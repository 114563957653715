.head1 {
  font-weight: 700;
  font-size: 2.0rem;
  color: #424242;
}

.insthead {
  font-weight: 700;
  color: #0f7c90;
  font-size: 1.5rem;
}

.head3 {
  color: #73726c;
  font-weight: 400;
}

.icon {
  color: #eb8a2f;
  display: flex;
  padding-right: 20px;
  padding-top: 14px;
}

.icon2 {
  color: #eb8a2f;
  display: flex;
  padding-right: 20px;
  padding-top: 0px;
}

.icondes {
  color: #3c3b37;
}

.icondes2 {
  color: #3c3b37;
  marginTop: 0px;
}

.des {
  font-weight: 400;
  font-size: 1.1rem;
  color: #3c3b37;
}

.deshead {
  font-weight: bold;
  font-size: 1.2rem;
  color: #424242;
}

.exp-text {
  background: white;
  /* width: 80%; */
  width: 78%;
  margin: 2px auto;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
  font-weight: 400;
}

.content {
  padding: 0 18px;
  max-height: 0px;
  transition: max-height 0.2s ease-out;
  width: 90%;
  margin: auto;
  /* background-color: #f1f1f1; */
}

.borderinstsn:hover {
  background: #ffb200;
  color: white;
  transition: 0.3s;
}

.borderinsts {
  /* border-bottom: 3px solid #FD0D59; */
  background: #133268;
  z-index: 999;
  color: white;
  transition: 0.3s;
}

.nav-tab-insts {
  margin: auto;
  display: grid;
  /* grid-template-columns: 20% 20% 20%; */
  grid-template-columns: 130px 130px 130px;
  /* border-bottom: 1px solid black; */
  cursor: pointer;
}

.inst-cs-ifo {
  display: grid;
  grid-template-columns: 130px 240px;
  padding-left: 35px;
}

.instr-name {
  font-size: 20px;
  font-weight: 600;
  color: grey;
  margin: 5px 0px;
}

.inst-head {
  font-size: 35px;
  font-weight: 600;
  color: #424242;
  padding-left: 35px;
}


@media only screen and (max-width:768px) {
  .inst-cs-ifo {
    grid-template-columns: 1fr;
    place-content: center;
    place-items: center;
    padding-left: 0px;
  }
  .inst-head {
    text-align: center;
    padding-left: 0px;
  }
}

