.main{
    max-width: 1400px;
    margin: 0 auto;
    padding: 10px 60px;
}
.contact_main{
    margin-top: 150px;
    display: flex;
}
.contact_container{
    width: 40%;
}
.heading{
    color: #172b69;
    margin-bottom: 10px;
    font-size: 52px;
}

.ctc_us{
    width: 100%; 
   text-align: left; 
   border-bottom: 1px solid #000; 
   line-height: 0.1em;
   margin: 40px 0 10px; 
}

.ctc_us span{
    background-color: #f7faff;
    color: #172b69;
    padding:0 10px; 
}

.address{
    display: flex;
    justify-content: space-evenly;
    padding: 50px 0 20px;
    color: #404a7b;
}
.cont_image{
    width: 60%;
}

.cts_block{
    width: 200px;
}

.cts_block:first-child{
    margin-right: auto;
}
.cts_block:last-child{

    margin-left: auto;
}

@media screen and (max-width: 1400px) and (min-width: 992px) {
    .heading{
        margin-bottom: 5px;
        font-size: 42px;
    }
}

@media screen and (max-width: 992px) and (min-width: 320px) {
    .contact_main{
        flex-direction: column-reverse;
    }
    .main {
        padding: 10px 20px;
    }
    .contact_container{
        width: 100%;
    }
    .address{
        flex-direction: column;
        align-items: center;
    }
    .cts_block:first-child{
        margin: 0 auto 20px;
    }
    .cts_block:last-child{
    
        margin: 10px auto 0;
    }
    .cont_image{
        width: 100%;
    }
    .heading{
        font-size: 32px;
    }
    .ctc_us > span{
        background-color: #f9efe2;
    }
}