.m_inner_content {
  margin: 10px auto;
  width: 70%;
}
.m_inner_content > p {
  font-size: 19px;
  color: black;
  text-align: center;
}

.logins {
  border-radius: 30px;
  /* background: white; */
  display: flex;
}
.navsgn {
  border: none;
  /* background-color: white; */
  color: black;
  padding: 10px 3px;
  overflow: hidden;
  border-radius: 5px;
  font-size: 15px;
  width: 74px;
  cursor: pointer;
}

.login-card-c-f {
  display: flex;
  width: 712px;
}

.login-card-c-f > div {
  width: 50%;
  height: 500px;
}

.card-login-c-l {
  background-repeat: no-repeat !important;
  /* background-size: 100% 100%; */
  background-size: contain !important;
  position: relative;
  background-color: #313d8d !important;
}

.login-man-img {
  position: absolute;
  height: 60%;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
}

.enter-number-login-c {
  width: 90%;
  margin: 0 auto 20px;
}
.studImg {
  width: 100%;
}

.login-num-title {
  color: #313d8d;
  font-size: 25px;
  text-align: center;
  margin-bottom: 40px;
}

.login-num-fields {
  color: grey;
  font-size: 20px;
  text-align: center;
}

.errs {
  margin-bottom: 10px;
}
#cityId {
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0px;
  width: 60px;
  font-size: 20px;
  color: black;
  font-weight: 900;
  opacity: 1;
  vertical-align: -webkit-baseline-middle;
  margin-bottom: 4px;
}

.input-field-num {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 70%;
}

.Bord_main {
  padding-bottom: 10px;
  border-bottom: 1px solid;
  margin-bottom: 10px;
}
#numberId {
  outline: none;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0px;
  font-size: 20px;
  font-weight: 900;
  width: 100%;
}

#numberId::placeholder {
  color: #858181;
}
.button-login-c {
  text-align: center;
}

.button-login-cb {
  padding: 10px;
  font-size: 25px;
  width: 70%;
  border-radius: 10px;
  background-color: #313d8d;
  color: white;
  cursor: pointer;
}

/* auth container */

.otp-input-field {
  display: flex;
  justify-content: space-between;
  margin: 5px auto;
  width: 284px;
}

.otp-input-field input {
  outline: none;
  border: none;
  border-bottom: 1px solid grey;
  padding: 8px;
  padding-bottom: 5px;
  font-size: 20px;
  width: 30px;
  border-radius: 0;
  text-align: center;
}
.otp-input-field input::placeholder {
  color: gray;
}

.bdy_ptnr {
  width: 450px;
}
.forms_main {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.phone-num-otp {
  text-align: center;
  color: grey;
  font-size: 20px;
  /* display: flex; */
  position: relative;
  display: inline-block;
  margin: auto;
}

.error-message-text {
  margin: 5px auto 20px ;
  text-align: center;
  color: red;
  width: 85%;
  font-weight: 500;
}
.phone-num-otp p {
  margin-top: 5px;
  width: 100%;
  color: black;
}

.edit-icon-float {
  position: absolute;
  top: 1px;
  right: -22%;
  /* border: 1px solid black; */
  padding: 3px 5px 0 5px;
  cursor: pointer;
}

.oops_content {
  padding: 50px 80px;
}

.sign_err {
  font-size: 24px;
  margin: 10px 0;
  font-weight: 500;
}

.contact {
  width: 30px;
}
.mobile_view {
  display: none;
}
.Containers_popup {
  border-radius: 12px;
}
.StudIcon {
  width: 50px;
  display: inline-block;
}
.form_data {
  display: flex;
  text-align: center;
  /* position: unset; */
  display: flex;
  top: 0;
  z-index: 124;
  background-color: #fff;
  width: 100vw;
  height: 100%;
  align-items: center;
  align-content: center;
  justify-content: space-around;
  background: linear-gradient(
    38.67deg,
    rgb(255, 211, 110) -64.26%,
    rgba(255, 255, 255, 0) 101.28%
  );
}
/* .MuiDialog-paperWidthSm {
  max-width: 50% !important;
  border-radius: 67px !important;
} */
.modal_head {
  display: none;
  background-color: #032c6b;
  text-align: center;
}
.modal_head > h1 {
  margin: 0;
  display: inline-block;
  padding: 20px;
  color: white;
}
.popup_content {
  display: flex;
  flex-direction: column;
}
.resendotp {
  padding: 10px;
}
.resendotp > p {
  text-align: center;
  color: black;
}
.resendotp a {
  color: #eb8413;
}

/*Form Detail*/
/* .form_data {
  display: flex;
  text-align: center;
  position: absolute;
  top: 0;
  z-index: 124;
  background-color: #fff;
  width: 99.4%;
} */
.form_data_box2 {
  width: 50%;
  padding: 15vh 0;
  display: flex;
  place-content: center;
  flex-direction: column;
  align-items: center;
  background: #fff;
}
.form_main {
  margin: 30px 0 0 0;
}
.form_main > p {
  text-align: center;
  color: black;
  font-size: 24px;
  font-weight: 600;
}
.form_content > p {
  text-align: center;
  font-size: 16px;
  color: #4a4a4a;
}
.MuiDialog-paperWidthXl {
  max-width: 800px !important;
}

.form_input_box {
  margin: 0 0 10px;
}
.form_inputs {
  display: flex;
  flex-direction: column;
}
.form_inputs > div {
  margin: 6px 43px;
  font-size: 20px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.form_input {
  border-radius: 12px;
  outline: none;
  background-color: gainsboro;
  border: none;
  padding: 12px 8px;
  font-size: 17px;
  width: 82%;
}

.form_content {
  width: 70%;
  margin: 6px auto;
}

.form_input::placeholder {
  color: #5c5959;
}

.success_title {
  color: #313d8d;
  text-align: center;
  margin-bottom: 5px;
  font-weight: 400;
}
.bk_book {
  width: 80%;
  height: auto;
  margin: auto;
}

.success_message {
  text-align: center;
  color: #333333;
  font-size: 20px;
  padding-bottom: 20px;
}

@media screen and (min-width: 993px) and (max-width: 1440px) {
  .logins {
    border-radius: 30px;
    background: white;
    display: flex;
    margin: 0px 9px;
  }
  .modal_head {
    background-color: #032c6b;
    text-align: center;
  }
  .modal_head > h1 {
    margin: 0;
    display: inline-block;
    padding: 20px;
    color: white;
  }
  .form_data {
    display: flex;
    text-align: center;
    /* position: unset; */
    top: 0;
    z-index: 124;
    background-color: #fff;
    width: 100vw;
    /* height: 100vh; */
    align-items: center;
    align-content: center;
    justify-content: space-around;
  }
}
@media screen and (max-width: 992px) {
  .modal_head {
    display: block;
    background-color: #032c6b;
    text-align: center;
  }
  .modal_head > h1 {
    margin: 0;
    display: inline-block;
    padding: 20px;
    color: white;
    font-size: 22px;
  }
  .m_inner_content {
    margin: 20px auto;
    width: 100%;
  }
  .sign_mobile,
  .mobile_view {
    display: none;
  }
  .Containers_popup {
    background-color: white;
  }
  .sm_first {
    border: none;
    border-right: 2px solid black;
    padding-right: 3px;
    color: #032c6ba1;
    background-color: Transparent;
    background-repeat: no-repeat;
    cursor: pointer;
    overflow: hidden;
    outline: none;
  }

  .MuiDialog-container {
    height: auto !important;
    /* outline: 0; */
  }
  .m_inner_content > p {
    font-size: 18px;
    margin: 6px 0;
  }

  #cityId {
    padding: 0;
    /* padding-top: 5px; */
    margin: 0;
    border: 0;
    border-radius: 0px;
    /* width: 50px; */
    font-size: 18px;
    color: black;
    font-weight: 900;
    opacity: 1;
  }

  #numberId {
    outline: none;
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 0px;
    font-size: 20px;
    font-weight: 900;
    /* width: 200px; */
  }
  .inp_nine {
    width: 50px;
    display: flex;
  }
  .inp_ten {
    width: 125px;
  }
  .input-field-num {
    justify-content: center;
    padding: 0;
  }
  .sm_second {
    padding-left: 3px;
  }
  .mob_profile {
    width: 46px;
    padding: 0 14px;
  }
  .body_container {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 2% !important;
    width: 100%;
  }
  .error-message-text {
    margin-top: 2px;
    margin-bottom: 3px;
  }
  .form_input_box {
    margin: 5px 0;
  }
  .form_input {
    padding: 6px 8px;
    font-size: 15px;
    width: auto;
  }
  .stud_dialogue {
    display: none;
  }
  .form_data {
    flex-direction: row-reverse;
  }
  .form_inputs > div {
    margin: 6px 13px;
  }
  .woo {
    display: none;
  }
  .success_message {
    color: #313d8d;
  }
  .bk_book {
    display: none;
    /* position: absolute; */
    width: 150px;
    top: 0px;
    right: 0;
  }
  .forms_main {
    flex-direction: column;
    align-items: flex-start;
  }
  .form_data_box2 {
    width: 95%;
    height: 300px;
  }
  .form_input_bx {
    width: 70%;
    margin: 0;
  }
  .studImg {
    width: 78%;
  }
}
@media screen and (max-width: 992px) {
  .form_data_box2 {
    display: flex;
    place-content: center;
    flex-direction: column;
    align-items: center;
    /* position: absolute; */
    top: 0;
    height: 100%;
    width: 100%;
    background: #fff;
  }
  .form_data_box_img {
    display: none;
  }
}
