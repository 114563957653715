.MainCOntainer{
  width: 90%;
  margin: auto;
}

.testTypeContainer {
  display: flex;
  justify-content: space-between;
}
.testName {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 400px;
  height: 120px;
  border-radius: 20px;
}

.testTypeBoxShadow{
  box-shadow: 0px 4px 4px 0px #00000040;

}

.bgimgcontainer {
  position: absolute;
  z-index: 0;
  left: 10px;
  bottom: -25px;
  width: 120px;

  & img {
    width: 100%;
  }
}

.showText {
  position: static;
  z-index: 10;
  color: #fff;
}

.containerHeading {
  font-size: 20px;
  font-weight: 500;
  position: relative;
}

.containerHeading::after {
  content: " ";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 40px;
  height: 2px;
  /* border: 1.9px solid; */
  background: linear-gradient(97.14deg, #11888a -3.11%, #2a5e95 100%);
}

.subjectListing {
  display: flex;
  /* justify-content: space-around; */
  gap: 30px;
  overflow: auto;
}
.subjectNameContainer {
  width: 312px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 15px;
  margin: 20px 0;
  cursor: pointer;
}

.activesubSelection{
    box-shadow: 0px 3px 8px 0px #0000003D;

}

.imgContainer{
  width: 100px;

  & img {
    width: 100%;
  }
}

.subText {
  margin: 0;
}

.flexBoxes {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.testInfoBox {
  box-shadow: 0px 4px 4px 0px #00000040;
  background: #fbfbfb;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
}

.leftInfotext {
  & h5 {
    font-size: 18px;
    font-weight: 500;
    color: #000;
    margin: 0;
  }

  & p {
    font-size: 15px;
    font-weight: 400;
    color: #7e7e7e;
    margin: 0 0 10px;
  }
}

.values {
  margin: 0 0 10px;
  font-size: 25px;
  font-weight: 500;
}
.rangeBox {
  position: relative;
}

.grayBG {
  background-color: #d9d9d9;
  border-radius: 20px;
  height: 10px;
}

.colorBG {
  background: linear-gradient(90deg, #286394 0%, #15838c 100%);
  border-radius: 20px;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
  /* animation: loading 4s ease-in-out forwards; Add animation */
}

/* Animation keyframes */
@keyframes loading {
  0% {
    width: 0; /* Start at 0% */
  }
  100% {
    width: 100%; /* Final width, adjust based on your dynamic value */
  }
}
@media only screen and (max-width: 768px) and (min-width: 320px) {
  .testTypeContainer {
    flex-direction: column;  
    gap: 10px;
  }

  .testName{
    width: 100%;
    height: 64px;
    font-size: 14px;
  }

  .bgimgcontainer {
    width: 55px;
    bottom: -10px;
    left: 30px;
    & img {
      width: 100%;
    }
  }

  .containerHeading {
    font-size: 18px;
  }

  .subjectListing{
    overflow: auto;
    gap: 20px;
    margin-bottom: 30px;
  }
  .subjectNameContainer{
    width: 250px;
    margin-bottom: 20px;
  }

  .subText{
    white-space: nowrap;
    font-size: 15px;
    font-weight: 600;
  }

  .imgContainer{
    width: 40px;

    & img{
        width: 100%;
    }
  }
}
