.signin_content{
    text-align: center;
}
.sign_cont_box{
    width: 80%;
    margin: 0 auto;
    padding: 10px;
}
.signin_input_box{
    text-align: center;
}

.icon_model{
    width: 40px;
}

.Signup{
    padding: 10px !important;
    font-size: 20px;
}

.popup_content{
    display: flex;
    justify-content: center;
}

.stud_content{
    width: 100%;
}

.m_inner_content{
    text-align: center;
    font-size: 17px;
    margin: 20px auto;
}

.s_ups_content{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.signContent{
    font-size: 20px;
    margin: 10px 0;
    cursor: pointer;
}
.MuiDrawer_paperAnchorBottom{
    background-color: transparent !important;
  }

