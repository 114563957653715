.heads-footer {
  text-decoration: none;
}
.footer_main {
  position: relative;
  padding: 50px 30px 20px 30px;
  box-sizing: border-box;
  margin: 0;
  display: grid;
  grid-template-columns: 10% 80%;
  background: linear-gradient(
    90deg,
    rgba(26, 123, 142, 0.58) 0%,
    rgba(38, 83, 159, 0.89) 100%
  ) !important;
}

.footer_container {
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  flex-wrap: wrap;
}

.footer_comp{
  position: relative;
  top: -24px;
}
.heads_foot {
  font-weight: bold;
  font-size: 21px;
  color: rgb(255, 255, 255);
  letter-spacing: 2px;
  margin-bottom: 24px;
  text-transform: none;
}

.head_list {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}
.hed_ls {
  display: flex;
  align-items: center;
  justify-content: center;
}

.heads_ls {
  padding-top: 39px;
}
.inline-flex-center {
  display: inline-flex;
  align-items: center;
}
.legend-icon {
  /* width: 50px !important; */
  height: auto !important;
}
.textLogo {
  width: 100%;
  text-align: center;
  font-size: 30px;
  font-weight: 900;
  font-style: italic;
  letter-spacing: 2px;
  color: white;
  /* margin-bottom: 20px; */
}

.logofooter {
  width: 165px;
  display: none;
}

.footer_content {
  display: flex;
  justify-content: space-around;
}

.heads-footer {
  padding: 6px;
  margin: 0;
  color: #fff;
}

@media screen and (max-width: 767px) and (min-width: 320px) {
  .hed_ls {
    justify-content: flex-start;
  }
  .footer_main {
    position: relative;
    padding: 30px 10px 20px 10px;
    box-sizing: border-box;
    margin: 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background: linear-gradient(
      90deg,
      rgba(26, 123, 142, 0.58) 0%,
      rgba(38, 83, 159, 0.8) 100%
    ) !important;
  }
.footer_looggo{
    width: 100%;
    text-align: center;
}

  .footer_details > ul {
    padding-left: 0;
  }
  .footer_content {
    flex-direction: column;
  }
  .heads_ls {
    padding: 0;
  }

  .footer_details {
    margin-bottom: 30px;
    width: 220px;

  }
  .heads-footer {
    padding: 10px;
  }
}
