.col-md-6 {
  flex: 0 0 auto;
  width: 50%;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}
.line_he {
  line-height: 1.5;
}
.textCenter {
  text-align: center !important;
}

.videos {
  width: 500px;
}

/* .testimonial_test_new {
  margin: 0 144px 0 20px;
  background-color: white;
  border: 2px solid;
  cursor: pointer;
  padding: 19px;
  padding-bottom: 0px;
  display: flex;
  padding-top: 0px;
  border-radius: 53px;
}
.testimonial_assessment_btn {
  border: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
} */

.bluiesh {
  color: #2f327d;
}

.bluiesh_back {
  background-color: #2f327d;
}

.black {
  color: #252641;
}

.organge {
  color: #f48c06;
}

.organge_back {
  background-color: #f48c06;
}

.greyesh {
  color: #696984;
}

.mx_fnt {
  font-size: 47px;
}
.max_font {
  font-size: 41px;
}
.m_font {
  font-size: 36px;
}
.max_norm_font {
  font-size: 29px;
}

.min_font {
  font-size: 18px;
}

.micro_font {
  font-size: 14px;
}

.flex_content {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
}

.flex_content_img {
  align-items: center;
}

.btn_struct {
  border-radius: 80px;
  padding: 19px;
  color: #ffffff;
  border: none;
  font-size: 20px;
  font-weight: 800;
  width: 194px;
  cursor: pointer;
}

.main_width {
  max-width: 100vw;
  margin: 0 auto;
  padding: 0 0px;
}

.headingConten {
  display: flex;
  padding-top: 150px;
  justify-content: space-evenly;
  margin: 0 10%;
  padding-bottom: 50px;
}

.head_home {
  background: url("../../Assets/HomeRewamp/topBack.png");
  margin-bottom: 10px;
  background-repeat: no-repeat;
  background-position: 0% 0%;
  background-size: 100% 100%;
}

.box_content {
  width: 100%;
}

.mobile {
  width: auto;
  height: 56%;
}

.benImage {
  background: #5b72ee;
  width: 60px;
  height: 60px;
  padding: 13px 9px 2px;
  margin: -40px auto 51px;
  border-radius: 50%;
}

.benefContent {
  width: 32%;
  padding: 21px;
  background: #ffffff;
  box-shadow: 0px 10px 60px rgb(38 45 118 / 8%);
  border-radius: 20px;
  text-align: center;
  margin: 10px;
  box-sizing: border-box;
}

.improve_main {
  margin-top: 140px;
  margin-bottom: 100px;
  padding-right: 10%;
  padding-left: 10%;
}

.improve_title {
  position: relative;
  margin-bottom: 50px;
  font-size: 30px;
}

.improve_Container {
  width: 43%;
  margin: auto;
}

.improve_back {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f4b767;
  position: absolute;
  top: -10px;
  left: -15px;
  z-index: -1;
}

.improve_para {
  font-size: 18px;
  margin-bottom: 30px;
  width: 90%;
  line-height: 2;
}

.supportPara {
  font-size: 18px;
  line-height: 2;
  width: 63%;
  margin: 0 auto;
  margin-bottom: 30px;
}

.support_main {
  margin-bottom: 150px;
  padding-right: 10%;
  padding-left: 10%;
}
.interest_form {
  padding: 3%;
  position: fixed;
  width: 80vw;
  margin: 0 6vw;
  display: flex;
  align-items: center;
  top: 25%;
  background-color: #fff;
  border-radius: 10px;
  justify-content: space-between;
}

.testimonial_test:before {
  content: " ";
  height: 1px;
  width: 100px;
  background: #525596;
  display: block;
  position: absolute;
  left: 0;
  top: 10px;
}

.testimonial_test {
  padding-left: 130px;
  color: #525596;
}

.testi_head {
  font-size: 40px;
  margin-bottom: 42px;
  font-weight: bold;
}

.btm_marg {
  margin-bottom: 40px;
}

.testi_para {
  width: 67%;
  font-size: 20px;
  line-height: 1.5;
}

.assessment {
  padding: 21px;
  /* width: 37%; */
  font-size: 20px;
  display: flex;
  background-color: white;
  color: #f48c06;
  border: 2px solid;
  border-radius: 37px;
  cursor: pointer;
  position: relative;
  align-items: center;
}

.assessment_2 {
  padding: 23px;
  margin-left: 10px;
  margin-right: -3px;
  font-size: 20px;
  display: flex;
  background-color: white;
  color: #f48c06;
  border: 2px solid;
  border-radius: 37px;
  cursor: pointer;
  position: relative;
  align-items: center;
}

.arrow {
  border-radius: 52%;
  position: absolute;
  border: 1px solid;
  right: -1px;
  top: -1px;
  padding: 17px 18px;
}

.testi_image {
  width: 81%;
  height: 86%;
  border-radius: 17px;
}

.test_caro_right {
  position: relative;
}

.tes_below_content {
  width: 89%;
  margin-left: 5px;
  background-color: #ffffff;
  box-shadow: 2px 2px 19px #dfc6c6;
  display: flex;
  border-radius: 17px;
}

.testi_border {
  padding: 6px;
  background-color: #f67766;
  border-bottom-left-radius: 12px;
  border-top-left-radius: 12px;
  margin-right: 18px;
}

.testimonial_text {
  border-left: 1px solid #5f5f7e;
  padding-left: 20px;
  line-height: 1.5;
  font-size: 19px;
  color: #5f5f7e;
}

.ccr_content {
  padding: 66px 8px 100px;
}

.banner_desktop .image_blue {
  position: absolute;
  width: 100%;
  top: 26%;
  z-index: -1;
}

.banner_desktop .data_book {
  width: 50%;
  display: flex;
  align-items: center;
}

.banner_desktop .book_carrer {
  color: #ffffff;
  font-size: 2em;
  font-weight: 900;
  text-align: right;
  margin-bottom: 50px;
}

.org_width {
  /* padding: 19px 44px; */
}

.careermapping {
  display: flex;
  flex-wrap: nowrap;
  max-width: 1400px;
  padding: 0 20px;
  margin: 10px auto;
  justify-content: center;
}

.w100 {
  max-width: 290px;
}

.banner_mobile {
  display: none;
}

.banner_desktop {
  display: block;
  /* background-color: #2f327d; */
}
.different_image {
  width: 90%;
}
.research_content {
  justify-content: center;
  align-items: center;
}

.research_content img {
  width: 40%;
  height: auto;
  margin: 20px;
}

.explore_btn {
  height: 160px;
  width: 366px;
  padding: 16px 0 16px 32px;
  background-image: linear-gradient(177deg, #3d3b4c, #26252f);
  -webkit-box-shadow: 0 7px 18px 0 rgb(0 0 0 / 20%);
  box-shadow: 0 7px 18px 0 rgb(0 0 0 / 20%);
  border-radius: 10px;
  margin-right: 40px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 1;
  color: white;
}

.btm_none {
  margin-bottom: -40px;
}

.video_height {
  height: 500px;
}

.t_brd {
  padding: 40px 1px;
  border-radius: 53px;
}
.rev_bx {
  width: 50%;
}

/* How to Join*/

.join_main {
  display: flex;
  flex-wrap: wrap;
}

.basic_dtl {
  width: 28%;
  margin: 10px;
  background: #e3e3e3;
  padding: 25px 11px;
}

.how_to_join {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* background: #2f327d; */
  background-image: url("../../Assets/ViewVideo/LC_back.png");
  background-repeat: no-repeat;
  background-size: 100%;
  font-size: 39px;
  font-weight: bold;
  color: white;
}

.btn_join {
  padding: 10px;
  border-radius: 31px;
  background: none;
  outline: none;
  cursor: pointer;
  border: 1px solid grey;
}
.desc_join {
  line-height: 1.7;
  color: #858585;
}
.icons {
  width: 40px;
}
@media screen and (max-width: 1200px) and (min-width: 992px) {
  .videos {
    width: 350px;
  }
}
@media screen and (max-width: 992px) and (min-width: 768px) {
  .w100 {
    width: 100%;
  }

  .bluiesh {
    color: #2f327d;
  }

  .bluiesh_back {
    background-color: #2f327d;
  }

  .black {
    color: #252641;
  }

  .organge {
    color: #f48c06;
  }

  .organge_back {
    background-color: #f48c06;
  }

  .greyesh {
    color: #696984;
  }

  .max_font {
    font-size: 20px;
  }

  .min_font {
    font-size: 18px;
  }

  .mid_font {
    font-size: 16px;
  }

  .micro_font {
    font-size: 12px;
  }

  .flex_content {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
  }

  .btn_struct {
    border-radius: 80px;
    padding: 10px 20px;
    color: #ffffff;
    border: none;
    font-weight: 700;
    cursor: pointer;
    font-size: 15px;
  }

  .headingConten {
    display: flex;
    padding-top: 110px;
    align-items: center;
    flex-wrap: wrap;
  }

  .head_home {
    background: url("../../Assets/HomeRewamp/topBack.png");
    margin-bottom: 10px;
    background-repeat: round;
    background-position: 0% -2%;
  }

  .box_content {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  .columnReverse {
    flex-direction: column-reverse !important;
  }

  .mobile {
    max-width: 30%;
    height: auto;
  }

  .benImage {
    background: #5b72ee;
    width: 60px;
    height: 60px;
    padding: 10px;
    margin: -40px auto 51px;
    border-radius: 50%;
  }

  .benefContent {
    width: 100%;
    padding: 12px;
    background: #ffffff;
    box-shadow: 0px 10px 60px rgb(38 45 118 / 8%);
    border-radius: 20px;
    text-align: center;
    margin: 19px 10px;
    box-sizing: border-box;
  }

  .improve_main {
    margin-top: 140px;
    margin-bottom: 100px;
  }

  .improve_title {
    position: relative;
    margin-bottom: 50px;
    font-size: 30px;
  }

  .improve_back {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #f4b767;
    position: absolute;
    top: -10px;
    left: -15px;
    z-index: -1;
  }

  .improve_para {
    font-size: 14px;
    margin-bottom: 30px;
    width: 100%;
    line-height: 2;
  }

  .improve_Container {
    width: 100%;
  }

  .supportPara {
    font-size: 14px;
    line-height: 2;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .support_main {
    margin-bottom: 60px;
  }

  .testimonial_test:before {
    content: " ";
    height: 1px;
    width: 100px;
    background: #525596;
    display: block;
    position: absolute;
    left: 0;
    top: 24px;
  }

  .testimonial_test {
    position: relative;
    padding-left: 130px;
    color: #525596;
  }

  .testi_head {
    font-size: 18px;
    margin-bottom: 22px;
    font-weight: 400;
  }

  .testi_para {
    width: 100%;
    font-size: 12px;
    font-size: 14px;
    width: 100%;
    line-height: 1;
  }

  .tes_below_content {
    position: absolute;
    bottom: 2px;
    left: 74%;
    background-color: #ffffff;
    box-shadow: 1px 1px 6px #dfc6c6;
    display: flex;
    border-radius: 17px;
  }

  .image_blue {
    position: absolute;
    width: 100%;
    z-index: -1;
  }
  .book_carrer {
    font-size: 1em !important;
  }
}

@media screen and (max-width: 767px) {
  .w100 {
    width: 100%;
  }

  .m_font {
    font-size: 23px;
  }
  .bluiesh {
    color: #2f327d;
  }

  .bluiesh_back {
    background-color: #2f327d;
  }

  .black {
    color: #252641;
  }

  .organge {
    color: #f48c06;
  }

  .organge_back {
    background-color: #f48c06;
  }

  .greyesh {
    color: #696984;
  }

  .max_font {
    font-size: 20px;
  }
  .mx_fnt {
    font-size: 21px;
  }
  .min_font {
    font-size: 18px;
  }

  .mid_font {
    font-size: 16px !important;
  }

  .micro_font {
    font-size: 12px;
  }

  .flex_content {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
  }

  .btn_struct {
    border-radius: 80px;
    padding: 10px 20px;
    color: #ffffff;
    border: none;
    font-weight: 700;
    cursor: pointer;
    font-size: 15px;
  }

  .headingConten {
    display: flex;
    padding-top: 150px;
    align-items: center;
    flex-wrap: wrap;
  }

  .head_home {
    background: url("../../Assets/HomeRewamp/topBack.png");
    margin-bottom: 10px;
    background-repeat: round;
    background-position: 0% -2%;
  }

  .box_content {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 0 12px;
  }

  .columnReverse {
    flex-direction: column-reverse !important;
  }

  .mobile {
    max-width: 30%;
    height: auto;
  }

  .benImage {
    background: #5b72ee;
    width: 60px;
    height: 60px;
    padding: 10px;
    margin: -40px auto 30px;
    border-radius: 50%;
  }

  .benefContent {
    width: 100%;
    padding: 12px;
    background: #ffffff;
    box-shadow: 0px 10px 60px rgb(38 45 118 / 8%);
    border-radius: 20px;
    text-align: center;
    margin: 19px 10px;
    box-sizing: border-box;
  }

  .improve_main {
    margin-top: 140px;
    margin-bottom: 100px;
  }

  .improve_title {
    position: relative;
    margin-bottom: 50px;
    font-size: 30px;
  }

  .improve_back {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #f4b767;
    position: absolute;
    top: -10px;
    left: -15px;
    z-index: -1;
  }

  .improve_para {
    font-size: 14px;
    margin-bottom: 30px;
    width: 100%;
    line-height: 1.6;
  }

  .improve_Container {
    width: 100% !important;
  }
  .btm_marg {
    margin-bottom: 20px;
  }
  .supportPara {
    font-size: 14px;
    line-height: 2;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .support_main {
    margin-bottom: 50px;
  }

  .testimonial_test:before {
    content: " ";
    height: 1px;
    width: 100px;
    background: #525596;
    display: block;
    position: absolute;
    left: 0;
    top: 24px;
  }

  .testimonial_test {
    position: relative;
    padding-left: 130px;
    color: #525596;
  }

  .testi_head {
    font-size: 18px;
    margin-bottom: 22px;
    font-weight: 400;
  }

  .testi_para {
    width: 100%;
    font-size: 12px;
    font-size: 14px;
    width: 100%;
    line-height: 1.5;
  }

  .assessment {
    padding: 0px;
    /* width: 37%; */
    margin: 5px;
    font-size: 16px;
    display: flex;
    background-color: white;
    color: #f48c06;
    border: 2px solid;
    border-radius: 37px;
    cursor: pointer;
    position: relative;
    align-items: center;
  }

  .different_image {
    width: 65%;
  }
  .arrow {
    border-radius: 52%;
    position: absolute;
    border: 1px solid;
    right: -1px;
    top: -1px;
    padding: 17px 18px;
  }

  .testi_image {
    width: 81%;
    height: 86%;
    border-radius: 17px;
  }

  .test_caro_right {
    position: relative;
  }

  .tes_below_content {
    background-color: #ffffff;
    box-shadow: 1px 1px 6px #dfc6c6;
    display: flex;
    border-radius: 17px;
  }

  .testi_border {
    padding: 6px;
    background-color: #f67766;
    border-bottom-left-radius: 12px;
    border-top-left-radius: 12px;
    margin-right: 18px;
  }

  .testimonial_text {
    border-left: 1px solid #5f5f7e;
    padding-left: 20px;
    line-height: 1.5;
    font-size: 10px;
    color: #5f5f7e;
  }

  .image_blue {
    position: absolute;
    width: 100%;
    top: 26%;
    z-index: -1;
  }

  .data_book {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .book_carrer {
    color: #ffffff;
    font-size: 0.8em;
    font-weight: 700;
    text-align: center;
  }

  .org_width {
    /* padding: 19px 44px; */
  }

  .careermapping {
    display: flex;
    flex-wrap: wrap;
  }

  .main_width {
    max-width: 100vw;
    margin: 0 auto;
    padding: 0 0px;
  }

  .banner_mobile {
    position: relative;
    /* background-color: rgb(47, 50, 125); */
    display: block;
    max-width: 100%;
  }

  .banner_desktop {
    display: none;
  }

  .banner_mobile .banner_img {
    max-width: 50%;
  }

  .banner_mobile .banner_img img {
    max-width: 100%;
  }

  .banner_mobile .banner_description {
    position: absolute;
    width: 58%;
    bottom: 0;
    right: 20px;
    top: 0;
  }

  .test_caro_right {
    position: relative;
    width: 100% !important;
  }

  .mt130 {
    margin-top: 130px;
  }

  .pt100 {
    padding-top: 100px;
  }

  .ptbt {
    padding-bottom: 45px;
  }
  .mobile_container {
    margin: auto 10% !important;
  }

  .videos {
    width: 300px;
  }
  .video_height {
    height: 200px;
  }
  .ccr_content {
    padding: 20px 8px 20px;
  }
  .mbZ {
    margin: 4px;
  }
  .mbZTitle {
    margin: 4px;
    margin-top: 12px;
  }
  .t_brd {
    padding: 4px 1px;
    border-radius: 33px;
  }
  .rev_bx {
    width: 100%;
  }

  /* How to Join*/

  .join_main {
    flex-direction: column;
  }

  .basic_dtl {
    width: 90%;
    margin: 10px;
    background: #e3e3e3;
    padding: 25px 11px;
  }

  .how_to_join {
    background: #2f327d;
    font-size: 29px;
    height: 100px;
  }

  .btn_join {
    padding: 10px;
    border-radius: 31px;
    background: none;
    outline: none;
    cursor: pointer;
    border: 1px solid grey;
  }
  .desc_join {
    line-height: 1.7;
    color: #858585;
  }
  .icons {
    width: 20px;
  }
}
