.img {
    display: block;
    width: 100%;
  }
  
  .section {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
  }
  
  .article {
    display: flex;
    width: 200%;
    animation: bannermove 20s linear infinite;
  }
  
  /* .article.paused {
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  } */
  
  .div {
    width: 100%;

    & ul {
        display: flex;
        /* background: red; */
        /* gap: 80px; */
        justify-content: space-around;
        list-style-type: none;
        padding-left: 0;
        margin: 50px 0;
      }
      
      & li {
        /* width: 100%; */
        /* background: blue; */
      }
      
     & li:nth-child(odd) {
        /* background: green; */
        margin-top: 60px;
      }
      
     & li:nth-child(even) {
        /* background: yellow; */
      }
  }

  
  @keyframes bannermove {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }

  @media only screen and (max-width: 767px) and (min-width: 320px) {
      
  .div {
    width: 100%;

    & ul {
        display: flex;
        /* background: red; */
        /* gap: 80px; */
        justify-content: space-around;
        list-style-type: none;
        padding-left: 0;
        margin: 50px 0;
      }
      
      & li {
        width: 200px;

        & img{
          width: 100%;
        }
        /* background: blue; */
      }
  
  }
  }