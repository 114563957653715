.custom-arrow{
    background-color: transparent;
    border: none;
    color: #89C6FF;
    position: absolute;
    cursor: pointer;
}

.custom-prev-arrow{
    top: 50%;
    left: -30px;
}

.custom-next-arrow{
    top: 50%;
    right: -30px;
}