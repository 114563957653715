.course-card-mains {
    height: 450px;
    width: 320px;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.2);
    display: inline-block;
    border-radius: 7px;
    margin: 10px;
    overflow-X: hidden;
    cursor: pointer;
    display: inline-block;
    background-color: #fff;
}

.cc-colored {
    /* background-image: linear-gradient(to bottom right, #FDC70144, #FD0D5944); */
    background: white;
    border-radius: 5px;
}

.course-card-mains div {
    box-sizing: border-box;
}

.course-card-mains:hover .tc-container {
    transform: translateX(-50%);
    transition: transform 0.6s ease-out;
}

.course-card-mains .tc-container {
    height: calc(100% - 150px);
    width: 200%;
    transform: translateX(0%);
    transition: transform 0.5s ease-out;
}

.course-card-mains .tc-container {}

.course-card-mains .tc-c-1 {
    width: 50%;
    height: 100%;
    display: inline-block;
    top: 0;
    position: absolute;
    left: 0;
    text-align: center;
    padding-top: 12px;
}

.course-c-wave {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;


}

.course-card-mains .tc-c-2 {
    width: 50%;
    height: 100%;
    display: inline-block;
    top: 0;
    position: absolute;
    right: 0;
    text-align: center;
}

.tc-c-2 .tc-c-2-ch {
    color: rgba(0, 0, 0, 0.6);
    font-family: Roboto;
    font-size: 15px;
    text-transform: uppercase;
    padding:8px 0px;
}

.tc-name {
    display: grid;
    place-items: center;
    padding: 20px 10px 10px 10px;
    text-align: center;
    font-size: 28px;
    max-height: 90px;
    /* color: rgba(0, 0, 0, 0.7); */
    color:black;
    font-weight: 700;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

/* Batch detail css */

.tc-batch-det {
    padding: 10px 10px 20px 10px;
}

/* Grade and board css */

.tc-batch-det div:nth-child(1) {
    font-size: 17px;
    color: rgba(0, 0, 0, 0.7);
    margin: 0px auto;
    padding: 2px;
}

/* Timings css */

.tc-batch-det div:nth-child(2) {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.8);
    margin: 0px auto;
    font-weight: 600;
    padding: 0px;
}

/* Instructor details*/

.tc-instructor-det {
    padding: 10px;
    text-align: left;
}



.course-c-btn
{
    height: 50px;
    width: 100%;
    border:none;
    font-weight: 600;
    font-size: 19px;
    text-transform: uppercase;
    cursor: pointer;
    color:white;
    outline: none;
    border:none;
}


/*Taken by css */


.course-c-mids
{
    display: grid;
    grid-template-columns: 55% 45%;
    height: 120px;
    place-items: top;
    /* padding-left: 10px; */
}

.mid-cc-bans
{
    height: auto;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.mid-cc-bans-img{
    text-align: right;
}

.mid-cc-ban img
{
    /* position: absolute; */
}


.tc-instructor-det div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 1px 1px;
    height: 100%;
    width: 100%;
    color:black;
}

.out-cc-mid
{
    display: flex;
    align-items: center;
    padding-left: 0;
    width:100% !important;
    /* overflow: hidden; */
}


.tc-instructor-det div:nth-child(1) {
    /* text-transform: uppercase; */
    font-size: 11px;
    /* letter-spacing: 1.5px; */
    /* color: rgba(0, 0, 0, 0.8); */
}

/* Instructor name css */

.tc-instructor-det div:nth-child(2) {
    /* text-transform: uppercase; */
    /* color: rgba(0, 0, 0, 0.7); */
    font-size: 15px;
    font-weight: 700;
}

/* Instructor subjects css */

.tc-instructor-det div:nth-child(3) {
    /* color: rgba(0, 0, 0, 0.5); */
    font-weight: 400;
    font-size: 11px;
    /* text-transform: uppercase; */
}

/* Instructor education css */

.tc-instructor-det div:nth-child(4) {
    /* color: rgba(0, 0, 0, 0.5); */
    font-weight: 400;
    font-size: 11px;
}

.tuncate-1line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0px 10px;
}

/* course highlishts */

.tc-c-higlights .tc-c-hg {
    width: 90%;
    margin: 10px auto;
    height: 40px;
    color: rgba(0, 0, 0, 0.9) !important;
    text-align: left;
    padding: 8px 6px 20px 14px;
    font-size: 13px !important;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.tc-c-hgs{
    width: 90%;
    margin: auto;
    text-align: left;
    overflow: hidden;
    height: 162px;
    overflow-y: scroll;

}

.tc-c-hgs:hover {
    overflow-y: scroll;
  }

.tc-c-higlights .tc-c-hg:nth-of-type(odd) {
    border-left: 2px solid #FD0D59;
}

.tc-c-higlights .tc-c-hg:nth-of-type(even) {
    border-left: 2px solid #FDC701;
}



/* Dates */


.date-lbl-bx
{
    text-transform: uppercase;
    font-size: 13px;
    width: 100%;
    text-align: center;
}

.date-bx
{
    text-transform: uppercase;
    font-size: 15px;
    padding-left: 10px;
}


/* CouseCardV2 css */

.tagitems-ccv2
{
    font-size: 12px;
    font-weight: 600;
    display: inline-block;
    width: fit-content;
    padding:5px;
    margin:3px;
    box-shadow: 0px 0px 2px 1px #00000022;    
    border-radius: 3px;
}

.ccv2-prov-imgs
{
    width:70% !important;
    /* height: 40px !important; */
    /* box-shadow: 0px 0px 4px 1px #00000033; */
    border-radius: 40px;
    padding-bottom: 3px;
    overflow: hidden;
    box-sizing: border-box;
    text-align: left
}

.ccv2-prov-imgs img
{ 
    /* width:29px !important; */
    /* height: 29px; */
    width:107px;
    height: 29px;
}

.tc-instructor-detv2
{
    padding: 4px ;
    box-sizing: border-box;
    min-height: 138px;
}

.tc-namev2s {
    display: grid;
    place-items: left;
    /* padding: 30px 10px 10px 10px; */
    padding: 10px;
    text-align: left;
    font-size: 20px;
    height: 61px;
    /* color: #000000b3; */
    color: #000;
    /* font-weight: 700; */
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    position: relative;
    


    /* overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* number of lines to show */
    /*line-height: 40px;        /* fallback */
   /* max-height: 40px;   */
    /* top: 20px; */
}



.course-card-mains:hover .tc-containerv2 {
    transform: translateX(-50%);
    transition: transform 0.6s ease-out;
}

.course-card-mains .tc-containerv2 {
    height: calc(100% - 180px);
    width: 200%;
    transform: translateX(0%);
    transition: transform 0.5s ease-out;
}


.price-tag-ccv2
{
    position: absolute;
    right: 0px;
    top:15px;
    padding:3px 10px;
    width: fit-content;
    box-sizing: content-box !important;
    border-bottom-left-radius:12px ;
    border-top-left-radius: 12px;
    color:white;
    font-weight: 600;
}

.price-tag-ccv2s
{
    position: absolute;
    right: 0px;
    top:15px;
    padding:3px 10px;
    width: fit-content;
    box-sizing: content-box !important;
    border-bottom-left-radius:12px ;
    border-top-left-radius: 12px;
    font-weight: 600;
}

.price-tag-ccv2s2{
    position: absolute;
    right: 0px;
    top:28px;
    padding:0px 10px;
    width: fit-content;
    box-sizing: content-box !important;
    border-bottom-left-radius:12px ;
    border-top-left-radius: 12px;
    right:-10px;
    background:#d0d0d0;
    
}

.price-tag-ccv2s2 p{
    margin:5px;
}
.new-discount-v-c{
    font-size: 22px;
    margin: 0px;
    color: #7EB62D;
}

.discount-c{
    margin:0px;
}



.course-c-wavev2s {
    position: absolute;
    bottom: 64px;
    left: 10px;
    width: 320px;
    /* width: 100%; */
    height: 100px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.inner-topbarnav{
    width:90%;
    margin:auto;
}

.course-card-detail-c{
    /* display: grid; */
    display: flex;
    flex-wrap: wrap;
    grid-template-columns: 40% auto;
    margin-bottom: 5px;
    padding-left:5px;
    text-align: left;
}

.course-card-detail-c  p{
    margin-right: 2px;
}

.display-star-card{
    display: grid;
    grid-template-columns: 20% 30% 20% !important;
    width:80%;
    /* align-items: end !important; */
}

.display-star-cards{
    display: grid;
    grid-template-columns: 30% 70%  !important;
    width:80%;
    margin-top:5px;
    align-items: center;
    /* align-items: end !important; */
}

.display-star-icon{
    text-align: left;
    display: flex;
    padding: 4px !important;
    /* box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.1); */
    display: inline-block;
    border-radius: 5px;
}

.tooltip-size-cc{
    font-size: 15px;
}



.tc-c-hgs::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  
  .tc-c-hgs::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 5px;
    box-shadow: inset 0px 0px 1px 1px rgba(0, 0, 0, 0.1)
  }
   
  /* Handle */
  
  .tc-c-hgs::-webkit-scrollbar-thumb {
    background: #133268; 
    border-radius:5px;
  }
  
  /* Handle on hover */
  
  .tc-c-hgs::-webkit-scrollbar-thumb:hover {
    background: #ffb200; 
  }
  

  .enrolment-classs-card{
      display: grid;
      grid-template-columns: 20% 80%;
  }

  .money-c{
    font-weight: 700;
    margin-top: 0px;
    margin-right: 10px;
    padding-top: 5px;

  }

  .offer-content-c{
      margin:2px 0px 5px 0px;
      background: #FCE7CA;
    color: #F09F34;
    border-radius: 5px;
    font-size: 15px;
  }

  .offer-with-discount-p-c{
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 0px;
  }

  .course-details-card-i{
      display: inline-block;
      margin:0px;
        background: #FCE7CA;
        text-align: center;
        border-radius: 20px;
        font-size: 14px;
        padding:5px;
        background:  rgb(34, 50, 109,0.7);
        color:  white;
        margin:2px auto;
  }