.drr-icons {
  width: 35px;
  display: inline-block;
  text-align: center;
}

.drr-icons img {
  height: 30px;
  margin: 5px 5px 0 0;
}

.live-course {
  border-radius: 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: white;
  padding: 15px;
  margin-left: 54px;
  margin-bottom: 20px;
  width: 26%;
}
.live-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  border-radius: 13px;
  padding: 5px 10px;
  font-weight: bold;
  color: aliceblue;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  background-color: #022e7a;
}

.live-img {
  width: 60px;
  margin-right: 15px;
}

.video {
  width: 42%;
  min-height: 240px;
  /* background-color: #444444; */
  margin-left: 54px;
  border-radius: 15px;
}

.course-type-header {
  color: #313e6c;
}

.opd-sidenav {
  height: 100%;
  width: 200px;
  box-sizing: border-box;
  /* z-index: -1; */
  top: 0;
  left: 0;
  overflow-x: hidden;
  border-right: 1px solid #e0e0e0;
  transition: width 0.3s;
  background-color: #fff;
}

.opd-sidenavHide {
  height: 100%;
  width: 60px;
  z-index: 1;
  top: 0;
  left: 0;
  box-sizing: border-box;
  background-color: #fff;

  overflow-x: hidden;
  /* padding-top: 20px; */
  border-right: 1px solid #e0e0e0;
  transition: width 0.3s;
}

.main {
  margin-left: 200px;
  /* Same as the width of the sidenav */
  padding: 0px 10px;
  /* height: 100vh; */
  transition: margin 0.3s;
}

.mainExp {
  /* height: 100vh; */
  margin-left: 60px;
  padding: 0px 10px;
  margin-top: 0px !important;
  transition: margin 0.3s;
}

.drr-l-lab {
  font-size: 17px;
  animation: test-anime 0.22s ease-in;
  color: #313e6c;
}
.content-div {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.card_container_new {
  margin-top: 100px;
  margin-left: 220px;
}
@keyframes test-anime {
  from {
    font-size: 0px;
  }
  to {
    font-size: 15px;
  }
}

/* .drr-list {
  /* font-size: 17px; * /
  display: flex;
  align-items: center;
  padding: 5px 10px;
  margin: 10px auto;
  width: 80%;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s;
} */

.drr-list {
  /* font-size: 17px; */
  display: inline-flex;
  align-items: center;
  padding: 5px 10px;
  margin: 10px auto;
  width: 100px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.drr-unseleted {
  color: #848484 !important;
}

.drr-unseleted path {
  fill: #848484;
}

.drr-selected {
  color: black !important;
}

.drr-selected path {
  fill: black !important;
}
.card_myspace_container {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  max-width: 380px;
  min-width: 30vw;
}
.Cards_Myspace {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 50px;
  margin-left: 20px;
}

.NoCouncilImg {
  width: 35%;
}
.card-session-container {
  width: 30vw;
  height: auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.card-session-action {
  align-items: center;
  align-content: center;
  justify-content: center;
}
.card-session-header {
  margin-bottom: 30px;
}
.card-session-container p {
  margin: 10px 5px;
  flex-wrap: nowrap;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
}
.card-session-container {
  width: 100%;
  height: auto;
}
.join_btn {
  justify-content: center !important;
  align-items: center !important;
  margin: 0 auto !important;
  display: flex !important;
  color: green !important;
  background: #fff !important;
  border-radius: 10px !important;
}
.card-session-book {
  padding: 20px;
  box-shadow: 0.7px 0.7px 5px 0 #0000002e, 0 6px 6px 0 #0000000d;
}
.bx-date {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}
.bx-date_available {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* text-align: left; */
  white-space: nowrap;
}
.expired_container {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 15px !important;
  color: #000 !important;
  /* white-space: pre-line; */
}
.available_container {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 15px !important;
  color: #000 !important;
}
.bx-content {
  text-align: center;
}
.bx-expired_header {
  font-weight: 600;
  font-size: 19px;
  line-height: 23px;
  display: flex;
  /* justify-content: center; */
}
.content-div {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.bx-expired {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 15px !important;
  color: #ffffff !important;
}
.bx-amount {
  margin: 10px 0;
}
.bx-new-card {
  padding: 10px;
  text-align: left;
  margin: 0;
  border: 1px solid #000;
  width: fit-content;
  border-radius: 6px;
}
.card-session-container {
  width: 100%;
  height: auto;
}
.join_btn {
  justify-content: center !important;
  align-items: center !important;
  margin: 0 auto !important;
  display: flex !important;
  color: green !important;
  background: #fff !important;
  border-radius: 10px !important;
}
.card-session-book {
  padding: 20px;
  margin: 10px;
  box-shadow: 0.7px 0.7px 5px 0 #0000002e, 0 6px 6px 0 #0000000d;
}
.bx-date {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}
.expired_container {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 15px !important;
  /* color: #ffffff !important; */
  /* white-space: pre-line; */
}
.bx-content {
  text-align: center;
}
.bx-expired_header {
  font-weight: 600;
  font-size: 19px;
  line-height: 23px;
  display: flex;
  /* justify-content: center; */
}
.content-div {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.bx-expired {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 15px !important;
  color: inherit !important;
}
.bx-amount {
  margin: 10px 0;
}
.bx-new-card {
  padding: 10px;
  text-align: left;
  margin: 0;
  border: 1px solid #000;
  width: fit-content;
}
@media screen and (max-width: 992px) and (min-width: 769px) {
  .live-course {
    width: 37%;
  }
  .Cards_Myspace {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 50px;
    margin-left: 20px;
  }
  .card_myspace_container {
    flex-direction: column;
  }
}
.card_container {
  /* margin-left: auto; */
}
.cards-box {
  max-width: 100%;
  box-shadow: 0.7px 0.7px 5px 0 #0000002e, 0 6px 6px 0 #0000000d;
}
@media screen and (max-width: 768px) and (min-width: 320px) {
  .card_container {
    width: 88%;
    margin: auto;
  }
  .cards-box {
    width: 100%;
    box-shadow: 0.7px 0.7px 5px 0 #0000002e, 0 6px 6px 0 #0000000d;
  }
  .live-course {
    width: 80%;
    margin: 10px 0 10px 46px;
  }
  .Cards_Myspace {
    margin: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    white-space: pre-line;
    margin-bottom: 30px;
    margin-left: 0px;
  }
  .NoCouncilImg {
    width: 100%;
  }
  .card_myspace_ {
    flex-direction: column;
  }
  .drr-l-lab {
    font-size: 17px;
    text-align: center;
    padding: 4px;
  }
  .card-session-container {
    width: 100%;
    height: auto;
  }
  .join_btn {
    justify-content: center !important;
    align-items: center !important;
    margin: 0 auto !important;
    display: flex !important;
    color: green !important;
    background: #fff !important;
    border-radius: 10px !important;
  }
  .card-session-book {
    padding: 20px;
    box-shadow: 0.7px 0.7px 5px 0 #0000002e, 0 6px 6px 0 #0000000d;
  }
  .bx-date {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* color: #ffffff; */
  }
  .expired_container {
    font-family: "Inter" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 15px !important;
    color: #000 !important;
    /* white-space: pre-line; */
  }
  .bx-content {
    text-align: center;
    font-size: 15px;
  }
  .bx-expired_header {
    font-weight: 600;
    font-size: 19px;
    line-height: 23px;
    display: flex;
    /* justify-content: center; */
  }
  .content-div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  .bx-expired {
    font-family: "Inter" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 15px !important;
    /* color: #ffffff !important; */
  }
  .bx-amount {
    margin: 10px 0;
  }
  .bx-new-card {
    padding: 10px;
    text-align: left;
    margin: 0;
    border: 1px solid #000;
    width: fit-content;
  }
  .main-session-title {
    padding: 15px 5px;
    border-bottom: 1px solid #3e3f75d0;
    width: 100%;
    display: block;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    text-transform: capitalize;
    color: #595b97;
  }
}
.headerSession {
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 23px;
}
.main-session-title {
  margin: 8px;
  text-align: left;
  padding: 20px 5px;
  border-bottom: 1.87px solid #3e3f75d0;
  width: 100%;
  display: block;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  text-transform: capitalize;
  color: #595b97;
}
.NoSessionCard {
  display: flex;
  text-align: center;
  margin: -12px 0px 20px 54px;
  flex-direction: column;
  align-items: center;
}
@media screen and (max-width: 768px) and (min-width: 320px) {
  .NoSessionCard {
    display: flex;
    text-align: center;
    margin: auto;
    flex-direction: column;
    align-items: center;
  }
}
