.cardContainer{
    width: 80% !important;
    border: 1px solid #C8E4FE;
    border-radius: 10px;
    margin: 40px auto;
    padding: 20px;
    min-height: 500px;
    transition: all 0.5s ease-in-out;
}

.imgContainer{
    width: 70%;
    margin: 0 auto;
    min-height: 350px;

    & img{
        width: 100%;
    }
}

.cardContainer:hover{
    transform: translate(0% ,-5%);
    background-color: rgba(187, 222, 255, 0.3);
   box-shadow: 5px 11px 15px 0 rgba(156, 196, 235, 0.25);
}
.cardHeading{
    font-size: 22px;
    font-weight: 600;
    color: #1A6EBC;
}

.cardPara{
    font-size: 16px;
    font-weight: 400;
    color: rgba(3, 44, 107, 0.8);
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
    .cardContainer{
        width: 100%;
        padding: 10px;
        min-height: 350px;
    }
    .imgContainer{
        width: 100%;
        margin: 0 auto;
        min-height: 250px;
    
        & img{
            width: 100%;
        }
    }
}