.back-b-cart-c{
    margin: 20px 4.3%;
    display: flex;
    align-items: center;
}

.back-b-cart-c img{
    margin-right: 10px;
    cursor: pointer;
}

.purchase-container{
    margin-top:20px;
}

.flip-box-c{
    margin:10px 4.3%;
    display: flex;
    justify-content: space-between;
}

.purchase-box{
    /* margin:10px 4.3%; */
    /* width:70%; */
}


.purchase-heading{
    text-align: left;
    font-size: 24px;
    margin:15px 4.3%;
}

.p-c-l-w{
    margin: 40px 0px;
    animation-name: example;
    position: relative;
  animation-duration: 1s;
}

@keyframes example {
    0%{opacity: 0;left:50px;}
    100%{opacity: 1;left:0px}
}

.purchase-box{
    box-shadow: 0 0 3px 1px rgba(0,0,0,.2);
    /* padding:10px; */
    margin-bottom: 50px;
    border-radius: 5px;
}


.p0-s-t{
    font-size:20px;
}

.purchase-o-l-c{
    display: grid;
    grid-template-columns: 15% 40%  20% 15%;
    grid-gap: 2%;
 }

 .course-title-purchase p:first-child{
     margin-top: 0px;
    color:#606060;
    }

    .remove-p-o{
        margin-top:20px;
        cursor: pointer;
        text-align: center;
    }

    .purchase-amount{
        width:80%;
        margin:auto;
        display: grid;
        grid-template-columns: 70% 30%;
        margin-bottom: 30px;
    }

    .purchase-amount-r{
        box-shadow: 0 1px 3px 1px rgba(0,0,0,.2);
        padding:20px;
        /* width:100%; */
    }

    .purchase-o-l-c-c{
        /* padding-top: 20px; */
    }

    .amount-stack-c{
        grid-template-columns: 70% 30%;
        display: grid;
        border-bottom: 1px solid black;
    }

    .amount-stack-c-b{
        grid-template-columns: 70% 30%;
        display: grid;
    }

    .price-stack-s{
        text-align: right;
    }

    .purchase-button-c{
       text-align: center;
    }

    .back-button-p{
        text-align: right;
    }

    .purchase-amount-l{
        text-align: left !important;
    }

.success-display{
    margin-top: 100px;
    margin-bottom: 100px;
}

    .success-div-c{
        width:80%;
        margin:auto;
        text-align: center;
        box-shadow:0 0 3px 1px rgba(0,0,0,.2);
        padding:15px;
        border-radius: 5px;
        margin: 10px auto;
    }

    .p0-s-t{
     
    }

    .payment-success-button{
    display: inline-block;
    padding: 4px;
    font-size: 20px;
    color: #133268;
    border-radius: 5px;
    box-shadow: 0px 0px 4px 0px #133;
    }

    .cart-card-co-t{
        background:#133268;
        margin:0px;
        padding:10px;
        color:white;
        display: grid;
        grid-template-columns: 5% 70% 20%;
        /* align-items: center; */
    }

    .cart-card-co-t-01{
       font-size: 20px;
       margin:5px 0px;
       padding:0px 5px;
       background:white;
       color:#133268;
       border-radius: 2px;
       display: inline-block;
    }

    .cart-card-co-t-02{
       font-size: 20px;
       margin:5px 0px;
    }

    .cart-card-co-t-03{
        font-size: 20px;
        margin:5px 0px;
        background:white;
       color:#133268;
        padding:5px 20px;
        display: inline-block;
        border-radius: 3px;
        cursor: pointer;
    }

    .title-desc-cart{
        color:#878787;
        padding-right: 10px;
    }

    .cart-change-user{
        color:#133268;
        cursor: pointer;
        margin:25px 0px;
    }

    .checkout-button-cart-b{
        display: inline-block;
        margin-top:5px;
        padding:12px;
        border-radius: 5px;
        box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        color: white;
    }


@media only screen and (max-width:768px){
    .flip-box-c{
        display: block;
    }
    .cart-page-leftdiv{
        width: auto !important;
    }
    .cart-page-rightdiv {
        width: auto !important;
    }
    .cart-card-co-t-02{
        margin:5px 15px;
    }
}