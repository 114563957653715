.tabsContainer{
    width: 90%;
    margin: 40px auto 20px;
}

.activeTab {
  background: linear-gradient(270deg, #032c6b 0%, #11898a 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.textLable{
    font-size: 26px !important;
    font-weight: 600 !important;
    text-transform: unset !important;
}


@media only screen and (max-width: 768px) and (min-width: 320px) {
    .textLable{
        font-size: 18px !important;
        font-weight: 600 !important;
        text-transform: unset !important;
    }
}