.refundmain{
    margin-top: 150px;
}
.tandc_title{
    font-weight: bold;
    font-size: 35px;
    color: #585555;
}
.refund-head{
    color: #585555;
    font-size: 23px;
}

.refund_para{
    color: #585555;
}