.contactformSection {
  text-align: center;
  /* padding: 50px 0; */
  padding: 50px;
  position: relative;
}

.innercontactContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.contactformImgContainer {
  width: 35%;
  background: radial-gradient(
    circle,
    rgba(37, 103, 147, 0.21052170868347342) 0%,
    rgba(255, 255, 255, 1) 70%
  );
  overflow: visible;

  & img {
    width: 100%;
  }
}
.borderContainer {
  /* margin: 0 auto; */
  width: 35%;
  padding: 4px;
  background: rgb(20, 135, 139);
  background: linear-gradient(
    120deg,
    rgba(20, 135, 139, 1) 0%,
    rgba(170, 212, 212, 1) 50%,
    rgba(20, 135, 139, 1) 100%
  );
  box-sizing: border-box;
}
.fromContainer {
  background-color: #fff;
  padding: 40px;
  & .sectionHeading {
    margin: 0;
  }
}
.bgacolor {
  background-color: rgba(217, 217, 217, 0.15);
}
.textfieldContainer {
  padding: 2px 40px;

  & .fullwidth {
    margin: 10px 0;
    width: 100%;
  }
}
.formsubmited {
  /* font-size: 25px; */
  /* background-color: #5cb85c; */
  /* border-radius: 100px; */
  /* color: #fff; */
  /* padding: 30px; */
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
.postions {
  position: absolute;
}

.cfone {
  top: 10%;
  left: 3%;
}
.cftwo {
  bottom: 10%;
  left: 4%;
}
.cfthree {
  bottom: 0;
  right: 15%;
}
.sectionHeading {
  font-size: 40px;
  font-weight: 600;
  color: #032c6b;
  margin-top: 20px;
}
.serviceInfopara {
  font-size: 18px;
  font-weight: 400;
  color: rgba(3, 44, 107, 0.8);
}
.fromsubmitbtn {
  background-color: #11898a !important;
  color: #ffffff !important;
  box-shadow: 0 4px 14px 0 rgba(23, 127, 141, 0.25) !important;
  padding: 10px 30px !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  margin-top: 40px !important;
  width: 100% !important;
}
@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .contactformImgContainer {
    display: none;
  }

  .borderContainer {
    width: 70%;
  }
  .productBox {
    width: 40%;
  }
  .innerlogocontainer {
    width: 130px;
  }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .cfone,
  .cftwo,
  .cfthree {
    display: none;
  }
  .borderContainer {
    width: 100%;
  }
  .fromContainer {
    padding: 20px;
  }

  .textfieldContainer {
    padding: 10px;
  }
  .contactformSection {
    padding: 20px;
  }
  .innercontactContainer {
    /* display: flex; */
    justify-content: space-around;
  }

  .contactformImgContainer {
    display: none;

    & img {
      width: 100%;
    }
  }

  .sectionHeading {
    font-size: 24px !important;
    font-weight: 600;
  }
  .serviceInfopara {
    font-size: 16px;
    font-weight: 400;
  }
}
