.slick-dots{
    /* top: 25px; */
}
.slick-dots > ul{
    display: flex;
    justify-content: center;
}
.slick-dots li {
    width: 100px;
    overflow: hidden;
}

 .slick-active > .update-slick{
    /* border: 2px solid !important; */
}

.box_Design{
    margin: 30px 0;
}