.slick-dots {
    bottom: 5%;
    width: 15%;
    margin-left: 20%;
  }
  .slick-dots > ul {
    display: flex;
    padding: 0;
  }
  .slick-dots li {
    width: 100%;
    overflow: hidden;
  }
  .slick-next{
    right: 20px;
  }

  .slick-prev{
    left: 20px;
  }
  .slick-active > .update-slick {
    border-bottom: 7px solid #12888a !important;
    background: #12888a;
    border-radius: 10px;
  }
  
  /* .box_Design {
    margin: 30px 0;
  } */
  .bx_first {
    padding: 30px 0 12%;
    margin-left: 20px;
    width: 100%;
  }
  .bx_firstU {
    width: 100%;
    margin-left: 26vw;
    position: relative;
  }
  
  .box_main {
    position: relative;
    padding: 40px 25px;
    width: auto !important;
    display: block !important;
  }
  
  .Ellipse-1 {
    width: 279.4px;
    height: 295px;
    background-color: #673c69;
    position: absolute;
    z-index: -1;
    border-radius: 50%;
    left: 1px;
  }
  
  .Ellipse-2 {
    position: absolute;
    top: 5px;
    left: 142px;
    z-index: -3;
    width: 226px;
  }
  .Ellipse-3 {
    width: 336px;
    height: 308px;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    background-color: #673c69;
  }
  .Ellipse-4 {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-color: #ddd2e2;
  }
  .box-outer {
    margin: 10vh 30px 6vh;
    padding: 10px 0 1vh;
    position: relative;
  }
  .testimonialCard {
    display: flex;
    /* justify-content: space-around; */
    justify-content: center;
    /* padding: 10%; */
    position: relative;
    /* overflow: hidden; */
  }
  .box-inner {
    /* width: 35%; */
    width: 40%;
    margin: auto 50px;
    display: flex;
    flex-direction: column;
  }
  .herosContainer {
    position: relative;
    height: 42vh;
    display: flex;
    align-items: center;
  }
  .heros {
    /* position: absolute;
    bottom: 0;*/
    width: 300px;
    margin-bottom: 4px;
  }
  .herosU {
    position: absolute;
    max-width: 24vw;
    object-fit: contain;
    object-position: bottom;
    height: 60vh;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
  .herosBgU {
    position: absolute;
    width: 100%;
    height: 42vh;
    bottom: 0;
    object-fit: fill;
  }
  
  .gfxhclkj {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
  
  .sq_name {
    font-size: 40px;
    margin: 0 0 10px 0;
    text-transform: capitalize;
    color: #412452;
  }
  .sq_desig {
    font-size: 28px;
    color: #412452;
    margin: 10px 0;
  }
  
  .sq_Vac {
    font-size: 24px;
    color: #412452;
    margin: 10px 0;
  }
  
  .sq_exp {
    width: 84px;
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: 2px 2px 6px rgb(184, 181, 181);
    margin: 0px 106px 0 2px;
    color: #412452;
    font-weight: 500;
    background-color: #fff;
  }
  
  .Test_images {
    width: 50%;
    height: 300px;
    background-color: crimson;
    float: right;
  }
  .bx_test_img {
    background-image: linear-gradient(to right, #412452, rgb(245, 245, 245));
    padding: 10px 20px 0;
    /* border-radius: 125px; */
  }
  .box_testimonial {
    display: flex !important;
    margin: 10px 20px;
    background-color: #ffffff;
    box-shadow: 2px 2px 2px #bfbaba;
    border-radius: 12px;
    align-items: center;
    border-radius: 80px;
    overflow: hidden;
  }
  
  .test_content {
    text-align: center;
    flex: 1;
    padding: 0 5%;
  }
  
  .test_head {
    font-size: 23px;
    margin: 30px 0 10px;
    text-transform: capitalize;
  }
  
  .text_test {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* number of lines to show */
    line-clamp: 5;
    -webkit-box-orient: vertical;
    /* text-transform: capitalize; */
  }
  
  .box_inner_last {
    align-self: center;
  }
  .box_inner_lastU {
    position: relative;
    align-self: flex-start;
    margin: 3% 8% 0 0;
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: 2px 2px 6px rgb(184, 181, 181);
    color: #412452;
    font-weight: 500;
    min-width: max-content;
  }
  
  .shield {
    font-size: 24px;
    padding-right: 10px;
    color: #412452;
  }
  
  .main-box-bg {
    margin: 22vh 0 4%;
  }
  
  .box_vertical {
    display: flex;
    align-items: center;
    position: relative;
    background-image: linear-gradient(to right, #4d205c, #6f3885);
    margin-top: 3%;
    border-radius: 30px;
    padding: 2% 5%;
  }
  .box_vert_img {
    min-width: 68%;
    max-width: 70%;
  }
  .vert_img {
    object-fit: cover;
    width: 100%;
    margin-top: -15%;
  }
  .vert_content {
    color: #f4c53d;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .vert_btn {
    color: #412452;
    padding: 3px 16px;
    border-radius: 15px;
    font-size: 16px;
    font-weight: 400;
    margin-top: auto;
  }
  .testimonial_container {
    display: flex;
    justify-content: space-around;
    width: 40%;
    position: relative;
  }
  .testimonial_image_back {
    height: auto;
    /* background: rgba(148, 194, 194, 0.5); */
    /* box-shadow: -16px 0px 25px rgba(148, 194, 194, 0.1); */
    /* transform: rotate(-13.92deg); */
    border-radius: 5px;
    right: 78px;
    top: -24px;
    z-index: 0;
    position: absolute;
  }
  .testimonial_image {
    background: #f8f9f9;
    box-shadow: 0px 15px 25px rgb(148 194 194 / 10%);
    border-radius: 5px;
    transform: rotate(6.09deg);
    width: 300px;
    height: 400px;
    position: absolute;
    right: 59px;
    bottom: -9em;
  }
  .support_main {
    margin-bottom: 10px;
    /* padding-right: 10%;
    padding-left: 10%; */
  }
  .testimonial_info {
    border-left: 3px solid rgb(21, 143, 146);
    margin-left: -20px;
    padding-left: 20px;
  }

  .hide-pc{
    display: none;
  }

  .new-carousel-container{
    /* margin-right: -150px; */
    width: 20%;
    /* border-radius: 50px; */
    min-height: 240px;
    overflow: hidden;
    margin: auto 0;
    & img{
      width: 100%;
    }
  }

  .border_zero{
    border-radius: 20px;
    overflow: hidden;
  }
  @media screen and (max-width: 1900px) and (min-width: 992px) {
    .sq_name {
      font-size: 30px;
      margin: 0 0 5px 0;
      white-space: nowrap;
    }
    .sq_desig {
      font-size: 20px;
      margin: 5px 0;
    }
    .sq_Vac {
      font-size: 18px;
      color: #412452;
      margin: 5px 0;
    }
    .box_inner_last {
      align-self: flex-end;
    }
    .sq_exp {
      margin-bottom: 30px;
    }
  }
  
  @media screen and (max-width: 1200px) and (min-width: 993px) {
    .herosU {
      height: 46vh;
    }
    .herosContainer,
    .herosBgU {
      height: 32vh;
    }
    .main-box-bg {
      margin: 18vh 0 4%;
    }
  }
  
  @media screen and (max-width: 992px) and (min-width: 768px) {
    .heros {
      width: 190px;
    }
    .bx_first {
      padding: 8px 0;
    }
    .sq_name {
      font-size: 20px;
      margin: 0 0 0 0;
    }
    .sq_desig {
      font-size: 18px;
      margin: 0;
    }
    .sq_Vac {
      font-size: 18px;
      margin: 5px 0;
    }
    .sq_exp {
      width: 70px;
      margin: 80px 50px 0 2px;
      font-size: 12px;
    }
    .box_inner_last {
      align-self: center;
    }
    .herosU {
      height: 26vh;
    }
    .herosContainer,
    .herosBgU {
      height: 14vh;
    }
    .main-box-bg {
      margin: 12vh 0 4%;
    }
  
    .testimonial_container{
      left: 20%;
    }
  }
  
  @media screen and (max-width: 767px) and (min-width: 320px) {
    .slick-dots > ul {
      display: flex;
      justify-content: center;
    }
    .slick-dots li {
      width: 4em;
      overflow: hidden;
      height: 20px;
      margin: 0 5px;
    }
    .box-outer {
      margin: 4vh 2px 1vh;
      padding: 10px 0 0;
    }
    .heros {
      width: 90px;
      margin-bottom: 4px;
    }
    .bx_first {
      padding: 8px 0;
      margin-left: 2px;
    }
    .sq_name {
      font-size: 12px;
      margin: 0 0 0 0;
    }
    .sq_desig {
      font-size: 10px;
      margin: 0;
    }
    .sq_Vac {
      font-size: 10px;
      margin: 4px 0;
      white-space: nowrap;
    }
    .sq_exp {
      margin: 65% 20px 0 8px;
      padding: 6px 12px;
      font-size: 8px;
      width: 44px;
    }
    .box_inner_last {
      align-self: center;
    }
    .bg_sqd {
      height: 13vh;
    }
    .shield {
      width: 12px;
    }
    .box_main {
      padding: 20px 8px;
    }
    .Test_images {
      width: 100%;
      height: 120px;
    }
    .Ellipse-1 {
      width: 78px;
      height: 78px;
    }
    .Ellipse-2 {
      width: 96px;
      left: 45px;
    }
    .Ellipse-3 {
      width: 100px;
      height: 100px;
    }
    .Ellipse-4 {
      width: 90px;
      height: 90px;
      margin-bottom: 20px;
    }
    .bx_test_img {
      padding: 10px 0 0;
    }
    .box_testimonial {
      border-radius: 40px;
      margin: 10px;
    }
    .test_head {
      font-size: 16px;
      margin: 10px 0;
    }
    .text_test {
      font-size: 13px;
      -webkit-line-clamp: 3; /* number of lines to show */
      line-clamp: 3;
    }
    .herosU {
      height: 20vh;
      max-width: 32vw;
    }
    .herosContainer,
    .herosBgU {
      height: 10vh;
    }
    .bx_firstU {
      margin-left: 30vw;
    }
    .box_inner_lastU {
      font-size: 10px;
      padding: 5px 10px;
      border-radius: 10px;
      margin-right: 4%;
    }
    .main-box-bg {
      margin: 12vh 0 4%;
    }
    .box_vertical {
      flex-direction: column;
      margin-top: 12%;
    }
    .box_vert_img {
      min-width: 100%;
    }
    .vert_img {
      margin-top: -20%;
    }
    .vert_content {
      text-align: center;
      padding: 2% 5%;
      min-height: 250px;
    }
    .vert_content h1 {
      font-size: 20px;
    }
    .vert_desc,
    .vert_btn {
      font-size: 14px;
    }
    .vert_btn {
      width: 100%;
      border-radius: 6px;
      margin-bottom: 20px;
    }
    .testimonial_container {
      width: 100%;
    }
    .testimonial_image_back {
      /* right: 0;
      top: 0; */
      /* position: relative; */
      left: -59px;
      top: -40px;
      z-index: 0;
      position: absolute;
    }
    .testimonial_image {
      width: 210px;
      height: auto;
      z-index: 2;
      position: relative;
      right: -43px;
      top: 64px;
    }
    .testimonialCard {
      padding: 0 5%;
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: column-reverse;
      margin-top: 50px;
      overflow: visible;
    }
    .box-inner {
      width: 90%;
      /* margin: 107px auto; */
      margin: 0 auto;
      display: flex;
      flex-direction: column;
    }
    .testimonial_info {
      border-left: 3px solid rgb(21, 143, 146);
      margin-left: 0px;
      padding-left: 20px;
    }
    .slick-dots {
      margin: auto;
      display: flex;
      width: 100%;
      justify-content: center;
      position: absolute;
      bottom: 25px;
    }

    .new-carousel-container{
        width: 100%;

        & img{
            width: 100%;
        }
    }
    .hide-mobile{
      display: none;
    }
    .hide-pc{
      display: block;
    }
  }
  
  @media screen and (max-width:650px) {
    .testimonial_image_back{
      width:320px;
      left:5%;
      
    }
  
    
  }
  
  @media screen and (max-width: 1900px) and (min-width: 992px) {
    .sq_name {
      font-size: 30px;
      margin: 0 0 5px 0;
      white-space: nowrap;
    }
    .sq_desig {
      font-size: 20px;
      margin: 5px 0;
    }
    .sq_Vac {
      font-size: 18px;
      color: #412452;
      margin: 5px 0;
    }
    .box_inner_last {
      align-self: flex-end;
    }
    .sq_exp {
      margin-bottom: 30px;
    }
  }
  
  @media screen and (max-width: 1200px) and (min-width: 993px) {
    .herosU {
      height: 46vh;
    }
    .herosContainer,
    .herosBgU {
      height: 32vh;
    }
    .main-box-bg {
      margin: 18vh 0 4%;
    }
  }
  
  @media screen and (max-width: 992px) and (min-width: 768px) {
    .heros {
      width: 190px;
    }
    .bx_first {
      padding: 8px 0;
    }
    .sq_name {
      font-size: 20px;
      margin: 0 0 0 0;
    }
    .sq_desig {
      font-size: 18px;
      margin: 0;
    }
    .sq_Vac {
      font-size: 18px;
      margin: 5px 0;
    }
    .sq_exp {
      width: 70px;
      margin: 80px 50px 0 2px;
      font-size: 12px;
    }
    .box_inner_last {
      align-self: center;
    }
    .herosU {
      height: 26vh;
    }
    .herosContainer,
    .herosBgU {
      height: 14vh;
    }
    .main-box-bg {
      margin: 12vh 0 4%;
    }
  }
  
  @media screen and (max-width: 767px) and (min-width: 320px) {
    .box-outer {
      margin: 4vh 2px 1vh;
      padding: 10px 0 0;
    }
    .heros {
      width: 90px;
      margin-bottom: 4px;
    }
    .bx_first {
      padding: 8px 0;
      margin-left: 2px;
    }
    .sq_name {
      font-size: 12px;
      margin: 0 0 0 0;
    }
    .sq_desig {
      font-size: 10px;
      margin: 0;
    }
    .sq_Vac {
      font-size: 10px;
      margin: 4px 0;
      white-space: nowrap;
    }
    .sq_exp {
      margin: 65% 20px 0 8px;
      padding: 6px 12px;
      font-size: 8px;
      width: 44px;
    }
    .box_inner_last {
      align-self: center;
    }
    .bg_sqd {
      height: 13vh;
    }
    .shield {
      width: 12px;
    }
    .box_main {
      padding: 20px 8px;
    }
    .Test_images {
      width: 100%;
      height: 120px;
    }
    .Ellipse-1 {
      width: 78px;
      height: 78px;
    }
    .Ellipse-2 {
      width: 96px;
      left: 45px;
    }
    .Ellipse-3 {
      width: 100px;
      height: 100px;
    }
    .Ellipse-4 {
      width: 90px;
      height: 90px;
      margin-bottom: 20px;
    }
    .bx_test_img {
      padding: 10px 0 0;
    }
    .box_testimonial {
      border-radius: 40px;
      margin: 10px;
    }
    .test_head {
      font-size: 16px;
      margin: 10px 0;
    }
    .text_test {
      font-size: 13px;
      -webkit-line-clamp: 3; /* number of lines to show */
      line-clamp: 3;
    }
    .herosU {
      height: 20vh;
      max-width: 32vw;
    }
    .herosContainer,
    .herosBgU {
      height: 10vh;
    }
    .bx_firstU {
      margin-left: 30vw;
    }
    .box_inner_lastU {
      font-size: 10px;
      padding: 5px 10px;
      border-radius: 10px;
      margin-right: 4%;
    }
    .main-box-bg {
      margin: 12vh 0 4%;
    }
    .box_vertical {
      flex-direction: column;
      margin-top: 12%;
    }
    .box_vert_img {
      min-width: 100%;
    }
    .vert_img {
      margin-top: -20%;
    }
    .vert_content {
      text-align: center;
      padding: 2% 5%;
      min-height: 250px;
    }
    .vert_content h1 {
      font-size: 20px;
    }
    .vert_desc,
    .vert_btn {
      font-size: 14px;
    }
    .vert_btn {
      width: 100%;
      border-radius: 6px;
      margin-bottom: 20px;
    }
  
    .removeMarginBottom{
      margin-bottom: 0px;
    }
  
  }
  