/* body {
  background-color: #f9efe2;
} */

.show {
  display: block;
  margin: 0 auto;
}

.hide {
  display: none;
}

.height_auto {
  height: auto !important;
  overflow: auto !important;
  -moz-transition: height 1s ease;
  -webkit-transition: height 1s ease;
  -o-transition: height 1s ease;
  transition: height 1s ease;
}

/* 1 - show case container*/
.showcontainer {
  position: relative;
  width: 94%;
  margin: 0 auto;
}
.topback {
  position: absolute;
  top: 0;
  width: 97%;
  height: 100%;
  margin: 0 auto;
  z-index: -1;
}
.showback {
  display: none;
}
.Showcase {
  /* background-image: url("../../Assets/hOME pAGE/ASSETS/1x/Asset 1.png");
  background-size: auto auto;
  background-size: cover; */
  position: relative;
  display: flex;
  margin-top: 100px;
  margin-right: 0px !important;
  padding: 85px 0;
  width: 100%;
  justify-content: space-around;
}
.showBox_one {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  margin: auto 0;
  font-size: 18px;
  width: 51%;
  padding: 0 0 0 7%;
}
.showBox_one h1 {
  color: #313e6c;
  font-size: 4em;
  font-weight: bold;
  margin-bottom: 20px;
  max-width: 400px;
}

.showBox_one p {
  color: #585555;
  font-size: 1.5em;
  white-space: nowrap;
}
.showBox_content {
  margin-bottom: 30px;
}
.showBox_content > p {
  margin-top: 2px;
  margin-bottom: 2px;
  text-align: left;
}
.girlBox {
  margin-right: auto;
}
.btn-class {
  display: flex;
  margin-bottom: 30px;
}
.store {
  display: flex;
}
.store img {
  width: 188px;
  margin: 20px;
}
.store img:first-child {
  margin-left: 0;
}

.showgirl {
  width: 100%;
  z-index: -1;
}
/* End of show case container*/

/* IIT */
.IIT_main {
  width: 90%;
  margin: 0 auto;
}

.iit_img {
  width: 100%;
  padding: 30px 0;
}
/* End*/

/* 2 - Main container*/
.Our-main {
  background-color: #f7faff;
  margin: 10px 5% 40px;
  text-align: center;
  border-radius: 75px;
  padding: 32px 10px 14px;
  box-shadow: -2px 6px 20px #b9b8b8;
}
.Our-appro {
  display: flex;
  font-size: 20px;
  justify-content: space-around;
  align-items: center;
  border-radius: 20px;
}

.sub-appr-head,
.sbox-join {
  margin: 0px;
  text-align: left;
  color: #556087;
}
.sub-appr-head {
  font-size: 2em;
  font-weight: 450;
  text-transform: uppercase;
  color: #2f3e73;
  margin-bottom: 20px;
}
.line {
  border: 2px solid #ee9435;
  background:  linear-gradient(97.14deg, #11888A -3.11%, #2A5E95 100%);
  padding: 93px 0;
}
.sbox-join {
  max-width: 422px;
  font-size: 1.4em;
  color: #2f3e73;
}
.sb-appr {
  display: flex;
  flex-direction: column;
  width: 31%;
  margin-bottom: 50px;
}
.sb-appr > p {
  text-align: left;
  font-weight: 450;
  font-size: 1.2em;
  text-transform: uppercase;
  color: #2f3e73;
}
.sb-appr ul {
  margin: 0;
}
.sb-appr ul.dashs {
  padding-left: 0;
  list-style-type: none;
}
.sb-appr ul.dashs > li {
  text-align: left;
  font-size: 1.1em;
  color: #2f3e73;
  text-indent: -1px;
  padding: 3px;
  white-space: nowrap;
}
.sb-appr ul.dashs > li:before {
  content: "-";
  text-indent: -1px;
  padding-right: 4px;
}
.sb-appr p {
  margin-bottom: 8px;
}
.sb-appr img {
  width: 90px;
  margin-bottom: 20px;
}
.btn-addmore {
  display: flex;
  justify-content: center;
  padding: 15px 6px;
  background-color: white;
  border: 4px solid #ee9435;
  color: #022e7a;
  font-weight: 570;
  font-size: 22px;
  border-radius: 12px;
  margin: 0 auto;
  margin-bottom: 13px;
  width: 317px;
}
/* End of Main container*/

/* 3 - Courses Main*/
.cls-list {
  position: relative;
  margin: 10px 5% 121px;
}
.rounded_cs {
  position: absolute;
  top: -83px;
  right: 114px;
  z-index: -1;
}
.sb-cls-head {
  margin: 20px 0;
  padding: 10px;
}
.sb-cls-head h1 {
  font-weight: 500;
  color: #2f3e73;
  border-left: 2px solid #ee9435;
  padding-left: 10px;
}
.cls-btns {
  overflow: auto;
  margin-bottom: 50px;
}
.sb-cls-btn {
  float: left;
  border: 1px solid #556087;
  font-size: 24px;
  font-weight: 540;
  padding: 6px 20px;
  margin-right: 35px;
  border-radius: 12px;
  text-align: center;
  min-width: 100px;
  color: #556087;
  cursor: pointer;
  background-color: #f9efe2;
  white-space: nowrap;
}
.sb_list_main {
  display: flex;
  width: 57%;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 286px;
  overflow: hidden;
}
.sb-cls-btn:hover {
  background-color: #eea85e;
  border: none;
  color: #f5efe9;
}
.views {
  float: right;
  font-size: 25px;
}
.view-exp {
  color: #313e6c;
}
.card-class {
  padding: 0 10px;
}
.cls-card {
  display: flex;
  justify-content: space-around;
}
.ITEM {
  border: 2px solid;
  height: 300px;
  margin-right: 10px;
}
/* End of Courses Main*/

/* 4 - Discover yourself*/
.intro-container {
  background-color: #fcead7;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 87%;
  border-radius: 80px;
  padding: 25px;
}
.intro-img {
  padding: 0 57px;
}

.intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  width: 600px;
  padding: 32px;
}
.intro-line {
  border: 2px solid #eea85e;
  margin: 100px 0;
  background-color: #eea85e;
}
.intro h1,
button {
  color: #2f3e73;
}

.intro > h1 {
  font-size: 3em;
  margin-bottom: -2px;
  white-space: nowrap;
}
.intro > div > button {
  /*********check here************/
  /******************************/
  width: 200px;
  padding: 19px 18px;
  font-size: 19px;
  font-weight: 700;
  border: 3px solid #ee9435;
  background-color: white;
  border-radius: 10px;
}
.img-intro {
  width: 80%;
}
.intro p {
  margin: 20px 0;
  font-size: 20px;
  max-width: 81%;
}
/* End of Discover yourself*/

/* 5- Edunguru container*/
.edun {
  /* background-color: #f5e6d29e; */
  padding: 20px 0;
  margin: 0 10% 40px;
}

.edun-title {
  padding: 30px 0px;
}
.edun_child_img {
  width: 100%;
  padding: 13px;
  box-sizing: border-box;
  text-align: center;
}

.edun-title h1 {
  font-weight: 500;
  color: #2f3e73;
  border-left: 2px solid #ee9435;
  padding-left: 20px;
}

.edun-sub {
  display: flex;
  justify-content: space-around;
  margin: 10px 20px;
}

.edun-text {
  font-size: 26px;
  font-weight: 500;
  color: #2f3e73;
  box-sizing: border-box;
  padding: 12px 0px;
  text-align: center;
  max-width: fit-content;
}
.edun-child {
  margin: 10px;
  box-sizing: border-box;
  max-width: 201px;
}
.edun-img {
  width: 100%;
  height: 135px;
}
/* End of edunguru*/

/* 6- lcaro template*/
.lcr-temp {
  padding: 0 10%;
  position: relative;
  margin-bottom: 44px;
}

.lcr-content h2 {
  color: #2f3e73;
  font-size: 30px;
  font-weight: 500;
  border-left: 2px solid #ee9435;
  padding-left: 20px;
}

.mbls-div {
  display: flex;
  justify-content: space-around;
  margin-top: 58px;
}
.mob {
  width: 300px;
  margin: 20px;
}
.mobss {
  display: flex;
  flex-direction: column;
}
.mobtwo {
  align-self: center;
}
.mobthree {
  align-self: flex-end;
  margin-top: 215px;
}
.mbl-background {
  position: absolute;
  top: -221px;
  display: flex;
  justify-content: center;
  z-index: -3;
  width: 70%;
}
.dimond {
  width: 70%;
}
.mob-btn {
  padding: 6px 20px;
  width: 80%;
  margin: 14px auto;
  border-radius: 30px;
  font-size: 21px;
  border: 1px solid #ee9435;
  background-color: #fdf3e5;
}
.curve-line {
  position: absolute;
  top: 263px;
  left: -163px;
  z-index: -1;
}
.curve {
  width: 100%;
}

/* End of lcaro*/

@media screen and (min-width: 993px) and (max-width: 1440px) {
  .showBox_one h1 {
    font-size: 3.4em;
  }
  .Showcase {
    padding-top: 31px;
  }
  .sb_list_main {
    height: 265px;
    overflow: hidden;
  }
  .intro > h1 {
    font-size: 2em;
  }
  .intro p {
    margin: 20px 0;
    font-size: 20px;
    max-width: 100%;
    text-align: left;
  }
  .intro > div > button {
    width: 173px;
    padding: 11px 10px;
    font-size: 16px;
  }
  .store img {
    width: 156px;
    margin: 20px;
  }
  .intro-line {
    margin: 40px 0;
  }
  .Our-main {
    padding: 46px 10px 1px 20px;
  }
  .sub-appr-head {
    font-size: 2em;
  }
  .sbox-join {
    max-width: 300px;
    font-size: 1em;
  }
  .sb-appr > p {
    font-size: 1em;
  }
  .sb-appr ul.dashs > li {
    font-size: 0.87em;
  }
  .line {
    border: 0.5px solid #ee9435;
    padding: 74px 0;
  }
  .btn-addmore {
    font-size: 17px;
    width: 224px;
    display: flex;
    align-items: center;
  }

  .edun-img {
    width: 90%;
    height: 120px;
  }
}
/*Laptop view*/

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .Showcase {
    margin: 0px auto;
    padding: 37px 0;
    width: 100%;
    padding-top: 160px;
  }
  .showcontainer {
    padding: 0px;
    margin: 0;
    width: 100%;
  }
  .showback {
    margin: 0px;
  }
  .showBox_one {
    font-size: 13px;
    padding: 0;
    padding-left: 22px;
    width: 60%;
  }
  .btn-class {
    flex-direction: column;
    margin-bottom: 0px;
  }
  /* .btn-prim, .btn-stud {
        padding: 10px 20px;
        border-radius: 15px;
    }
    .btn-class .btn {
        margin-right: 0px;
        margin-bottom: 10px;
    } */
  .Our-main {
    background-color: #f7faff;
    margin: -30px 30px 40px;
    text-align: center;
    border-radius: 45px;
    padding-bottom: 5px;
  }
  .Our-appro {
    flex-wrap: wrap;
    font-size: 16px;
  }
  .sb-appr ul.dashs > li {
    font-size: 1em;
  }
  .sb_list_main {
    height: 250px;
    overflow: hidden;
    width: 100%;
  }
  .sb-appr {
    align-items: center;
    justify-content: center;
  }
  .sub-appr {
    display: block;
    margin: 0 10px 20px;
    width: 100%;
  }
  .sub-appr-head {
    margin: 0 10px 5px;
    text-align: center;
  }
  .sbox-join {
    max-width: 100%;
    text-align: center;
    margin-bottom: 0px;
  }
  .sb-appr img {
    width: 80px;
  }
  .btn-addmore {
    padding: 14px 28px;
    font-weight: 700;
    font-size: 17px;
  }

  .intro-container {
    margin: 0px 20px;
    padding: 29px;
  }
  .intro-img {
    margin: 0;
    padding: 0 10px;
  }
  .intro-line {
    display: none;
  }
  .img-intro {
    width: 100%;
  }
  .intro {
    width: 420px;
    padding: 12px;
  }

  .edun {
    margin: 0;
  }
  .edun-child {
    padding: 10px;
  }
  .edun-text {
    display: none;
  }

  .edun-img {
    width: 100%;
  }

  .cls-list {
    margin: 10px 48px 46px;
  }
}

/*-------------- Mobile View ----------*/

@media only screen and (max-device-width: 767px) {
  .showcontainer {
    padding: 0;
    /* background-image: none; */
    position: relative;
    width: 100%;
  }
  .store img {
    width: 76px;
    height: 25px;
    margin: 1px;
  }
  .store img:first-child {
    margin-left: 0;
  }
  .showback {
    display: block;
    position: absolute;
    top: 0;
    z-index: -2;
    margin: -3px;
    padding: 0;
  }
  .Showcase {
    padding: 7px 7px 40px;
    margin: 0px;
    width: 95%;
    padding-top: 190px;
    flex-direction: column-reverse;
  }
  .topback {
    width: 100%;
  }
  .showBox_one {
    font-size: 8px;
    width: 100%;
    padding: 0;
    align-items: center;
  }
  .showBox_one p {
    font-size: 0.5em;
    white-space: pre-wrap;
  }
  .showBox_one > .showBox_heading,
  .showBox_content {
    margin-bottom: 10px !important;
    font-size: 3.2em;
    font-weight: bolder;
  }
  .girlBox {
    text-align: end;
    margin: 0;
  }
  .showgirl {
    width: 100%;
  }
  .btn-class {
    flex-direction: column;
    margin-bottom: 0px;
  }
  .btn-class .btn {
    padding: 7px;
    margin: 0 0 10px 0 !important;
    width: 100%;
    border-radius: 8px;
  }

  .Our-main {
    margin: 10px;
    border-radius: 50px;
  }
  .Our-appro {
    flex-wrap: wrap;
    font-size: 10px;
  }
  .sb-appr ul.dashs > li {
    font-size: 1em;
  }
  .sb_list_main {
    height: 148px;
    width: 100%;
  }
  .sb-appr {
    align-items: center;
    justify-content: center;
  }
  .sub-appr {
    display: block;
    margin: 0 10px 20px;
    width: 100%;
  }
  .sub-appr-head {
    margin: 0 10px 5px;
    text-align: center;
  }
  .sbox-join {
    max-width: 100%;
    text-align: center;
    margin-bottom: 0px;
  }
  .sb-appr > p {
    text-align: center;
    font-weight: 700;
    margin-bottom: 3px !important;
  }
  .sb-appr img {
    width: 40px;
  }
  .btn-addmore {
    padding: 10px 5px;
    font-weight: 700;
    font-size: 11px;
    width: 54%;
  }
  .sb-cls-head {
    margin: 10px;
    font-size: 10px;
  }
  .sb-cls-btn {
    margin-right: 4px;
    min-width: 51px;
    font-size: 11px;
    margin-bottom: 10px;
    padding: 6px 11px;
  }
  .views {
    font-size: 15px;
    margin-top: 10px;
  }
  .cls-list {
    margin: 10px 10px 50px;
  }

  .intro > div > button {
    width: 90%;
    padding: 5px 5px;
    font-size: 12px;
    font-weight: 700;
    border: 3px solid #ee9435;
    background-color: white;
    border-radius: 10px;
  }
  .intro-container {
    margin: 0 auto;
    padding: 0px;
    border-radius: 0;
  }
  .img-intro {
    width: 100%;
  }

  .intro {
    width: 500px;
    padding: 12px 5px;
    box-sizing: border-box;
  }
  .intro-line {
    display: none;
  }
  .intro-img {
    margin: 0;
    padding: 0 10px;
  }
  .intro p {
    margin: 8px 0;
    font-size: 12px;
    max-width: 100%;
  }
  .intro h1 {
    white-space: pre;
    font-size: 1em;
    margin-bottom: 5px;
    font-weight: bolder;
  }

  .edun {
    margin: 0;
  }

  .edun-child {
    width: 100%;
  }
  .edun_child_img {
    padding: 10px;
  }
  .edun-text {
    display: none;
  }
  .edun-title {
    padding: 10px;
  }
  .edun-img {
    width: 100%;
    height: auto;
  }
  .edun-title h1 {
    font-size: 15px;
    padding-left: 10px;
  }
  .mbl-background {
    position: absolute;
    top: -69px;
    display: flex;
    justify-content: center;
    z-index: -3;
    width: 98%;
  }
  .lcr-temp {
    padding: 10px;
    position: relative;
    margin-bottom: 44px;
    overflow: hidden;
  }
  .mobthree {
    margin-top: 0px;
  }
  .mobss {
    padding: 10px;
  }
  .mbls-div {
    width: fit-content;
  }
}
