.main_backGround {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 20;
}

.secondBox {
  max-width: 1400px;
  margin: 20px auto 20px;
  background-color: #fff;
}

.titleintrobox{
  width: 50%;
  margin: 0 auto;
  text-align: center;

}
.mentalHealthTitle{
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 2px solid #000;
  font-weight: 700;
}

.listingOuterBox {
  max-width: 1100px;
  margin: 0 auto;
  /* box-shadow: 1px 1px 12px #bfbfbf; */
  padding: 20px;
  border-radius: 21px;
  overflow: auto;
}

.btn_starts_test {
  padding: 10px 20px;
  font-size: 18px;
  background-color: #172e92;
  color: white;
  border: none;
  /* width: auto !important; */
  border-radius: 12px;
  box-shadow: 1.5px 1.5px 3px 0px #767676;
  cursor: pointer;
  /* white-space: nowrap; */
  /* margin: auto 0; */
}

.listingBox {
  margin: 40px 0;
  /* display: flex;
    justify-content: center; */
}

.mainBoxlisting {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  /* text-align: center; */
}

.testBox {
  width: 35%;
  padding: 20px;
  box-shadow: 1px 1px 12px #bfbfbf;
  border-radius: 35px;
}

.imgContainer {
  width: 100%;

  & img {
    width: 100%;
    border-radius: 25px;
  }
}
.testHEading{
    text-align: center;
}

.teststartBtn {
  padding: 10px 20px;
  font-size: 18px;
  background-color: #172e92;
  color: white;
  border: none;
  /* width: auto !important; */
  border-radius: 12px;
  box-shadow: 1.5px 1.5px 3px 0px #767676;
  cursor: pointer;
  margin:  0 auto;
  /* text-align: center; */
}

/* rules box css */

.introBox{
  padding: 40px;
  box-sizing: border-box;
}
.introtestheading{
  text-align: center;
  font-size: 40px;
}

.imgRuleBox{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1600px;
  margin: 0 auto;
}

.introimgContainer{
  width: 35%;

  & img{
    width: 100%;
    border-radius: 15px;
  }
}

.introtextContainer{
  width: 60%;
}

.rulesHeading{
  font-size: 22px;

}

.rulesLinstingHeading{
  font-size: 20px;
  margin: 10px;
}
.rulesPara{
  line-height: 1.4;
  margin: 5px 0;
  & span{
    /* text-decoration: ; */
  }
}

.ruleslist{
  /* list-style: disc; */
  margin-bottom: 40px;
  & li{
    list-style: disc;
  }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .main_backGround {
    height: auto;
  }

  .titleintrobox{
    width: 90%;
    margin: 20px auto;
    text-align: center;
  
  }
  .testBox {
    width: 100%;
  }

  .introBox{
    padding: 20px;
    box-sizing: border-box;
  }
  .introtestheading{
    text-align: center;
    font-size: 30px;
  }
  .imgRuleBox{
    flex-direction: column;
    width: 100%;
  }
  
  .introimgContainer{
    width: 100%;
  
    & img{
      width: 100%;
    }
  }
  
  .introtextContainer{
    width: 100%;
  }
}