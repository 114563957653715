.BannerSection {
  /* min-height: 700px; */
  /* background-color: #C1D2FF; */
  position: relative;
}

.absolute {
  position: absolute;
}
.bannerImageConatainer {
  width: 100%;
  & img {
    width: 100%;
  }
}

.bannertextOne {
  top: 0;
  left: 39%;
  font-size: 32px;
  font-weight: 400;
  text-align: center;

  & span {
    font-weight: 600;
  }
}

.signinComponent {
  bottom: 10%;
  left: 40%;
  text-align: center;
}

.bannertextTwo {
  font-size: 28px;
  font-weight: 500;
}
.signbtn {
  background-color: #7286d3 !important;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 700;
}
.signbtn:hover {
  background-color: #7286d3 !important;
}
.showCouterSection {
  padding: 50px 100px;
}
.counterMain {
  display: flex;
  padding: 40px 100px;
  background-color: #bbdeff;
  border-radius: 20px;
  box-shadow: 6px 6px 25px 0 rgba(93, 157, 218, 0.25) inset;
  justify-content: center;
  text-align: center;
  gap: 80px;
}

.number {
  font-size: 32px;
  font-weight: 700;
  color: #1a6ebc;
}

.title {
  font-size: 16px;
  font-weight: 400;
}

/* slider section */
.sliderContainer {
  padding: 50px 100px;
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
  .bannertextOne {
    top: 0;
    left: 20%;
    font-size: 14px;
    font-weight: 400;
    text-align: center;

    & span {
      font-weight: 600;
    }
  }

  .signinComponent{
    left: 25%;
    bottom: 5%;
  }

  .bannertextTwo{
    font-size: 12px;
  }
  .showCouterSection {
    padding: 20px;
  }

  .signbtn {
 
    font-size: 10px !important;
  
  }

  .counterMain {
    flex-wrap: wrap;
    padding: 20px;
    justify-content: space-around;
    row-gap: 10px;
    column-gap: 10px;
  }
  /* slider section */
  .sliderContainer {
    padding: 50px 20px;
  }
}
