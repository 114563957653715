.login-card-b{
    padding:15px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 16px;
    width:50%;
    border-radius: 5px
}

.login-buttons{
    display: grid;
    grid-template-columns: 48% 48%;
    grid-gap:2%;
    text-align: center;
}

.login-buttons p{
    border:1px solid #133268;
    padding:10px;
    border-radius: 5px;
    cursor: pointer;
    opacity: 0.8;
    margin-top: 3px;
}

.signup-card-su{
    background:#133268;
    color:#EF9022;
}

.login-buttons p:hover{
    opacity: 1;
}