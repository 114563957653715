/* common css */
.center {
  text-align: center;
}

.sectionHeading {
  font-size: 40px;
  font-weight: 600;
  color: #032c6b;
  margin: 20px 0;
}

.secondHeding {
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  padding: 10px;
  color: #118a8a;
  margin: 20px 0;
  & span {
    padding: 0 20px;
  }

  & .textOne {
    border-right: 2px solid #118a8a;
  }

  & .texttwo {
    border-left: 2px solid #118a8a;
  }
}
.postions {
  position: absolute;
}
/* banner css */

.BannerConatiner {
  background: linear-gradient(
    180deg,
    #90c9c9 0%,
    rgba(144, 201, 201, 0) 96.11%
  );
  padding: 60px 0;
}

.bannerImgContainer {
  width: 100%;

  & img {
    width: 100%;
  }
}
.bannertextmain {
  font-size: 40px;
  font-weight: 300;
  margin: 20px 0;
  font-style: italic;
}

.bannertextsmall {
  font-size: 25px;
  font-weight: 300;
  margin: 20px 0;
  font-style: italic;
}

.likeabutton {
  text-align: center;

  & span {
    background: linear-gradient(90deg, #12888b 0.81%, #2b5f96 100%);
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    padding: 10px 30px;
    border-radius: 25px;
  }
}

/* alphaguide test and counselling */
.alphaTC {
  padding: 40px 60px;
}

.genalphSection {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 40px 0;
}

.genimgContainer {
  width: 35%;

  & img {
    width: 100%;
    border-radius: 25px;
    box-shadow: 17px 20px 35.099998474121094px 0px #13868b26;
  }
}

.genimgContainer:last-child {
  & img {
    box-shadow: -17px 20px 25px 0px #13868b26;
  }
}

.genTextContainer {
  width: 50%;
}

.genCHeading {
  font-size: 25px;
  font-weight: 400;
  margin: 20px 0;
}

.innersecondHeding {
  font-size: 30px;
  font-weight: 500;
  color: #032c6b;
  margin: 20px 0;

  & span {
    font-size: 30px;
    font-weight: 600;
    color: #118a8a;
  }
}
.geninnerimgtext {
  display: flex;
  /* gap: 20px; */
  align-items: center;

  & .innersecondHeding {
    width: 55%;
  }
}

.genInnerimgcontainer {
  width: 100px;

  & img {
    width: 100%;
  }
}

.genpara {
  font-size: 22px;
  font-weight: 400;
  line-height: 1.4;
}

.boldertext {
  font-size: 24px;
  font-weight: 700;
  color: #118a8a;
}

.genbtnContainer {
  margin-top: 50px;
  display: none;
}

.btnGradient {
  background: linear-gradient(
    90deg,
    rgba(17, 137, 138, 1) 0%,
    rgba(44, 91, 150, 1) 100%
  );
  color: #ffffff !important;
  font-size: 16px !important;
  font-weight: 700;
}

/* ALPHAGUIDE OVERVIEW */

.alphaOverview {
  background-color: #fbfbfb;
  padding: 60px;
}

.sectionPara {
  text-align: center;
  font-size: 25px;
  font-weight: 400;
  color: #000;
}
/* career counselling */

.ccSection {
  padding: 40px;
}

.ccInnercontainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 60px 0 0;
}

.ccText {
  width: 40%;

  & p {
    font-size: 25px;
    line-height: 1.4;
  }
}
.ccImageContainer {
  width: 40%;

  & img {
    width: 100%;
  }
}

/* scientific adv */
.LJOS {
  background-color: #d8fffd;
  padding: 40px 40px;
  text-align: center;
  position: relative;
}
.riconone {
  top: -5%;
}
.ricontwo {
  bottom: 20%;
  left: 8%;
}
.riconthree {
  bottom: -15%;
  right: 0;
}
.textrightcontainer {
  width: 50%;
}
.mainforflex {
  display: flex;
  align-items: center;
}

.LJOSimgContainer {
  width: 25%;
  margin: 0 auto;

  & img {
    width: 100%;
    border-radius: 25px;
  }
}

.hisName {
  font-size: 30px;
  color: #032c6b;
  margin: 20px 0;
}

.iitslogoandname {
  display: flex;
  justify-content: center;
  gap: 30px;
  align-items: center;
  /* color: #032C6B; */
}

.iitsName {
  font-size: 25px;
  color: #032c6b;
  margin: 20px 0;
}
.hisQuote {
  font-size: 22px;
  color: #032c6b;
  margin: 20px 0;
}
.iitslogoConatiner {
  width: 90px;

  & img {
    width: 100%;
  }
}
.LJOSbtnContainer {
  margin-top: 20px;
}

@media only screen and (max-width: 1640px) and (min-width: 1024px) {
  /* alphaguide test and counselling */
  .genTextContainer {
    width: 55%;
  }

  .genimgContainer {
    width: 40%;

    & img {
      width: 100%;
      border-radius: 25px;
      box-shadow: 17px 20px 35.099998474121094px 0px #13868b26;
    }
  }
}
@media only screen and (max-width: 1024px) and (min-width: 768px) {
  /* alphaguide test and counselling */

  .alphaTC {
    padding: 20px 40px;
  }

  .genalphSection {
    flex-direction: column;
    margin: 40px 0;
  }

  .genalphSection:last-child {
    flex-direction: column-reverse;
  }

  .genimgContainer {
    width: 100%;

    & img {
      width: 100%;
    }
  }

  .genTextContainer {
    width: 100%;
  }

  .genCHeading {
    font-size: 25px;
    margin: 20px 0;
  }

  .innersecondHeding {
    font-size: 30px;
    margin: 20px 0;

    & span {
      font-size: 30px;
    }
  }

  .genpara {
    font-size: 20px;
  }

  .boldertext {
    font-size: 22px;
  }

  /* career counselling */
  .ccInnercontainer {
    flex-direction: column-reverse;
    margin: 60px 0 0;
  }

  .ccText {
    width: 100%;
    & p {
    }
  }

  .ccImageContainer {
    width: 100%;
  }
  /* scientific adv */
  .LJOSimgContainer {
    width: 40%;
  }
}
@media only screen and (max-width: 768px) and (min-width: 320px) {
  /* common css */
  .center {
    text-align: center;
  }

  .sectionHeading {
    font-size: 25px;
    font-weight: 600;
    color: #032c6b;
    margin: 20px 0;
  }

  .secondHeding {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    padding: 0px 0 20px;
    white-space: nowrap;
    color: #118a8a;
    margin: 0;
    & span {
      padding: 0;
    }

    & .textOne {
      border-right: 1px solid;
      padding-right: 5px;
    }

    & .texttwo {
      border-left: 1px solid;
      padding-left: 5px;
    }
  }

  /* banner css */

  .BannerConatiner {
    padding: 30px 0;
  }

  .bannerImgContainer {
    width: 100%;

    & img {
      width: 100%;
    }
  }
  .bannertextmain {
    font-size: 20px;
    font-weight: 300;
    margin: 10px 0;
    font-style: italic;
  }

  .bannertextsmall {
    font-size: 18px;
    font-weight: 300;
    margin: 20px 0;
    font-style: italic;
  }
  /* alphaguide test and counselling */
  .likeabutton {
    text-align: center;

    & span {
      background: linear-gradient(90deg, #12888b 0.81%, #2b5f96 100%);
      display: inline-block;
      color: #fff;
      font-size: 18px;
      font-weight: 500;
      padding: 10px 30px;
      border-radius: 25px;
    }
  }

  .alphaTC {
    padding: 20px ;
  }

  .genalphSection {
    flex-direction: column;
    margin: 40px 0;
  }

  .genalphSection:last-child {
    flex-direction: column-reverse;
  }

  .genimgContainer {
    width: 100%;

    & img {
      width: 100%;
    }
  }

  .genTextContainer {
    width: 100%;
  }

  .genCHeading {
    font-size: 16px;
    font-weight: 400;
    margin: 20px 0;
  }

  .innersecondHeding {
    font-size: 19px;
    font-weight: 500;
    color: #032c6b;
    margin: 20px 0;

    & span {
      font-size: 19px;
      font-weight: 600;
      color: #118a8a;
    }
  }

  .genpara {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4;
  }

  .boldertext {
    font-size: 18px;
    font-weight: 700;
    color: #118a8a;
  }

  .genbtnContainer {
    margin-top: 50px;
  }

  .btnGradient {
    background: linear-gradient(
      90deg,
      rgba(17, 137, 138, 1) 0%,
      rgba(44, 91, 150, 1) 100%
    );
    color: #ffffff !important;
    font-size: 16px !important;
    font-weight: 700;
  }

  /* ALPHAGUIDE OVERVIEW */

  .alphaOverview {
    background-color: #fbfbfb;
    padding: 20px;
  }

  .sectionPara {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    color: #000;
  }

  /* career counselling */

  .ccSection {
    padding: 20px;
  }

  .ccInnercontainer {
    flex-direction: column-reverse;
    margin: 60px 0 0;
  }

  .ccText {
    width: 100%;

    & p {
      font-size: 18px;
      line-height: 1.4;
    }
  }
  .ccImageContainer {
    width: 90%;

    & img {
      width: 100%;
    }
  }

  /* scientific adv */
  .LJOS {
    background-color: #d8fffd;
    padding: 20px;
    text-align: center;
    position: relative;
  }
  .riconone {
    top: -5%;
    right: 1%;
  }
  .ricontwo {
    bottom: 35%;
    left: auto;
    right: 0;
  }
  .riconthree {
    bottom: 10%;
    right: auto;
    left: 0;
  }
  .textrightcontainer {
    width: 100%;
  }
  .mainforflex {
    display: block;
  }

  .LJOSimgContainer {
    width: 100%;
    margin: 0 auto;

    & img {
      width: 100%;
      border-radius: 25px;
    }
  }

  .hisName {
    font-size: 20px;
    color: #032c6b;
    margin: 20px 0;
  }

  .iitslogoandname {
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
    /* color: #032C6B; */
  }

  .iitsName {
    font-size: 20px;
    color: #032c6b;
    margin: 20px 0;
  }
  .hisQuote {
    font-size: 18px;
    color: #032c6b;
    margin: 20px 0;
  }
  .iitslogoConatiner {
    width: 70px;

    & img {
      width: 100%;
    }
  }
  .LJOSbtnContainer {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 450px) {

  .alphaTC{
    padding: 20px 10px ;
  }
  .geninnerimgtext {
    display: flex;
    /* gap: 20px; */
    align-items: center;

    & .innersecondHeding {
      width: 80%;
    }
  }

  .genInnerimgcontainer {
    width: 65px;

    & img {
      width: 100%;
    }
  }
}
