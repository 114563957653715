.title {
    font-weight: 700;
    /* color: #fff; */
    font-size: 2.6rem;
}

.main-text-limiter {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.titledesc {
    color: #3c3b37;
    font-size: 1rem;
    overflow: hidden;
    margin: 10px 0px;
    position: relative;
    transition: all 1s linear;
}

.icon {
    color: #F7BB56;
}

.batchDetails {
    background-color: #f0f0f0;
    border-radius: 6px;
    padding: 0 2% 2%;
    display: flex;
  }

  .variantDays {
    display: flex;
    margin-top: 2%;
  }

  .dayCircle {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    width: 2rem;
    border-radius: 1rem;
    text-transform: uppercase;
    margin-left: -5px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  }
  
.image-c-t-cd{
    width:100px;
    text-align: center;
    background: white;
    display: inline-block;
    /* height: auto; */
    height: 100px;
    padding:5px;
    border-radius: 5px;
    display: grid;
    align-content: center;
    align-items: center;
    box-shadow: 1px 2px 4px 0px rgb(0 0 0 / 30%);
}

.rating {
    color: #F7BB56;
    font-size: 0.9rem;
    font-weight: 700;
    padding: 3px;
    /* margin-top:-22px;
    margin-left:85px; */
}

.crs-ban-text-c{
    display: flex;
    margin-bottom: 20px;
    padding-top: 5px;
    /* justify-content: space-between */

}

.crs-ban-text-c > div{
    width:38px;
    /* text-align: center; */
}

.display-star-icon > img {
    width:50%;
}

.display-star-icon > p {
    width:50%;
    text-align: center;
}
.offer-with-discount-p{
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap:15px;
}

.crs-ban-text-c-g{
    display: grid;
    grid-template-columns:108px auto;
    /* align-items: center; */
    /* margin:5px 10px 20px 0px; */
    /* margin-bottom:20px; */
    grid-gap:15px;
   
}

.new-discount-price {
    font-size: 35px;
    font-weight: 500;
    margin: 5px;
    color: #022E7A;
}

.internal-card-validity{
    padding: 10px;
    border-radius: 13px;
    margin: 10px 15px;
    box-shadow: 0px 0px 9px 0px rgb(0 0 0 / 20%);
    cursor: pointer;
}

.validity-head{
    font-weight: bold;
    font-size: 18px;
    padding-bottom: 10px;
}

.validity-date{
    color: gray;
    padding-bottom: 10px;
}

.crs-ban-text-c-ng{
    display:grid;
    grid-template-columns: 40px auto;
    align-items: center;
    /* margin:5px 10px 20px 0px; */
    margin-bottom:20px;

}

.course-details-banner-data {
    color: #022E7A;
    padding-left: 10px;
}

.crs-ban-text-c > div {
    /* margin:5px; */
    /* margin-right: 3px; */
}


.crs-ban-text-c > div {
    /* margin:5px; */

}
.rating1 {
    color: #fff;
    font-size: 0.9rem;
    font-weight: 400;
    /* margin-top:-34px;
    margin-left:85px; */
}

.rating2 {
    color: #fff;
    font-size: 0.9rem;
    font-weight: 400;
    /* margin-top:-34px;
    margin-left:200px; */
}

.icon3 {
    color: #fff;
}

.lang {
    color: #fff;
    /* margin-left:34px;
    margin-top:-27px; */
    display: flex;
    align-items: center;
}

.money {
    font-size: 2.3rem;
    font-weight: 700;
    margin-top: 10px;
    margin-left: 10px;
}

.stfont {
    color: #607d8b;
}

.discount {
    margin-top: 24px;
    margin-left: 8px;
}

.new-discount-v{
    font-weight: 700;
    font-size: 29px;
    margin: 5px;
    color: #7EB62D;
}

.offer-content{
    background:#FCE7CA;
    color:#F09F34;
    border-radius: 5px;
}

.time {
    font-size: 2rem;
    color: #3c3b37;
    font-weight: 700;
    margin-top: 8px;
    margin-left: 10px;
}

.time1 {
    margin-top: 0px;
    font-weight: 500;
}

.time2 {
    margin-top: 0px;
    font-weight: 500;
}

.ename {
    font-size: 1.5rem;
    font-weight: 600;
    text-align-last: center;
}

.crs-info-head {
    padding-left: 20px;
    color: #333333;
    font-size: 30px;
    padding: 11px 10px 10px 22px;
    font-weight: 500;
    margin-bottom: 10px;
}

.crs-info-det {
    padding: 1px 22px;
}

.crd-prereq {
    overflow-x: hidden;
    overflow-y: scroll;
}

.KsPSm{
    margin:0px!important;
}

.main-banr-crs-info {
    display: grid;
    /* grid-template-columns: calc(100% - 400px) 400px; */
    padding: 10px 0px 10px;
    width:90%;
    margin:auto;
}

.crs-ban-det {
    /* padding: 10px 10px 10px 30px; */
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
}

.crs-banr-crd-c {
    display: flex;
    justify-content: center;
    align-items: center;
}

.crs-ban-rating-i {
    color: #F7BB56;
}

.inst-name-crs-pg {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 24px;
    color: white;
}

.desc-sectin {
    width: 87%;
    margin: 10px auto 50px auto;
}

.desc-gradiant {
    height: 120px;
    background-image: linear-gradient(transparent, white);
    position: absolute;
    /* top: 32px; */
    bottom: 0;
    width: 100%;
    z-index: 2;
}


.certi-info-det
{

    display: grid;
    grid-template-columns: 70% 30%;
    padding:12px;
}

.certi-info-det-i{
    display: grid;
    grid-template-columns: 15% 60% 15%;
    padding:12px;
    align-items: center;
    align-content: center;
    grid-gap:10px;
}

/* @media only screen and (max-width:768px) {
    .main-banr-crs-info {
        display: grid;
        grid-template-columns: 1fr;
    }
} */

.crs-banr-crd-c {
    padding-top: 0px;
}

.crs-price-crd {
    width: 90%;
    margin:auto;
}
.course-align-info-div{
    display: flex !important;
    flex-direction: column-reverse;
}
.title{
    font-size: 1.6rem;
}

.active-internal-card{
    border:2px solid #022E7A;
}

@media only screen and (max-width:359px){
    .image-c-t-cd{
        width:22px;
    }
    .crs-ban-det {
        padding: 10px;
    }
    .course-align-info-div{
        display: flex !important;
        flex-direction: column-reverse;
    }
    .title{
        font-size:0.9rem;
    }
    .main-banr-crs-info{
        padding:0px 0px 0px;
    }
    .crs-ban-text-c-g {
        grid-gap: 0px;
        grid-template-columns: 43px auto;
        margin-bottom: 5px;
    }
    .display-star-icon{
        width: 25%;
    }
    .course-detail-star{
        width:100% !important ;
    }
    .crs-ban-text-c{
        margin-bottom: 2px;
    }
    .course-detail-tower{
        width:17px !important;
    }
    .crs-ban-text-c > div {
        width:38px;
    }
    .course-detail-enimg{
        width: 19px !important;
        padding: 0px !important;
    }
    .crs-ban-text-c-ng{
        grid-template-columns: 25px auto;
        margin-bottom:5px;
    }
    .course-detail-entxt{
        font-size: 13px !important;
    }
    .course-detail-edavatar{
        width: 70px !important;
    }
}

@media only screen and (min-width:320px) and (max-width:767px){
    .image-c-t-cd{
        width: 60px;
        height: 58px;
    }
    .crs-ban-det {
        padding: 10px;
    }
    .course-align-info-div{
        display: flex !important;
        flex-direction: column-reverse;
    }
    .title{
        font-size:1.2rem;
    }
    .main-banr-crs-info{
        padding:0px 0px 0px;
    }
    .crs-ban-text-c-g {
        grid-gap: 0px;
        grid-template-columns: 66px auto;
        margin-bottom: 5px;
    }
    .display-star-icon{
        width: 25%;
    }
    .course-detail-star{
        width:100% !important ;
    }
    .crs-ban-text-c{
        margin-bottom: 2px;
    }
    .course-detail-tower{
        width:17px !important;
    }
    .crs-ban-text-c > div {
        width:38px;
    }
    .course-detail-enimg{
        width: 19px !important;
        padding: 0px !important;
    }
    .crs-ban-text-c-ng{
        grid-template-columns: 25px auto;
        margin-bottom:5px;
    }
    .course-detail-entxt{
        font-size: 13px !important;
    }
    .course-detail-edavatar{
        width: 25px !important;
        height: 25px !important;
    }
}

@media only screen and (min-width:786) and (max-width:1024px){
    .main-banr-crs-info {
        display: grid;
        grid-template-columns: 1fr;
    }

    .image-c-t-cd{
        width:60px;
    }
    .crs-ban-det {
        padding: 10px;
    }
    .course-align-info-div{
        display: flex !important;
        flex-direction: column-reverse;
    }
    .title{
        font-size:1.8rem;
    }
    .main-banr-crs-info{
        padding:0px 0px 0px;
    }
    .crs-ban-text-c-g {
        grid-gap: 0px;
        grid-template-columns: 80px auto;
        margin-bottom: 15px;
    }
    .display-star-icon{
        width: 25%;
    }
    .course-detail-star{
        width:100% !important ;
    }
    .crs-ban-text-c{
        margin-bottom: 15px;
    }
    .course-detail-tower{
        width:17px !important;
    }
    .crs-ban-text-c > div {
        width:38px;
    }
    .course-detail-enimg{
        width: 25px !important;
    }
    .course-detail-entxt{
        margin-left:15px;
    }
    .crs-ban-text-c-ng{
        grid-template-columns: 25px auto;
        margin-bottom:15px;
    }
    .course-detail-edavatar{
        width: 70px !important;
    }

}

@media only screen and (min-width:1024px){
    .title{
        font-size: 2.6rem;
    }
}

/* 
@media only screen and (max-width:468px) {
    .crs-ban-det {
        padding: 10px;
    }
    .title {
        text-align: center;
        font-size: 1.6rem;
    }
    .course-align-info-div{
        display: flex !important;
        flex-direction: column-reverse;
    }
} */

@media only screen and (min-width:769px) {
    .course-align-info-div{
        display: grid !important;
    }
}