.AboutUs{
    padding: 50px;
    position: relative;
}

.alingcenter{
    text-align: center;
}

.heading{
    font-size: 32px;
    font-weight: 600;
    color: #032C6B;
}

.newpara{
    font-size: 18px;
    font-weight: 400;
    color: rgba(3, 44, 107, 0.8);
    margin-bottom: 40px;
}

.postions{
    position: absolute;
}

.bg1{
    top: 10%;
    left: 0;
}

.bg2{
    top: 30%;
    right: 0;
}

.bg3{
    bottom: 0%;
    left: 0;
}

.bg4{
    bottom: 0;
    right: -2%;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {

    .AboutUs{
        padding: 50px 20px;
        position: relative;
    }
}