.queReviewBox {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  margin-bottom: 20px;
}
.queBar {
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}

.queBar > p {
  /* overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2; */
}
.timeingForQ {
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: center;
}
.difficultyBarMarks {
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.marksBox {
  color: #143f6b;
  font-size: 14px;
  font-weight: 600;
  background-color: #fff;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
  padding: 0px 15px;
  /* border-radius: 5px; */
}
.marksBox > h2 {
  margin: 0;
  white-space: nowrap;
  padding: 5px 0;
  font-size: 16px;
}
.correctAns {
  background-color: rgba(76, 187, 23, 0.1);
  color: #4CBB17;
  border: 1px solid #4CBB17;
}
.incorrectAns {
  background-color: rgba(255, 34, 62, 0.1);
  color: #FF223E;
  border: 1px solid #FF223E;
}

.textStyles{
  font-size: 16px;
  font-weight: 500;
  padding: 8px 20px;
  /* background-color: black; */
  color: #fff;
  border-radius: 25px;
  min-width: 60px;
  text-align: center;
}
.easy{
  background-color: #5CB462;
}

.medium{
  background-color: #F9A714;
}

.hard{
  background-color: #E74C4C;
}
.MarksIndicators{
  display: flex;
  align-items: center;
  gap: 5px;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .queReviewBox {
    display: block;
    padding: 10px;
  }
  .queBar {
    width: 100%;
  }
  .difficultyBarMarks{
    width: 100%;
    justify-content: flex-start;
    gap: 20px;
    margin-top: 20px;
  }

}
