.mainContainer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 100;
  margin-bottom: 80px;
  box-sizing: border-box;
}
.firstContainer{
  position: relative;
}
.Bg1{
  position: absolute;
  top: 10%;
  left: 30%;
}
.Bg2{
  position: absolute;
  top: 10%;
  right: 30%;
}
.Bg3{
  position: absolute;
  bottom: 10%;
  left: 10%;
}
.Bg4{
  position: absolute;
  bottom: 10%;
  right: 10%;
}

.logocontainer {
  text-align: center;
  margin-top: 50px;
}

.pageHeading {
  color: #032c6b;
  font-size: 64px;
  font-weight: 600;
  line-height: 1.4;
  text-align: center;
}
.descriptionbox {
  width: 50%;
  margin: 0 auto;
  text-align: center;
}
.mainPara {
  font-size: 22px;
  line-height: 1.4;
  font-weight: 400;
}
.registerNow {
  background: linear-gradient(
    90deg,
    rgba(17, 137, 138, 1) 0%,
    rgba(44, 91, 150, 1) 100%
  );
  font-weight: 700 !important;
  font-size: 20px !important;
  color: #ffffff !important;
  padding: 10px 60px !important;
  border-radius: 10px !important;
  /* font-size: 16px !important;
  font-weight: 700; */
  /* margin: 0 auto; */
}

/* secont section  */

.secondContainer {
  background: rgb(234, 245, 248);
  background: linear-gradient(
    142deg,
    rgba(234, 245, 248, 1) 67%,
    rgba(255, 240, 237, 1) 100%
  );
  padding: 40px 0;
  margin: 40px 0;
}
.sectionHeading {
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  color: #15838c;
}
.textLine {
  background: linear-gradient(180deg, #286394 0%, #15838c 100%);
  color: #fff;
  text-align: center;

  & h3 {
    font-size: 32px;
    font-weight: 700;
    padding: 10px 0;
  }
}

.firstLine {
  width: 400px;
  background-color: #15838c;
  padding: 1px;
  margin: auto;
}

.chooseWrite {
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.8;
  width: 70%;
  margin: 40px auto;
}

.CardListing {
  width: 1400px;
  margin: 0 auto;
}

/* How does it work */
.hdiwSection {
  padding: 0px 0 30px;
}
.headingLine {
  width: 160px;
  margin: 0 auto;
  padding: 1px;
  background-color: #032c6b;
  margin-bottom: 20px;
}

/* What If We Told You */
.toldyou {
  background: rgb(234, 245, 248);
  background: linear-gradient(
    142deg,
    rgba(234, 245, 248, 1) 67%,
    rgba(255, 240, 237, 1) 100%
  );
  padding: 30px 0;
  box-sizing: border-box;
}

.toldyouHeading {
  font-size: 68px;
  font-weight: 700;
  color: #15838c;
  text-align: center;
  position: relative;
}

.toldyouHeading::before {
  content: "";
  position: absolute;
  width: 380px;
  height: 2px;
  background: linear-gradient(180deg, #286394 0%, #15838c 100%);
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, -50%);
  /* right: auto; */
}

.toldyoupara {
  width: 50%;
  margin: 0 auto;
  text-align: center;
  color: #032c6b;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.4;
}

.bottomtext {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  color: #032c6b;
  text-decoration: underline;
}

/* what you will get */

.youwillgetheding {
  font-size: 50px;
  font-weight: 600;
  text-align: center;
  background: linear-gradient(180deg, #286394 0%, #15838c 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.whatyougetContainer {
  text-align: center;
}

/* register today */

.registerBonus {
  background: rgb(234, 245, 248);
  background: linear-gradient(
    142deg,
    rgba(234, 245, 248, 1) 67%,
    rgba(255, 240, 237, 1) 100%
  );

  padding: 40px 20px;
  box-sizing: border-box;
}

.registerHeading {
  font-size: 40px;
  font-weight: 700;
  color: #032c6b;
  text-align: center;
  /* width: 30%; */
  margin: 30px auto;
  & span {
    font-size: 67px;
    font: 700;
    color: #15838c;
  }
}

/* future card */

.futureTodayContainer {
  width: 80%;
  margin: 40px auto;
  /* padding-bottom: 40px; */
  transition: all 0.5s ease;
}

.futureTodayContainer:hover {
  transform: translate3D(0, -1px, 0) scale(1.03);
  transition: all 0.4s ease;
  box-shadow: 13px 13px 20px 0 rgba(94, 143,180,0.25);
}

.innerFutureCard {
  background: linear-gradient(180deg, #286394 0%, #15838c 100%);
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 12px;
}

.topText {
  font-size: 32px;
  color: #fff;
  font-weight: 700;
  margin: 10px 0;
}

.futureBtn {
  display: inline-block;
  padding: 5px 20px;
  background-color: #fff;

  border-radius: 10px;
  color: #15838c;
  font-size: 36px;
  font-weight: 700;
 
  & span {
    color: #00000080;
    font-size: 20px;
    font-weight: 400;
    text-decoration: line-through;
  }
}

.youtext{
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  margin: 10px 0;
}

.stikybtn{
  position: sticky;
  bottom: 0%;
  text-align: center;
  /* background: linear-gradient(180deg, #286394 0%, #15838c 100%); */
  background: linear-gradient(180deg, rgba(40,99,148,0.8) 0%, rgba(21,131,140,0.8) 100%);
  display: flex;
  justify-content: center;
  gap: 30px;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}
.stickytest{
  font-size: 20px;
  color: #fff;
  font-weight: 500;
}

.stickyInnerBtn{
  background-color: #fff !important;
  color: #15838c !important;
  font-weight: 700 !important;
}

.stickyInnerBtn:hover{
  background-color: #fff;
}

@media only screen and (max-width: 1440px) and (min-width: 320px) {
  .CardListing {
    width: 90%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .pageHeading {
    font-size: 22px;
    font-weight: 600;
  }

  .descriptionbox {
    width: 100%;
    margin: 0 auto;
    padding: 10px;
    text-align: center;
    box-sizing: border-box;
    background-color: #fff;
  }
  .mainPara {
    font-size: 14px;
    font-weight: 400;
  }

  /* secont section  */

  .secondContainer {
    background: rgb(234, 245, 248);
    background: linear-gradient(
      142deg,
      rgba(234, 245, 248, 1) 67%,
      rgba(255, 240, 237, 1) 100%
    );
    padding: 40px 0;
    margin: 0;
  }
  .firstLine {
    width: 170px;
    background-color: #15838c;
    padding: 1px;
    margin: auto;
  }

  .sectionHeading {
    font-size: 24px;
  }
  .textLine {
    background: linear-gradient(180deg, #286394 0%, #15838c 100%);
    color: #fff;
    text-align: center;

    & h3 {
      font-size: 11px;
      font-weight: 700;
      padding: 10px 0;
    }
  }
  .chooseWrite {
    font-size: 12px;
  }


/* What If We Told You */
.toldyou {
  background: rgb(234, 245, 248);
  background: linear-gradient(
    142deg,
    rgba(234, 245, 248, 1) 67%,
    rgba(255, 240, 237, 1) 100%
  );
  padding: 30px 20px;
  box-sizing: border-box;
}

.toldyouHeading {
  font-size: 30px;

}

.toldyouHeading::before {
  content: "";
  position: absolute;
  width: 80px;
  height: 2px;
  background: linear-gradient(180deg, #286394 0%, #15838c 100%);
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, -50%);
  /* right: auto; */
}

.toldyoupara {
  width: 100%;
 
  font-size: 16px;
  
}

.bottomtext {

  font-size: 16px;
  
}

  /* what you get  */

  .youwillgetheding {
    font-size: 30px;
  }

  .whatyougetContainer {
    text-align: center;
    width: 100%;
    & img {
      width: 100%;
    }
  }

  /* register today */

  .registerHeading {
    font-size: 24px;
    font-weight: 700;
    color: #032c6b;
    text-align: center;
    width: 100%;
    margin: 30px auto;
    & span {
      font-size: 67px;
      font: 700;
    }
  }
  .topText {
    font-size: 22px;
    color: #fff;
    font-weight: 700;
    margin: 10px 0;
  }
  .Bg1{
    position: absolute;
    top: -7%;
    left: 4%;
  }
  .Bg2{
    position: absolute;
    top: -11%;
    right: 0%;
  }
  .Bg3{
    position: absolute;
    bottom: -20%;
    left: -4%;
  }
  .Bg4{
    position: absolute;
    bottom: -23%;
    right: -1%;
  }
  .stikybtn{
    justify-content: flex-start;
    gap: 10px;
    padding: 20px 10px ;
  }
  .stickytest{
    font-size: 16px;
    color: #fff;
    font-weight: 500;
  }
}
