.btn{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 42px;
    font-size: 1.4em;
    border-radius: 13px;
    padding: 10px 30px;
    font-weight: bold;
    color: aliceblue;
    border: none;
    cursor: pointer;
    white-space: nowrap;
    text-transform: uppercase;
}
/* .btn:last-child{ margin-right: 0px;} */
.btn-prim{
    background-color: #313e6c;
}

.btn-stud{
    background-color:  #032C6BA1;
;
}

@media screen and (min-width: 993px) and (max-width:1440px) {
    .btn {
        font-size: 1em;
        padding: 13px 20px;
        width: 168px;
    }
}
@media only screen and (min-width:768px) and (max-width:992px) {
    .btn-prim, .btn-stud {
        padding: 10px 20px;
        border-radius: 15px;
    }
    .btn-class .btn {
        margin-right: 0px;
        margin-bottom: 10px;
    }
}


@media only screen and  (max-device-width : 768px) {
    .btn{
        font-size: 15px;
        font-weight: 900;
        padding: 6px;
        width: 60%;
    }
    .btn-clss > .btn-prim, .btn-stud{
        padding: 3px 15px;
        margin: 0 auto;
        margin-bottom: 10px;
        border-radius: 10px;
    }
    .btn:last-child {
        margin-bottom: 0px;
        margin-right: auto;
    }


}