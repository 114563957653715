/* ==================================== */
/* Typography Desktop*/
/* ==================================== */
.tittleFont {
  font-weight: 700;
  font-size: 40px;
}
.subTittleFont {
  font-weight: 400;
  font-size: 24px;
}

.takeATestFont {
  font-weight: 700;
  font-size: 16px;
}

.bottom_rightGradient {
  background-image: radial-gradient(
    farthest-side at 104% 91%,
    rgba(37, 103, 147, 0.15),
    white
  );
}

.bottom_rightGradientTop {
  background-image: radial-gradient(
    farthest-side at 104% 7%,
    rgba(37, 103, 147, 0.15),
    white
  );
}

.circle_gradient {
  background-image: radial-gradient(rgba(37, 103, 147, 0.15), white);
}
/* ==================================== */
/* Typography Mobile*/
/* ==================================== */
@media screen and (max-width: 767px) {
  .tittleFont {
    font-weight: 700;
    font-size: 28px;
  }
  .subTittleFont {
    font-weight: 400;
    font-size: 16px;
  }
  .takeATestFont {
    font-weight: 600;
    font-size: 16px;
  }
}

@media screen and (max-width: 767px) {
  .emailresponsive {
    
    top: 76% !important;
    right: -20% !important;
  }
}

/* ==================================== */
.col-md-6 {
  flex: 0 0 auto;
  width: 50%;
}
.maxWidth {
  max-width: 1330px !important;
  margin: 0 auto;
  padding: 0 10px;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.line_he {
  line-height: 1.5;
}
.textCenter {
  text-align: center !important;
}

.videos {
  width: 500px;
}

/* .testimonial_test_new {
  margin: 0 144px 0 20px;
  background-color: white;
  border: 2px solid;
  cursor: pointer;
  padding: 19px;
  padding-bottom: 0px;
  display: flex;
  padding-top: 0px;
  border-radius: 53px;
}
.testimonial_assessment_btn {
  border: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
} */

.bluiesh {
  color: #2f327d;
}

.bluiesh_back {
  background-color: #2f327d;
}

.black {
  color: #252641;
}

.white {
  color: #ffffff;
}

.white_back {
  background-color: #fff;
}

.greyesh {
  color: #032c6bcc;
}

.mx_fnt {
  /* font-size: 47px; */

  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  /* line-height: 60px; */
}
.max_font {
  font-size: 41px;
  color: #032c6b;
}
.m_font {
  font-size: 36px;
}
.max_norm_font {
  font-size: 29px;
}

.min_font {
  font-size: 18px;
}

.micro_font {
  font-size: 14px;
}

.flex_content {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
}

.flex_content_img {
  align-items: center;
}

.btn_struct {
  border-radius: 5px;
  padding: 19px;
  color: #ffffff;
  border: none;
  font-size: 20px;
  font-weight: 800;
  width: 194px;
  cursor: pointer;
}
.btn_struct_news {
  border-radius: 5px;
  padding: 11px;
  color: #14878b;
  background-color: #ffffff;
  border: none;
  font-size: 20px;
  font-weight: 800;
  width: 154px;
  cursor: pointer;
}

.main_width {
  max-width: 1500px;
  margin: 0 auto;
  padding: 0 10px;
  overflow: hidden;
}

.headingConten {
  display: flex;
  padding-top: 150px;
  justify-content: space-evenly;
  padding-bottom: 50px;
}

.head_home {
  /* background: url("../../Assets/HomeRewamp/topBack.png"); */
  background: rgb(17, 138, 138);
  background: linear-gradient(
    270deg,
    rgba(17, 138, 138, 1) 0%,
    rgba(97, 97, 169, 1) 100%
  );
  margin-bottom: 10px;
  background-repeat: no-repeat;
  background-position: 0% 0%;
  background-size: 100% 100%;
}

.box_content {
  width: 100%;
  max-width: 1500px;
  padding: 0 10px;
  margin-inline: auto;
}

.mobile {
  width: auto;
  height: 56%;
}

.benImage {
  background: #5b72ee;
  width: 60px;
  height: 60px;
  padding: 13px 9px 2px;
  margin: -40px auto 51px;
  border-radius: 50%;
}

.benefContent {
  width: 32%;
  padding: 21px;
  /* background: #ffffff; */
  box-shadow: 0px 10px 60px rgb(38 45 118 / 8%);
  border-radius: 20px;
  text-align: center;
  margin: 10px;
  box-sizing: border-box;
}

.improve_main {
  margin-top: 140px;
  /* margin-bottom: 100px; */
  padding-right: 5%;
  padding-left: 5%;
}

.improve_title {
  position: relative;
  margin-bottom: 50px;
  font-size: 30px;
}

.improve_Container {
  width: 43%;
  /* margin: auto; */
}

.typoColor {
  color: rgba(3, 44, 107, 0.8) !important;
  font-size: 1.1rem !important;
}

.typColorBig {
  color: #032c6b !important;
  font-size: 1.9rem !important;
  margin-bottom: 15px !important;
}

.improve_back {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f4b767;
  position: absolute;
  top: -10px;
  left: -15px;
  z-index: -1;
}

.improve_para {
  font-size: 18px;
  margin-bottom: 30px;
  width: 90%;
  line-height: 2;
}

.supportPara {
  font-size: 1.3rem;
  line-height: 2;
  width: 63%;
  margin: 0 auto;
  margin-bottom: 30px;
  font-weight: 450;
}

.support_main {
  padding-top: 70px;
  margin-bottom: 150px;
  padding-right: 10%;
  padding-left: 10%;
  /* background-image: radial-gradient(rgba(37, 103, 147, 0.15), white); */
}
.interest_form {
  padding: 3%;
  position: fixed;
  width: 80vw;
  margin: 0 6vw;
  display: flex;
  align-items: center;
  top: 25%;
  background-color: #fff;
  border-radius: 10px;
  justify-content: space-between;
}

.testimonial_test:before {
  content: " ";
  height: 1px;
  width: 100px;
  background: #525596;
  display: block;
  /* position: absolute; */
  left: 0;
  top: 10px;
}

.testimonial_test {
  padding-left: 130px;
  color: #525596;
}

.testi_head {
  font-size: 40px;
  margin-bottom: 42px;
  font-weight: bold;
}

.btm_marg {
  margin-bottom: 100px;
}
.assessment {
  padding: 21px;
  /* width: 37%; */
  font-size: 20px;
  display: flex;
  background-color: white;
  color: #fff;
  border: 2px solid;
  border-radius: 37px;
  cursor: pointer;
  position: relative;
  align-items: center;
}

.assessment_2 {
  padding: 23px;
  margin-left: 10px;
  margin-right: -3px;
  font-size: 20px;
  display: flex;
  background-color: white;
  color: #fff;
  border: 2px solid;
  border-radius: 37px;
  cursor: pointer;
  position: relative;
  align-items: center;
}

.arrow {
  border-radius: 52%;
  position: absolute;
  border: 1px solid;
  right: -1px;
  top: -1px;
  padding: 17px 18px;
}

.testi_image {
  width: 81%;
  height: 86%;
  border-radius: 17px;
}

.test_caro_right {
  position: relative;
}

.tes_below_content {
  width: 89%;
  margin-left: 5px;
  background-color: #ffffff;
  box-shadow: 2px 2px 19px #dfc6c6;
  display: flex;
  border-radius: 17px;
}

.testi_border {
  padding: 6px;
  background-color: #f67766;
  border-bottom-left-radius: 12px;
  border-top-left-radius: 12px;
  margin-right: 18px;
}

.testimonial_text {
  border-left: 1px solid #5f5f7e;
  padding-left: 20px;
  line-height: 1.5;
  font-size: 19px;
  color: #5f5f7e;
}

.ccr_content {
  padding: 66px 8px 100px;
}

.banner_desktop .image_blue {
  position: absolute;
  width: 100%;
  top: 26%;
  z-index: -1;
}

.banner_desktop .data_book {
  width: 50%;
  display: flex;
  align-items: center;
}

.banner_desktop .book_carrer {
  color: #ffffff;
  font-size: 2em;
  font-weight: 900;
  text-align: right;
  margin-bottom: 50px;
}

.org_width {
  /* padding: 19px 44px; */
}

.careermapping {
  display: flex;
  flex-wrap: nowrap;
  max-width: 1400px;
  padding: 0 20px;
  margin: 10px auto;
  justify-content: center;
}

.w100 {
  max-width: 290px;
}

.banner_mobile {
  display: none;
}

.banner_desktop {
  display: block;
  /* background-color: #2f327d; */
}
.different_image {
  width: 90%;
}
.research_content {
  justify-content: center;
  align-items: center;
}

.research_content img {
  width: 40%;
  height: auto;
  margin: 20px;
}

.explore_btn {
  height: 160px;
  width: 366px;
  padding: 16px 0 16px 32px;
  background-image: linear-gradient(177deg, #3d3b4c, #26252f);
  -webkit-box-shadow: 0 7px 18px 0 rgb(0 0 0 / 20%);
  box-shadow: 0 7px 18px 0 rgb(0 0 0 / 20%);
  border-radius: 10px;
  margin-right: 40px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 1;
  color: white;
}

.btm_none {
  margin-bottom: -40px;
}

/* .video_height {
  height: 500px;
} */

.video_height{
  height: 300px;
}

.t_brd {
  padding: 40px 1px;
  border-radius: 53px;
}
.rev_bx {
  width: 50%;
}

/* How to Join*/

.join_main {
  display: flex;
  flex-wrap: wrap;
}

.basic_dtl {
  width: 28%;
  margin: 10px;
  background: #e3e3e3;
  padding: 25px 11px;
}

.how_to_join {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* background: #2f327d; */
  background-image: url("../../Assets/ViewVideo/LC_back.png");
  background-repeat: no-repeat;
  background-size: 100%;
  font-size: 39px;
  font-weight: bold;
  color: white;
}

.btn_join {
  padding: 10px;
  border-radius: 31px;
  background: none;
  outline: none;
  cursor: pointer;
  border: 1px solid grey;
}
.desc_join {
  line-height: 1.7;
  color: #858585;
}
.icons {
  width: 40px;
}
.light_blue_gradident {
  /* background-color: rgba(17, 138, 138, 0); */
  background: linear-gradient(
    270deg,
    rgba(17, 138, 138, 0.5) 0%,
    rgba(17, 138, 138, 0) 297.6%
  );
}
.btn_blue_gradident {
  /* background-color: rgba(17, 138, 138, 0); */
  background: rgb(17, 137, 138);
  background: linear-gradient(
    270deg,
    rgba(44, 91, 150, 1) 0%,
    rgba(17, 137, 138, 1) 100%
  );
  box-shadow: 0px 4px 14px rgba(23, 127, 141, 0.25);
  padding: 8px 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  text-align: center;
}
.blue_text {
  color: #032c6b;
}
.skillsTitle {
  font-size: 31px;
}
.featuresdiv {
  font-weight: 600;
  font-size: 32px;
}
.whiteColor {
  color: #fff;
}
.cardTitle {
  font-weight: 600;
  font-size: 32px;
  line-height: 50px;
  text-align: center;
  color: rgba(3, 44, 107, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}
.cardSubtitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  margin-top: 50px;
}

.improve_Container_bg {
  background-image: url(../../Assets/brainhap/skills_background.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 50px;
  /* width: 38%; */
}
.button_Container_bg:nth-child(2) {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
.improve_para {
  font-size: 18px;
  margin: 0px auto;
  width: 90%;
  line-height: 2;
}
.benImage {
  background: #5b72ee;
  width: 60px;
  height: 60px;
  padding: 10px;
  margin: auto;
  border-radius: 50%;
}

.img_fluid {
  width: 100%;
  height: auto;
}
.featuresCards {
  margin-bottom: 20px;
  background: #ffffff;
  box-shadow: 6px 10px 41px rgb(20 130 139 / 10%),
    inset -5px -5px 28px rgb(198 225 225 / 25%);
  border-radius: 16px;
  padding: 25px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  height: fit-content;
}
.mbZTitle {
  font-weight: 600;
  font-size: 30px;
  color: #032c6b;
  margin: 10px 0px;
}
.rev_bx {
  width: 40%;
  margin: auto;
}
.mbZ {
  margin: 0px;
  margin-bottom: 30px;
  font-weight: 300;
  font-size: 25px;
  line-height: 30px;
  color: rgba(3, 44, 107, 0.8);
}

.testi_para {
  width: fit-content;
  font-weight: 400;
  font-size: 25px;
  color: rgba(3, 44, 107, 0.8);
}
.testi_para:before {
  display: block;
  content: "\275D";
  font-size: 37px;
  /* position: absolute;
  left: -22px;
  top: -4px; */
  color: #032c6b;
}
.testi_para:after {
  display: block;
  content: "\275E";
  font-size: 37px;
  /* position: absolute;
  right: -14px;
  bottom: 7px; */
  color: #032c6b;
}
.btnContainer {
  margin-top: 6rem;
  display: flex;
  justify-content: center;
}
.featuresSections {
  /* display: flex; */
  align-items: center;
  max-width: 1330px;
}

.featuresSections div:nth-child(even) {
  flex-direction: row-reverse;
}
.featuresCards:nth-child(odd) {
  flex-direction: row;
}
.featuresSectionDiv {
  display: flex;
  align-items: center;
  max-height: 220px;
  justify-content: space-between;
}
.subscribeTitle {
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  text-align: center;
  color: #ffffff;
}
.subscribeSubTitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;

  text-align: center;

  color: #ffffff;
}
.subscribeDiv {
  background: #14878b;
  box-shadow: 0px 4px 23px rgba(20, 135, 139, 0.25);
  border-radius: 20px;
  padding: 40px;
  text-align: center;
  margin: 0;
  text-align: center;
  margin: 0;
  padding: 30px 40px;
  position: relative;
}
.subscribeImg {
  top: 108px;
  left: 5%;
}
.subscribeImg:hover {
  width: 300px;
}

.messageIcon {
  width: 200px;
  height: auto;
  transition: width 2s ease-in-out;
}

.messageIcon:hover {
  width: 250px;
}
.formSections {
  display: flex;
  /* justify-content: space-evenly; */
  align-items: flex-start;
  margin: 20px 0;
  padding: 0 15%;
  /* flex-wrap: wrap; */
  gap: 15px;
}

.textWidth {
  background: #ffffff;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  display: flex;
  /* margin: 10px !important; */
  color: #2a619580;
}

.sliderDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* background: rgba(160, 207, 207, 0.25); */
  /* box-shadow: rgb(0 0 0 / 10%) 0px 4px 6px; */
}
.sliderDivBg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: rgba(160, 207, 207, 0.25);
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 6px;
}
.sliderDivBgChild {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* background: rgba(160, 207, 207, 0.25);
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 6px; */
}

.sliderContent {
  display: flex;
  justify-content: center;
  width: 45%;
  align-items: center;
  padding-left: 90px;
  /* border-right: 1px solid #032c6b; */
}
.joinSubscribeBtn {
  border-radius: 5px;
  padding: 10px;
  color: #14878b;
  border: none;
  font-size: 20px;
  font-weight: 800;
  width: 194px;
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.15),
    inset -3px -3px 9px rgba(0, 0, 0, 0.15),
    inset 3px 3px 11px rgba(215, 215, 215, 0.25);
}
.mx10 {
  margin: auto 10%;
}
.squards {
  padding: 0px 10px 40px;
}
.mainText {
  font-size: 24px;
  margin: 0;
}
.mainText c {
  color: #4d3060;
}
.sliderHr {
  border-right: 1px solid #032c6bcc;
  height: 122px;
  margin-block: 20px;
}

.gradientbutton {
  position: relative;
  overflow: hidden;
  width: auto;
  display: inline-block;
}

.gradientbutton > .bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-image: linear-gradient(90deg, red 45%, yellow 50%, green 55%);
  animation: spin 1s linear infinite;
}

.gradientbutton > .label {
  position: relative;
}

.sliderlogoDiv {
  display: inline-flex;
  align-items: center;
}
.gifImage {
  width: 270px;
}
@media screen and (max-width: 1200px) and (min-width: 992px) {
  .videos {
    width: 350px;
  }
}
@media screen and (max-width: 992px) and (min-width: 768px) {
  .w100 {
    width: 100%;
  }

  .bluiesh {
    color: #2f327d;
    font-weight: 600;
    font-size: 28px;
  }

  .bluiesh_back {
    background-color: #2f327d;
  }

  .black {
    color: #252641;
  }

  .white {
    color: #fff;
  }

  .white_back {
    background-color: #fff;
  }

  .greyesh {
    color: #032c6bcc;
  }

  .min_font {
    font-size: 16px;
    font-weight: 400;
  }

  .mid_font {
    /* font-size: 16px; */
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
  }

  .subtitles_text {
    /* font-size: 16px; */
    font-style: normal;
    font-weight: 400;
    font-size: 24px !important;
    line-height: 36px;
  }

  .micro_font {
    font-size: 12px;
  }

  .flex_content {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
  }

  .btn_struct {
    border-radius: 80px;
    padding: 10px 20px;
    color: #ffffff;
    border: none;
    font-weight: 700;
    cursor: pointer;
    font-size: 16px;
  }

  .headingConten {
    display: flex;
    padding-top: 110px;
    align-items: center;
    flex-wrap: wrap;
  }

  .head_home {
    background: url("../../Assets/HomeRewamp/topBack.png");
    margin-bottom: 10px;
    background-repeat: round;
    background-position: 0% -2%;
  }

  .box_content {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin: 20px auto;
    margin-left: -19px;
  }

  .columnReverse {
    flex-direction: column-reverse !important;
  }

  .mobile {
    max-width: 30%;
    height: auto;
  }

  .benImage {
    background: #5b72ee;
    width: 60px;
    height: 60px;
    padding: 10px;
    margin: -40px auto 51px;
    border-radius: 50%;
  }

  .benefContent {
    width: 100%;
    padding: 12px;
    background: #ffffff;
    box-shadow: 0px 10px 60px rgb(38 45 118 / 8%);
    border-radius: 20px;
    text-align: center;
    margin: 19px 10px;
    box-sizing: border-box;
  }

  .improve_main {
    margin-top: 140px;
    margin-bottom: 100px;
  }

  .improve_title {
    position: relative;
    margin-bottom: 50px;
    font-size: 30px;
  }

  .improve_back {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #f4b767;
    position: absolute;
    top: -10px;
    left: -15px;
    z-index: -1;
  }

  .improve_para {
    font-size: 14px;
    margin-bottom: 30px;
    width: 100%;
    line-height: 2;
  }

  .improve_Container {
    width: 100%;
  }

  .supportPara {
    font-size: 14px;
    line-height: 2;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .support_main {
    margin-bottom: 60px;
  }

  .testimonial_test:before {
    content: " ";
    height: 1px;
    width: 100px;
    background: #525596;
    display: block;
    position: absolute;
    left: 0;
    top: 24px;
  }

  .testimonial_test {
    position: relative;
    padding-left: 130px;
    color: #525596;
  }

  .testi_head {
    font-size: 18px;
    margin-bottom: 22px;
    font-weight: 400;
  }

  .testi_para {
    width: 100%;
    font-size: 12px;
    font-size: 14px;
    width: 100%;
    line-height: 1;
  }

  .tes_below_content {
    position: absolute;
    bottom: 2px;
    left: 74%;
    background-color: #ffffff;
    box-shadow: 1px 1px 6px #dfc6c6;
    display: flex;
    border-radius: 17px;
  }

  .image_blue {
    position: absolute;
    width: 100%;
    z-index: -1;
  }
  .book_carrer {
    font-size: 1em !important;
  }
  


}

@media screen and (max-width: 767px) {
  .gifImage {
    width: 180px;
  }
  .subtitles_text {
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    /* line-height: 26px; */
  }
  .featuresdiv {
    font-size: 40px;
    margin-bottom: 0;
  }
  .cardTitle {
    font-size: 24px;
    font-weight: 600;
  }
  .cardimg {
    width: 80%;
  }
  .skillsTitle {
    font-size: 25px;
  }
  .w100 {
    width: 100%;
  }

  .m_font {
    font-size: 23px;
  }
  .bluiesh {
    color: #2f327d;
  }

  .bluiesh_back {
    background-color: #2f327d;
  }

  .black {
    color: #252641;
  }

  .white {
    color: #fff;
  }

  .white_back {
    background-color: #fff;
  }

  .greyesh {
    color: #032c6bcc;
  }

  .max_font {
    font-size: 40px !important;
    font-weight: 600;
  }
  .mx_fnt {
    font-size: 21px;
  }
  .min_font {
    font-size: 16px;
    font-weight: 400;
  }

  .mid_font {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
  }

  .micro_font {
    font-size: 12px;
  }

  .flex_content {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
  }

  .btn_struct {
    border-radius: 5px;
    padding: 10px 20px;
    color: #ffffff;
    border: none;
    font-weight: 600;
    cursor: pointer;
    font-size: 16px;
    margin-top: 30px;
  }
  .headingConten {
    display: flex;
    padding-top: 150px;
    align-items: center;
    flex-wrap: wrap;
    padding-inline: 9%;
  }

  .subscribeTitle{
    font-size: 24px;
    line-height: 30px;
    text-align: start;
  }

  /* .subscribeTitle{
    text-align: start;

  } */

  .head_home {
    background: url("../../Assets/HomeRewamp/topBack.png");
    margin-bottom: 10px;
    background-repeat: round;
    background-position: 0% -2%;
  }

  .box_content {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    text-align: center;
    /* padding: 0 12px; */
  }

  .columnReverse {
    flex-direction: column-reverse !important;
  }

  .mobile {
    max-width: 30%;
    height: auto;
  }

  .benImage {
    background: #5b72ee;
    width: 60px;
    height: 60px;
    padding: 10px;
    margin: -40px auto 30px;
    border-radius: 50%;
  }

  .benefContent {
    width: 100%;
    padding: 12px;
    background: #ffffff;
    box-shadow: 0px 10px 60px rgb(38 45 118 / 8%);
    border-radius: 20px;
    text-align: center;
    margin: 30px 10px;
    box-sizing: border-box;
  }

  .improve_main {
    margin-top: 140px;
    margin-bottom: 100px;
  }

  .improve_title {
    position: relative;
    margin-bottom: 50px;
    font-size: 30px;
  }

  .improve_back {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #f4b767;
    position: absolute;
    top: -10px;
    left: -15px;
    z-index: -1;
  }

  .improve_para {
    font-size: 14px;
    margin-bottom: 30px;
    width: 100%;
    line-height: 1.6;
  }

  .improve_Container {
    width: 100% !important;
  }
  .btm_marg {
    margin-bottom: 20px;
  }
  .supportPara {
    font-size: 14px;
    line-height: 2;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .alignFlexStart {
    align-items: flex-start;
  }

  .textAtStart {
    text-align: start;
  }

  .support_main {
    margin-bottom: 50px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
  }

  .testimonial_test:before {
    content: " ";
    height: 1px;
    width: 100px;
    background: #525596;
    display: block;
    position: absolute;
    left: 0;
    top: 24px;
  }

  .testimonial_test {
    position: relative;
    padding-left: 130px;
    color: #525596;
  }

  .testi_head {
    font-size: 18px;
    margin-bottom: 22px;
    font-weight: 400;
  }

  .testi_para {
    width: 100%;
    font-size: 12px;
    font-size: 14px;
    /* width: 100%; */
    line-height: 1.5;
  }

  .assessment {
    padding: 0px;
    /* width: 37%; */
    margin: 5px;
    font-size: 16px;
    display: flex;
    background-color: white;
    color: #fff;
    border: 2px solid;
    border-radius: 37px;
    cursor: pointer;
    position: relative;
    align-items: center;
  }

  .different_image {
    width: 65%;
  }
  .arrow {
    border-radius: 52%;
    position: absolute;
    border: 1px solid;
    right: -1px;
    top: -1px;
    padding: 17px 18px;
  }

  .testi_image {
    width: 81%;
    height: 86%;
    border-radius: 17px;
  }

  .test_caro_right {
    position: relative;
  }

  .tes_below_content {
    background-color: #ffffff;
    box-shadow: 1px 1px 6px #dfc6c6;
    display: flex;
    border-radius: 17px;
  }

  .testi_border {
    padding: 6px;
    background-color: #f67766;
    border-bottom-left-radius: 12px;
    border-top-left-radius: 12px;
    margin-right: 18px;
  }

  .testimonial_text {
    border-left: 1px solid #5f5f7e;
    padding-left: 20px;
    line-height: 1.5;
    font-size: 10px;
    color: #5f5f7e;
  }

  .image_blue {
    position: absolute;
    width: 100%;
    top: 26%;
    z-index: -1;
  }

  .data_book {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .book_carrer {
    color: #ffffff;
    font-size: 0.8em;
    font-weight: 700;
    text-align: center;
  }

  .org_width {
    /* padding: 19px 44px; */
  }

  .careermapping {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  }

  .main_width {
    max-width: 100%;
    margin: 0 auto !important;
    /* padding: 77px 0 0px; */
  }

  .banner_mobile {
    position: relative;
    /* background-color: rgb(47, 50, 125); */
    display: block;
    max-width: 100%;
  }

  .banner_desktop {
    display: none;
  }

  .banner_mobile .banner_img {
    max-width: 50%;
  }

  .banner_mobile .banner_img img {
    max-width: 100%;
  }

  .banner_mobile .banner_description {
    position: absolute;
    width: 58%;
    bottom: 0;
    right: 20px;
    top: 0;
  }

  .test_caro_right {
    position: relative;
    width: 100% !important;
  }

  .mt130 {
    margin-top: 130px;
  }

  .pt100 {
    padding-top: 100px;
  }

  .ptbt {
    padding-bottom: 45px;
  }
  .mobile_container {
    margin: auto 9%;
    margin-bottom: 65px;
  }

  .videos {
    width: 300px;
    margin-top: 20px;
  }
  .video_height {
    height: 200px;
  }
  .ccr_content {
    padding: 20px 8px 20px;
  }

  .t_brd {
    padding: 4px 1px;
    border-radius: 33px;
  }
  .rev_bx {
    width: 100%;
  }

  /* How to Join*/

  .join_main {
    flex-direction: column;
  }

  .basic_dtl {
    width: 90%;
    margin: 10px;
    background: #e3e3e3;
    padding: 25px 11px;
  }

  .how_to_join {
    background: #2f327d;
    font-size: 29px;
    height: 100px;
  }

  .btn_join {
    padding: 10px;
    border-radius: 31px;
    background: none;
    outline: none;
    cursor: pointer;
    border: 1px solid grey;
  }
  .desc_join {
    line-height: 1.7;
    color: #858585;
  }
  .icons {
    width: 20px;
  }

  .messageIcon {
    width: 150px;
    height: auto;
    transition: width 2s ease-in-out;
    position: absolute;
    right: -20px;
    bottom: 10px;
    animation: zoom 1s linear infinite;
  }
  .subscribeImg {
    top: -61px;
    left: -20%;
  }
  .sliderDivBgChild {
    flex-direction: column;
  }
  .improve_para {
    /* font-size: 16px; */
  }
  formSections {
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
  }
  .sliderDivBg {
    padding-block: 5%;
    flex-direction: column;
  }
  .sliderDiv {
    flex-direction: column;
    padding: 14px 0;
  }
  .sliderContent {
    width: 100%;
    padding: 0;
    flex-direction: column;
  }
  .sliderHr {
    border-bottom: 1px solid #032c6bcc;
    margin-block: 10px;
    width: 60%;
    border-right: 0;
    height: 0;
  }
  .improve_Container_bg {
    background-image: url(../../Assets/brainhap/blueabstract.png);
    margin-bottom: 50px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 10px;
  }
  .featuresSectionDiv {
    flex-wrap: wrap;
    height: auto;
    margin: auto 0;
    max-height: 100%;
    justify-content: center;
  }
  .cardSubtitle {
    font-size: 16px;
  }
  .btnContainer {
    margin-block: 4.5rem;
  }
  .featuresCards {
    padding: 15px;
  }
  .featuresdiv {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 28px;
  }
  .gifImage {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 28px;
  }
}
/* ======================================= */

.animatedText {
  font-size: 35px;
  /* color: linear-gradient(98.59deg, #032c6b 2.19%, #286394 120.62%); */
  animation-duration: 1000ms;
  animation-name: blink;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  background: -webkit-linear-gradient(98.59deg, #032c6b 2.19%, #286394 120.62%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@keyframes blink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.7;
  }
}

/* =========== */
@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg) scale(5);
  }
  to {
    transform: rotate(360deg) scale(5);
  }
}
@keyframes zoom {
  from {
    transform: width(200px) scale(5);
  }
  to {
    transform: width(300px) scale(5);
  }
}
mobile font size .mobile_title {
  font-weight: 700 !important;
  font-size: 28px !important;
}
.mobile_font_slider {
  font-weight: 700 !important;
  font-size: 22px !important;
}
.mobile_title {
  font-weight: 700 !important;
  font-size: 28px !important;
}
.mobile_title {
  font-weight: 700 !important;
  font-size: 28px !important;
}
.mobile_title {
  font-weight: 700 !important;
  font-size: 28px !important;
}
/* font-weight: 700; font-size: 22px; slider */
/* font-weight: 400; font-size: 18px;  slider Content */
/* font-weight: 600; font-size: 16px; description */
/* font-weight: 400; font-size: 16px;  btn */
/* 28px */
/* 28px */
/* desktop font size */
/* 28px */
/* 28px */
/* 28px */
/* 28px */
