
.teach_main_reg{
    margin: 69px auto;
    width: 91%;
    position: relative;
}
.teach_reg_img{
    width: 100%;
}

.wrapper_container{
    margin: 0 21px;
    padding: 10px 31px;
    width: 35%;
}

.teach_content{
    display: flex;
    justify-content: space-between;
    margin: 0 20px;
    padding: 183px 0px 0px;
}

.bg_teach_img{
    width: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
}

.reg_section{
    background-color: white;
    padding: 47px 70px;
    border-radius: 67px;
}
.head_main_reg{
    text-align: center;
    margin-bottom: 10px;
}
.reg_head{
    font-size: 22px;
    color: #022E7A;
    display: inline;
    border-left: 3px solid #eb8413;
    padding-left: 10px;
    font-weight: 400;
}

.tr_input{
    border: 1px solid #f9f1f1;
    border-radius: 0;
    padding: 10px;
    width: -webkit-fill-available;
    font-size: 18px;
    margin: 10px 0;
    padding-right: 10px;
    box-shadow: 3px 3px 11px #e8e8e8;
}
.tr_input::placeholder{
    color: rgb(92, 89, 89);
    font-weight: 300;
}
.tr_input:last-child{
    padding-right: 0;
}

.text_bx_data{
    display: flex;
}

.states{
    display: flex;
}
.st_child{
    padding-right: 10px;
    width: 49%;
}
.st_child:last-child{
    padding-right: 0;
}

.lbl{
    margin: 10px;
    color: #022E7A;
    font-size: 18px;
    font-weight: 300;
}

.contain_reg{
    margin: 10px 0;
}

.btn_reg_class{
    margin: 20px 30px;
}

.Teach_images{
    position: relative;
}

.heads_teach{
    position: absolute;
    top: 76px;
    font-size: 54px;
    max-width: 405px;
    text-align: right;
    color: #171F4F;
}

.btM{
    display: none;
}
.err_pass{
    margin: 0;
}


@media screen and (max-width:992px) {
    
    .teach_content{
        flex-direction: column-reverse;
    }
    .bg_teach_img {
        top: 188px;
    }
    .wrapper_container{
        width: 100%;
        padding: 0;
        margin: 0;
    }
    .teach_reg_img {
        width: 71%;
        float: right;
    }
    .heads_teach {
        float: left;
        top: 76px;
        font-size: 34px;
        max-width: 200px;
        text-align: left;
    }
    .st_child {
        width: 49%;
    }
    .reg_section {
        border-radius: 0; 
    }
    
    .btI{
        display: block;
    }
}
@media screen and (max-width:992px) {
    .reg_section {
        border-radius: 0; 
        padding: 30px;
    }
    .teach_main_reg {
        margin: 0;
        width: 100%; 
        position: relative;
    }

    .sig_button {
        width: 100%;
        background-color: #eb8413;
        border: none;
        padding: 14px;
        color: white;
        font-size: 20px;
        font-weight: bold;
        border-radius: 15px;
    }
    .btM{
        display: block;
    }
    .btI,.trI,.ht_m{
        display: none;
    }
    .teach_reg_img {
        width: 100%;
        float: right;
    }
}
