.pptContainer{
    /* margin: 10px; */
    box-sizing: border-box;
}

.CourseHeading{
    color: #032C6B;
    font-size: 28px;
    font-weight: 700;
    text-transform: capitalize;
}
.heading{
    font-size: 24px;
    font-weight: 600;
    color: #032C6B;
}
.chapterheading{
    font-size: 18px;
    font-weight: 400;
    color: #000;
    text-transform: capitalize;
    margin-top: 0;
}
.pptsCards{
    display: flex;
    gap: 20px;
}
.slider{
    text-align: center;
}
.pptCard{
    position: relative;
    display: inline-block;
    text-decoration: none;
    /* width: 30%; */
    overflow: hidden;
    border-radius: 10px;
}
.pptCard > img{
    width: 100%;
}
.videoOverlay::after{
    position: absolute;
    background: linear-gradient(180deg, rgba(0,0,0,0.2) 50%, rgba(0,0,0,1) 100%);
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
}

.pptCardText{
    position: absolute;
    bottom: 10px;
    left: 0;
    color: #fff;
    /* background-color: rgba(0, 0, 0, 0.25); */
    display: block;
    width: 100%;
    z-index: 3;
}

.pptCardText > h5{
    margin: 10px 20px;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
}

.pptCardText > p{
    margin: 5px 10px;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    text-align: left;
    padding: 10px;
    box-sizing: border-box;
}
.innerVideoDisplay{
    width:100% !important;
    height: 500px !important;
}
.durestionInfo{
    position: absolute; 
    transform: translate(0, 0);
    top:10px; 
    right:0; 
    background:rgba(0, 0, 0, 0.5);  
    color:#fff; 
    text-align:right;
    margin:0 10px 10px;
    padding:5px 10px; 
    border-radius:5px; 
    box-sizing:border-box;
    z-index: 2;
}
.playBtn{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    /* background: rgba(0, 0, 0, 0.25);
    padding: 15px 17px 10px;
    border-radius: 50px; */
}
.playBtn > img{
    width: 100%;
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
.boxForMobile{
    width: 300px !important;
    
}

.innerVideoDisplay{
    width:100% !important;
    height: 400px !important;
}
}