.mainBox{
    text-align: center;
    width: 160px;
}

.boxTitle{
    font-size: 20px;
}

.imgContainer{
    width: 90px;
    margin: 0 auto;
    & img {
        width: 100%;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {

    .mainBox{
        width: 180px;
        margin: 10px 20px;
    }
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
    .mainBox{
        text-align: center;
        width: 140px;
        margin: 20px 10px;

        
    }

    .imgContainer{
        width: 100px;
        & img{
            width: 100%;
        }
    
    }
    
    .boxTitle{
        font-size: 16px;
    }
}