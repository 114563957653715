/* .m-inner_main{
    padding: 20px;
} */

/* .m_inner_content > p{
    font-size: 19px;
    color: black;
    text-align: center;
} */



/* #numberId{
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 0px;
    font-size: 25px;
    font-weight: 900;
} */
.btn_classModel{
    text-align: center;
    margin: 20px 0;
}
.btn_model{
    width: 73%;
    padding: 5px;
    border-radius: 12px;
    margin: 20px 0;
    font-size: 20px;
    color: #7b7b7b;
    font-weight: 900;
    border: 0;
}

.email_login{
    text-align: center;
}

.referal > p{
    text-align: center;
    color: black;
    margin-top: 18vh;
}

