.mainSection{
    padding: 40px 50px;
}

.MainImageContainer{
    
    text-align: center;
}

.showNameSection{
    background-color: #C4EAF2;
}

.mainshow{
    display: flex;
    justify-content: center;
    gap: 50px;
    align-items: center;
    padding: 20px 20px;
    margin: 40px auto;
}

.textone{
    font-size: 40px;
    font-weight: 700;
    color: #445960;
}

.logoimgcontainer{
    width: 10%;

    & img {
        width: 100%;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
    .MainImageContainer{
        width: 100%;
        text-align: center;

        & img{
            width: 100%;
        }
    }

    .mainshow{
        gap: 30px;
    }
    .textone{
        font-size: 25px;
        white-space: nowrap;
    }

    .logoimgcontainer{
        width: 100px;

        & img {
            width: 100%;
        }
    }

}
@media only screen and (max-width: 767px) and (min-width: 320px) {
    .mainSection{
        padding: 20px;
    }

    .MainImageContainer{
        width: 100%;
        text-align: center;

        & img{
            width: 100%;
        }
    }

    .mainshow{
        gap: 10px;
    }
    .textone{
        font-size: 16px;
    }

    .logoimgcontainer{
        width: 70px;

        & img {
            width: 100%;
        }
    }
}