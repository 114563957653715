.Back_position {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 20;
  padding: 20px 0;
}

.show_data {
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin: 10px auto 0;
  align-items: baseline;
}

.btn_starts_test {
  padding: 10px 20px;
  font-size: 18px;
  background-color: #172e92;
  color: white;
  border: none;
  border-radius: 12px;
  box-shadow: 1.5px 1.5px 3px 0px #767676;
  cursor: pointer;
  white-space: nowrap;
  margin: auto 0;
}

.show_question {
  background: #e5e5e5;
  border-radius: 26px;
  color: #0f1d2b;
  box-shadow: none;
}
.btn_next {
  border: 2px solid #76778d !important;
  box-sizing: border-box;
  border-radius: 27px !important;
  padding: 15px;
  width: 150px;
  font-weight: bold;
  color: black;
  cursor: pointer;
  margin-right: 20px;
  background-color: transparent;
  font-size: 20px;
}
.test_section {
  width: 900px;
  margin: 0 auto;
}
.next_btns {
  border: 2px solid #f48c06 !important;
  box-sizing: border-box;
  color: #f48c06;
}

.btn_starts_test {
  padding: 10px 20px;
  font-size: 18px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  white-space: nowrap;
  /* margin: auto 0; */
}

.submit_btn {
  margin-top: 5px;
  margin-left: 12px;
  background-color: #f48c06;
  box-shadow: 0px 4px 16px #f48c06;
  border-radius: 26px;
  font-size: 25px;
}
.mains_test {
  max-width: 1400px;
  margin: 20px auto 20px;
  background-color: #fff;
}

.test_section {
  width: 900px;
  margin: 0 auto;
}

.btn_answers {
  width: 47%;
  margin-right: 10px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  font-weight: 900;
  font-size: 18px;
  padding: 10px 30px;
  border: none;
  background-color: white;
  border-radius: 12px;
  cursor: pointer;
  box-shadow: 1px 1px 6px grey;
}

.btn_answers:nth-child(2n) {
  margin-right: 0px;
}
.btn_succes {
  background-color: #172e92;
  color: rgb(253 251 251);
}
.btn_succes > .chars {
  background-color: black;
}
.answer_wrapper {
  display: flex;
  flex-wrap: wrap;
}

.chars {
  padding: 7px 12px;
  margin-right: 12px;
  border-radius: 50%;
  background-color: #eaeaea;
}
.quest {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  
  .quest > p {
    font-size: 18px;
    text-align: justify;
    margin: 10px 0;
  }
  
  .quest > p > span {
    color: #262323 !important;
  }
  .show_total_question {
    margin-top: 30px;
    padding: 20px 10px;
    border-bottom: 1px solid rgb(210, 214, 210);
    font-size: 20px;
  }
  .test_box {
    border-radius: 12px;
    padding: 5px 10px;
    margin: 20px;
    display: flex;
    align-items: center;
    border: 2px solid #cbc8c8;
    cursor: pointer;
    height: 65px;
  }

  .btn_green {
    background-color: #3aaf46;
    color: rgb(253 251 251);
  }

  .border {
    border: 2px solid #f48c06 !important;
  }

  .whitespace {
    white-space: nowrap;
    margin-right: 10px;
  }
  .question_display {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .question_display > img {
    display: none;
  }

  .grey {
    background-color: #b1b1b1;
    color: #0f1d2b;
    width: 30%;
    padding: 20px;
    cursor: pointer;
    font-size: 18px;
    border: none;
    margin: 10px;
  }

  .getNextSet {
    width: 23%;
    padding: 20px;
    cursor: pointer;
    font-size: 18px;
    color: white;
    background-color: #172e92;
    border: none;
    margin: 10px;
  }
  .widthHundred {
    width: 100%;
  }
  @media screen and (max-width: 768px) and (min-width: 320px) {
    .test_section {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
      }

      .btn_next {
        width: 100px;
        font-size : 20px;
      }
      .btn_answers {
        width: 92%;
      }
    
      .quest > p {
        font-size: 15px;
      }

      .mob_drawer {
        height: 80vh;
      }
      .getNextSet {
        width: 92%;
      }
  }