.mainBox {
  width: 80%;
  margin: 140px auto 0;
  padding: 30px 50px;
  box-sizing: border-box;
  border-radius: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.boxImgContainer {
  width: 25%;
  margin-top: -120px;
  /* border: 4px solid #ccc; */
  border-radius: 30px;

  & img {
    width: 100%;
    border-radius: 25px;
  }
}

.textContainer {
  width: 60%;
}

.cardHeading {
  font-size: 30px;
  font-weight: 600;
  margin: 20px 0;
}

.cardPara {
  font-size: 25px;
  font-weight: 500;
  line-height: 1.4;
}
@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .mainBox{
    width: 90%;
    padding: 20px;
    /* flex-direction: column; */
  }
  .boxImgContainer {
    width: 40%;
    margin-top: -120px;
  
    & img {
      width: 100%;
    }
  }
  
  .textContainer {
    width: 55%;
  }
  .cardHeading {
    font-size: 25px;
    font-weight: 600;
    margin: 20px 0;
  }
  
  .cardPara {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4;
  }
}
@media only screen and (max-width: 768px) and (min-width: 320px) {
  .mainBox {
    width: 95%;
    margin: 120px auto 0;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 20px;
    flex-direction: column;
  }


.boxImgContainer {
  width: 100%;
  margin-top: -120px;

  & img {
    width: 100%;
  }
}

.textContainer {
  width: 100%;
}

.cardHeading {
  font-size: 20px;
  font-weight: 600;
  margin: 20px 0;
}

.cardPara {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
}

}

@media only screen and (max-width: 1440px){

}