.outercardContainer {
  border: 2px solid #15838c;
  border-radius: 20px;
  padding: 20px 60px;
  text-align: center;
  margin-bottom: 60px;
  /* background: linear-gradient(321.62deg, #14878B 0%, #AAD4D4 35.94%, #AAD4D4 55.21%, #AAD4D4 74.48%, #14878B 100%); */
    position: relative;
  & h3 {
    font-size: 28px;
    font-weight: 700;
  }

  & p {
    font-size: 22px;
    font-weight: 400;
  }
}

.titleunderline {
  width: 20%;
  margin: 0 auto;
  background-color: #15838c;
  padding: 1px;
}

.cardnum{
    position: absolute;
    top: -20px;
    left: 10%;
    background-color: #032C6B;
    color: #fff;
    width: 100px;
    padding: 10px 0;
    border-radius: 50px;
    font-size: 20px;
    font-weight: 600;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .outercardContainer {
    padding: 20px ;
    & h3 {
      font-size: 12px;
    }
  
    & p {
      font-size: 12px;
      font-weight: 400;
    }
  }

  .cardnum{
    position: absolute;
    top: -20px;
    left: 10%;
    background-color: #032C6B;
    color: #fff;
    width: 100px;
    padding: 10px 0;
    border-radius: 50px;
    font-size: 20px;
    font-weight: 600;
}

.cardnum{

  font-size: 10px;
  font-weight: 600;
}
}