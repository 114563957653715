.cardListing{
    display: flex;
    flex-wrap: wrap;
    gap: 60px;
    width: 60%;
    margin: 0 auto;
    justify-content: center;
}


.cardContainer{
    width: 380px;
    border: 1px solid #15838c;
    border-radius: 20px;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
}
.imgContainer{
    margin-top: 20px;
}
.title{
    font-size: 22px;
    font-weight: 600;
    position: relative;
}

.title::before{
    content: "";
    position: absolute;
    bottom: -15px;
    left: 50%;
    width: 130px;
    height: 2px;
    background-color: #15838c;
    transform: translate(-50%,-50%);
}

.para{
    font-size: 22px;
    font-weight: 400;
    line-height: 1.6;
}
.cardnum{
    position: absolute;
    top: 0;
    background-color: #032C6B;
    color: #fff;
    padding: 5px 10px;
    border-radius: 50px;
    transform: translate(-50%,-50%);
    font-size: 20px;
    font-weight: 600;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {

    .cardListing{
        width: 100%;
    }

}