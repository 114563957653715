.ul {
  padding: 0;
}
.outsidelist {
  display: flex;
  justify-content: center;
  gap: 40px;
  align-items: center;
  position: relative;
  padding: 30px;
  box-sizing: border-box;
}

.outsidelist:nth-child(2) {
  flex-direction: row-reverse;
  & .imgContainer {
    width: 50%;
    text-align: left !important;
  }

  .textConatainer {
    text-align: right;
  }
}

.imgContainer {
  width: 50%;
  text-align: right;
}

.textConatainer {
  width: 50%;

  & span {
    background: linear-gradient(97.14deg, #11888a -3.11%, #2a5e95 100%);
    color: #fff;
    font-size: 28px;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 50px;
  }

  & h4 {
    font-size: 24px;
    font-weight: 700;
    color: #15838c;
    margin: 10px 0;
  }

  & p {
    font-size: 22px;
    font-weight: 700;
    margin: 5px 0;
  }
}

.outsidelist::before {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  /* border: 3px solid; */

  background: linear-gradient(180deg, #286394 0%, #15838c 100%);
}

.outsidelist:nth-child(2)::before {
  /* border: 3px solid; */
  background: #e0e0e0;
  /* background: linear-gradient(180deg, #286394 0%, #15838c 100%); */
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .outsidelist {
    padding: 10px 0;
    /* gap: 10px; */
  }

  .outsidelist:nth-child(2) {
    flex-direction: row;
    & .imgContainer {
      width: 30%;
      text-align: right;
    }

    .textConatainer {
      text-align: left;
    }
  }

  .outsidelist::before {
    position: absolute;
    content: "";

    left: -20px;
    width: 2px;
    height: 100%;
    /* border: 3px solid; */

    background: linear-gradient(180deg, #286394 0%, #15838c 100%);
  }

  .imgContainer {
    width: 30%;
    /* text-align: right; */
    & img {
      width: 100%;
    }
  }

  .textConatainer {
    width: 65%;

    & span {
      font-size: 10px;
    }

    & h4 {
      font-size: 14px;
      font-weight: 700;
    }

    & p {
      font-size: 10px;
      font-weight: 400;
    }
  }

  .ul {
    padding-left: 40px;
  }
}
