.educ_emp_Showcase {
  margin: 60px;
  display: flex;
  justify-content: space-between;
  padding: 0px 40px;
  position: relative;
  padding-top: 150px;
  background-image: url("../../Assets/Teachlcaro/Assets/1x/Asset 23.png");
  background-size: cover;
  margin-bottom: 0px;
}

.educ_details {
  font-size: 18px;
}

.educ_title {
  color: #313e6c;
  font-size: 4.1em;
  font-weight: bold;
  margin-bottom: 20px;
  max-width: 400px;
}

.educ_emp {
  width: 50%;
  position: relative;
}
.teacher_showcase {
  margin: 0 auto;
  width: 54%;
}
.teacher_child_showcase {
  width: 100%;
}

.teach_des_one {
  position: absolute;
  top: 0;
  left: 49px;
}
.teach_des_two {
  position: absolute;
  top: 0;
  right: 5%;
  text-align: center;
}
.teach_des_three {
  text-align: center;
  position: absolute;
  top: 320px;
}
.teach_des_four {
  text-align: center;
  position: absolute;
  top: 320px;
  right: 59px;
}

.teach_paras {
  color: #333333;
  font-size: 19px;
  text-align: center;
  max-width: 134px;
}
.teach_top {
  width: 70px;
}
.t_teacher_top {
  width: 70px;
}
.t_online_top {
  width: 60px;
}
.heads {
  padding: 0px 36px;
  margin: 37px 0px 10px;
}

.edu_para {
  padding: 0 36px;
}
.edu_para p {
  font-size: 1em;
  color: #696969;
  max-width: 300px;
  text-align: left;
}

.edu_blocks {
  max-width: 500px;
  margin: 10px 36px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
}

.ebls {
  border: 3px solid #ee9435;
  padding: 5px;
  width: 38%;
  min-height: 35px;
  text-align: center;
  font-size: 1em;
  margin: 24px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ebls:nth-child(2n + 1) {
  margin-left: 0px;
}

.edu_bl_one:hover {
  background-color: #ee9435;
  color: black;
}

.edu_bl_two:hover {
  background-color: #ee9435;
  color: black;
}

.edu_bl_three:hover {
  background-color: #ee9435;
  color: black;
}

.edu_bl_four:hover {
  background-color: #ee9435;
  color: black;
}

.teachBack {
  position: absolute;
  z-index: -1;
  left: 10px;
  top: 0;
}

.tb_img {
  width: 100%;
}

/*  2- Get rolling*/

.gt_rolling {
  overflow: hidden;
  height: 90vh;
}

.gr_content {
  padding: 50px 100px;
}
.gr_content > p {
  font-size: 27px;
  font-weight: 500;
  border-left: 2px solid #ee9435;
  padding-left: 10px;
  color: #022e7a;
  text-align: left;
}

.forms {
  text-align: center;
  margin: 0 40px;
}

.forms_img {
  width: 90%;
}
/*  end of get rolling*/

/*3- New Tech*/

.new_tech {
  background-color: white;
  margin: 0 60px 100px;
  overflow: auto;
}
.new_tech_title {
  padding: 100px 0 45px;
  text-align: center;
}
.new_tech_title > p,
.ntc_partnering > p {
  font-size: 29px;
  font-weight: 700;
  border-left: 2px solid #ee9435;
  display: inline;
  padding-left: 20px;
  color: #315084;
}
.new_tech_container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 74%;
}
.ntc > p {
  font-size: 18px;
  color: #022e7a;
  text-align: left;
  line-height: 25px;
}
.ntc {
  display: flex;
  padding: 28px;
  border-radius: 5px;
  width: 35%;
  margin-bottom: 60px;
  background-color: #fdf3e5;
  align-items: center;
  box-shadow: -1px 4px 7px #cec5c5;
}

.ntc_icon {
  width: 47px;
  margin: 0 24px 0 0;
}

.ntc_partnering {
  text-align: center;
}
.ntc_about {
  display: flex;
  margin: 40px auto;
  width: 60%;
  justify-content: space-around;
}
.ntc_p_icon {
  width: 100px;
  align-self: center;
}
.ntc_p_i_one {
  width: 70px;
}
.ntc_ab_cont {
  display: flex;
  flex-direction: column;
}
.ntc_ab_cont > p {
  margin-top: auto;
  max-width: 140px;
  text-align: center;
  color: #333333;
  padding: 8px;
}

.nac_four {
  padding-top: 20px;
  padding-bottom: 10px;
}

.nac_one,
.nac_four > p {
  margin-bottom: 16px;
}
/* End of New Tech */

/* 4- Features*/

.our_feat_heads {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 30px;
}
.our_feat_heads > h1 {
  color: #315084;
  font-weight: 500;
  border-left: 3px solid #ee9435;
  padding-left: 10px;
}

/*4-1 Cards details*/
.our_feat {
  margin-bottom: 40px;
}
.of_Card {
  background-color: white;
  padding: 30px;
  width: 350px;
  border-radius: 40px;
  margin: 10px;
  border: 2px solid #8a8479;
}
.our_feat_card {
  display: flex;
  justify-content: space-around;
  margin: 0 80px;
}
.crd_head {
  font-size: 25px;
  font-weight: bold;
  margin-right: 10px;
  color: #000000;
}
.Card_title {
  display: flex;
  margin-bottom: 20px;
}

.crd_h_img {
  width: 100px;
}
.crd_head_img {
  margin-top: -40px;
  margin-left: auto;
}
.icon_list {
  width: 30px;
  float: left;
  margin-right: 15px;
}
.lists > ul {
  padding: 0;
}
.ourfeatlist {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ourFeaturedetail {
  padding: 10px;
  overflow: auto;
  color: #333333;
}
/*End of Card details*/
/* End of Features*/

/* 5- Services*/

.service_main {
  margin: 0 4%;
  display: flex;
  position: relative;
}
.serv_img {
  width: 100%;
  z-index: -1;
}

.service_box_one {
  margin: 20px;
  width: 40%;
  position: absolute;
  top: 4%;
  left: 5%;
}

.service_box_two {
  width: 60%;
}
.sbo_head {
  color: white;
  font-size: 48px;
  max-width: 450px;
  margin-bottom: 31px;
  margin-top: 40px;
}
.sbo_para {
  margin-bottom: 18px;
  color: #f1e9e9;
  font-size: 21px;
  font-weight: 500;
  max-width: 500px;
  text-align: left;
}
.sbo_details {
  font-size: 23px;
  color: white;
  margin-bottom: 15px;
  text-align: left;
}
.sbo_button {
  background-color: transparent;
  color: white;
  border: 4px solid white;
  font-size: 25px;
  font-weight: bold;
  border-radius: 25px;
  padding: 10px;
}
.ServTeach {
  position: absolute;
  top: -19px;
  right: -79px;
  width: 58%;
}
.sr_img {
  width: 100%;
}
/* 6- App Available on*/

.devices {
  display: none;
  display: flex;
  background-color: white;
  justify-content: space-around;
  margin: 0 auto;
  width: 91%;
  padding: 70px 0;
}
.app_Available_on {
  display: flex;
  justify-content: space-around;
}
.small_mobile,
.small_desk {
  width: 80%;
}
.desk_top,
.mobile_ios {
  text-align: center;
}
.device_heads {
  margin: 0;
  font-size: 28px;
  font-weight: 400;
  text-align: center;
}
.device_para {
  font-size: 26px;
  color: #7d7d7d;
  font-weight: 400;
  text-align: center;
}
.desktop {
  width: 100%;
}
.device_child {
  /* width: 50%; */
  padding: 50px;
}

.store_apps {
  display: flex;
  justify-content: space-around;
}

.Ap_store,
.Gp_store {
  width: 85%;
}

.str {
  margin-top: 30px;
  text-align: center;
}
/* End of App Available*/

@media screen and (max-width: 1400px) and (min-width: 993px) {
  .educ_title {
    font-size: 3.1em !important;
    margin: 0px;
  }
  .heads {
    padding: 0px 36px;
    margin: 17px 0px 10px;
  }
  .educ_emp_Showcase {
    padding: 0 10px;
    padding-top: 120px;
    margin: 30px;
    margin-bottom: 0;
  }
  .ebls {
    font-size: 16px;
    width: 40%;
    margin: 18px 8px 0;
  }
  .edu_para > p {
    font-size: 1em;
  }
  .teach_top {
    width: 60px;
  }
  .edu_blocks {
    margin: 0px 0 10px 28px;
  }
  .teacher_child_showcase {
    width: 100%;
    padding: 78px 10px 0;
  }
  .educ_emp {
    width: 65%;
  }
  .teach_des_one {
    left: 20%;
  }
  .teach_des_two {
    right: 9%;
  }
  .teach_des_three {
    top: 50%;
  }
  .teach_des_four {
    top: 50%;
    right: 2%;
  }
  .sbo_head {
    font-size: 41px;
    margin-top: 8px;
    margin-bottom: 10px;
  }
  .sbo_para {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .sbo_details {
    font-size: 21px;
    margin-bottom: 10px;
  }
}
/*Table view*/
@media screen and (max-width: 992px) and (min-width: 768px) {
  .educ_emp_Showcase {
    flex-direction: column-reverse;
    background-image: url("../../Assets/Teachlcaro/Assets/1x/Asset mobback.png");
    padding-top: 162px;
    margin: 0;
  }
  .educ_emp {
    width: 100%;
    position: relative;
  }
  .educ_details {
    width: 100%;
    font-size: 15px;
  }

  .educ_title {
    max-width: 100%;
    text-align: center;
  }
  .edu_para > p {
    max-width: 100%;
    text-align: center;
  }
  .edu_blocks {
    text-align: center;
    width: 100%;
    margin: 0;
    max-width: initial;
    justify-content: space-around;
  }
  .ebls {
    float: none;
    width: 35%;
    font-size: 1em;
    margin: 13px;
  }
  .teacher_showcase {
    margin: 0 auto;
    width: 54%;
  }
  .teacher_child_showcase {
    width: 100%;
  }

  .teach_des_one {
    position: absolute;
    top: 0;
    left: 99px;
  }
  .teach_des_two {
    position: absolute;
    top: 0;
    right: 100px;
    text-align: center;
  }
  .teach_des_three {
    text-align: center;
    position: absolute;
    top: 320px;
  }
  .teach_des_four {
    text-align: center;
    position: absolute;
    top: 320px;
    right: 59px;
  }

  .teach_paras {
    color: black;
    font-size: 18px;
    text-align: center;
    max-width: 134px;
  }
  .teach_top {
    width: 60px;
  }

  .new_tech_container {
    width: 88%;
  }
  .ntc {
    padding: 10px;
    width: 40%;
    margin-bottom: 33px;
  }
  .new_tech_title {
    padding: 47px 0 30px;
  }
  .ntc_about {
    margin: 20px auto;
    width: 92%;
  }
  .ntc_p_icon {
    width: 70px;
  }
  .ntc_p_i_one {
    width: 60px;
  }

  .new_tech {
    margin: 0 30px 60px;
  }
  .our_feat_card {
    margin: 0px 18px;
  }

  .of_Card {
    padding: 30px;
    width: 28%;
    border-radius: 35px;
  }

  .crd_head {
    font-size: 17px;
    margin-right: 3px;
  }
  .crd_h_img {
    width: 70px;
    margin-left: 23px;
  }

  .lists > ul > li {
    padding: 5px;
    font-size: 14px;
  }

  .icon_list {
    width: 22px;
    margin-right: 9px;
  }

  .service_main {
    margin: 0;
    display: flex;
    position: relative;
    overflow: hidden;
  }
  .service_box_one {
    margin: 3px;
    width: 50%;
    top: 0;
    left: 30px;
  }

  .sbo_button {
    font-size: 22px;
    width: 50%;
    font-weight: 900;
    border-radius: 10px;
  }
  .sbo_head {
    font-size: 30px;
    max-width: 250px;
    margin-bottom: 4px;
  }
}

@media screen and (max-width: 768px) {
  .educ_emp_Showcase {
    flex-direction: column-reverse;
    background-image: url("../../Assets/Teachlcaro/Assets/1x/Asset mobback.png");
    padding: 0 20px;
    padding-top: 120px;
    margin: 0;
  }
  .educ_emp {
    width: 100%;
    position: relative;
  }

  .teach_des_one {
    top: 0;
    left: 1%;
  }
  .teach_des_two {
    top: 0;
    right: 0;
  }
  .teach_des_three {
    top: 183px;
    left: -18px;
  }
  .teach_des_four {
    top: 183px;
    right: -23px;
  }
  .educ_details {
    width: 100%;
    font-size: 10px;
  }
  .teach_top {
    width: 50px;
  }
  .teacher_showcase {
    margin: 0 auto;
    width: 73%;
  }
  .teach_paras {
    font-size: 12px;
    max-width: 86px;
  }

  .educ_title {
    max-width: 100%;
    text-align: center;
    font-size: 3.1em;
  }
  .heads {
    padding: 0px 36px;
    margin: 0px 0px 10px;
  }
  .edu_para > p {
    max-width: 100%;
    text-align: center;
    font-size: 2.1em;
  }
  .edu_blocks {
    justify-content: space-around;
    text-align: center;
    width: 100%;
    margin: 0;
    max-width: initial;
  }

  .ebls {
    float: none;
    width: 35%;
    text-align: center;
    font-size: 1.3em;
    margin: 10px;
    border-radius: 10px;
  }

  .ebls:nth-child(2n + 1) {
    margin-left: 10px;
  }
  /*Get Rolling*/
  .gr_content {
    padding: 10px 25px;
  }
  .gr_content > p {
    font-size: 15px;
    font-weight: 900;
  }
  /*Adapt new way of teaching*/

  .new_tech {
    margin: 0;
  }
  .new_tech_title {
    padding: 22px 20px 19px;
    text-align: left;
  }
  .new_tech_title > p {
    font-size: 18px;
    font-weight: 900;
  }
  .new_tech_container {
    margin: 0 auto;
    width: 94%;
  }

  .ntc {
    padding: 5px;
    width: 38%;
    margin-bottom: 28px;
  }
  .ntc_icon {
    width: 27px;
    margin: 0 11px 0 0;
  }
  .ntc > p {
    font-size: 10px;
    line-height: 17px;
  }

  .ntc_ab_cont > p {
    padding: 5px;
    font-size: 12px;
  }
  .nac_four {
    padding-top: 0;
    padding-bottom: 0;
  }
  .new_tech_title > p,
  .ntc_partnering > p {
    font-size: 18px;
  }
  .ntc_partnering {
    text-align: left;
    padding: 0 14px 12px;
  }
  .ntc_about {
    display: flex;
    margin: 10px auto;
    width: 100%;
    padding: 0 13px;
    box-sizing: border-box;
  }
  .ntc_p_icon {
    width: 44px;
    align-self: center;
  }
  .ntc_p_i_one {
    width: 34px;
  }

  .our_feat_card {
    margin: 0px 3px;
    position: relative;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }

  .our_feat_heads > h1 {
    color: #315084;
    font-size: 20px;
    font-weight: 500;
    border-left: 3px solid #ee9435;
    padding-left: 10px;
  }

  .our_feat_heads {
    margin-bottom: 40px;
  }

  .gt_rolling {
    overflow: hidden;
    height: auto;
  }
  .crd_head {
    font-size: 11px;
    margin-right: 3px;
  }
  .crd_h_img {
    width: 33px;
    margin-left: 0;
  }
  .Card_title {
    display: flex;
    margin-bottom: 12px;
  }
  .of_Card {
    padding: 14px 13px;
    width: 200px;
    border-radius: 20px;
    margin: 2px 2px 20px;
  }

  .ourFeaturedetail {
    padding: 4px;
    box-sizing: border-box;
  }
  .ourFeaturedetail > p {
    font-size: 11px;
    margin: 4px 0;
  }
  .lists > ul > li {
    padding: 5px;
    font-size: 10px;
  }
  .icon_list {
    width: 14px;
    float: left;
    margin-right: 8px;
  }

  .serv_img {
    display: none;
  }
  .service_main {
    margin: 0;
    background-image: url("../../Assets/Teachlcaro/Assets/1x/Asset 31.png");
    overflow: hidden;
    background-size: cover;
  }
  .service_box_one {
    margin: 30px 10px;
    width: 50%;
    top: 0;
    left: 0;
    position: relative;
  }
  .ServTeach {
    position: absolute;
    top: 10px;
    right: -44px;
    width: 58%;
  }
  .sbo_head {
    font-size: 17px;
    max-width: 260px;
    margin: 2px;
  }
  .sbo_para {
    margin-bottom: 5px;
    font-size: 11px;
    font-weight: 400;
    max-width: 500px;
  }
  .sbo_details {
    font-size: 16px;
    font-weight: 600;
  }
  .sbo_button {
    font-size: 22px;
    width: 50%;
    font-weight: 900;
    border-radius: 10px;
  }
  .crd_head_img {
    margin-top: -20px;
  }

  /* .of_Card_Adv {
    position: absolute;
    top: -15px;
    right: 0;
    left: 0;
    width: 30%;
    margin: 0 auto;
    padding-bottom: 57px;
  } */
  .of_Card_Adv .crd_head {
    font-size: 18px;
  }
  .of_Card_Adv .lists > ul > li > p {
    font-size: 12px;
    padding: 5px;
  }
  .of_Card_Adv > .crd_head_img > .crd_h_img {
    width: 57px;
    margin-left: 18px;
  }

  /*Down Desktop view*/
  .device_child .device_child_second {
    width: 50%;
  }

  .desk_top,
  .mobile_ios {
    text-align: center;
    margin: 0 2px 9px;
  }
  .app_Available_on {
    justify-content: space-around;
  }
  .small_mobile,
  .small_desk {
    width: 53px;
  }
  .device_heads {
    margin: 0;
    font-size: 20px;
  }
  .device_para {
    font-size: 18px;
  }
  .str {
    margin-top: 10px;
    text-align: center;
  }
  .device_child {
    padding: 10px;
  }
  .devices {
    width: 97%;
    padding: 10px 0;
    flex-direction: column-reverse;
  }
  .faq-container {
    margin-bottom: 10px;
  }
}
