.login-container {
   
    height: 100vh;
    position: absolute;
    width: 100%;
    z-index: 10000;
    overflow: hidden;
    top: 0;
    left: 0;
}

.overlay-login-back {
    position: absolute;
    height: 100%;
    width: 100%;
    /* background-color: rgba(0, 0, 0, 0.3);
     */
    
    background-color: red;
     /* filter: blur(100px); */
    z-index: -1;
    top: 0;
    left: 0;
}

.login-card-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.login-card-container>div {
    width: 500px;
    max-height: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 50px 5px 50px 5px;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-1 {
    width: 380px;
    padding: 10px 0px 30px 0px;
}

.crete-account-lbl
{
    text-decoration: none;
    user-select: none;
}

@media only screen and (max-width:768px) {
    .login-card-container {
        width: 98%;
    }

.login-1 {
    width: 380px
}

}

@media only screen and (max-width:510px) {
    .login-card-container>div {
        width: 90%;
    }


.login-1 {
    width: 100%;
}

}