.gallerySection {
  background-color: #f7ecff;
  text-align: center;
  /* margin: 70px 0; */
  padding: 30px 30px 90px;
  position: relative;
}

.sectionHeading {
  font-size: 40px;
  font-weight: 600;
  color: #032c6b;
  margin-top: 20px;
}
.galleryimgContainer {
  width: 80%;
  margin: 0 auto;
  & img {
    width: 100%;
  }
}

.showmobile {
  display: none;
}

.postions{
    position: absolute;
}

.galleryIcon1 {
    top: 10px;
    right: 0;
  }
  .galleryIcon2 {
    left: 0;
    top: 25px;
  }
  @media only screen and (max-width: 767px) and (min-width: 320px) {
  .hidemobile {
    display: none;
  }
  .showmobile {
    display: block;
  }

  .galleryimgContainer {
    width: 95%;
    margin: 0 auto;
    /* padding: 10px; */
    & img {
      width: 100%;
    }
  }

  .galleryIcon1 {
    width: 266px;
    top: auto;
    bottom: -25%;
    right: 0;
    & img {
      width: 100%;
    }
  }
  .galleryIcon2 {
    width: 71px;
    left: 0;
    top: 25px;
    & img {
      width: 100%;
    }
  }
}
