.textContainer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.heading{
    font-size: 22px;
    font-weight: 700;
}

.testheading{
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 400;
}

.viewReport{
    color: #056696;
    font-size: 20px;
    font-weight: 500;
    text-decoration: underline;
    border: none;
    background-color: transparent;
}

.pending{
    font-size: 20px;
    font-weight: 500;
    color: #EF9000;
}