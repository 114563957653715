.cards-box {
  width: 90%;
  box-shadow: 0 2px 8px 0 #0000002e, 0 6px 6px 0 #0000000d;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  margin: 5px 5px 5px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
}
.card-session-book {
  background-repeat: no-repeat;
  background-size: cover;
  /* background-image: url("../../Assets/cardBackground/sessionbook.png"); */
}
.card-session-container {
  background-repeat: no-repeat;
  background-size: cover;
}
.Sub_icon {
  width: 100%;
}
.react-multiple-carousel__arrow--right {
  right: calc(0% + 1px) !important;
  z-index: 1;
}
.react-multiple-carousel__arrow--left {
  left: calc(-0.1% + 1px) !important;
  z-index: 1;
}
.card-img {
  position: relative;
}
.ratings {
  position: absolute;
  bottom: 20px;
  right: 0px;
  display: flex;
  align-items: center;
  background-color: #cce0fb;
  padding: 3px 10px;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
}
.stars {
  width: 20px;
  margin-right: 10px;
}

.box-title {
  display: flex;
  justify-content: space-between;
  padding: 5px 15px;
}
.bx-title {
  font-size: 21px;
  max-width: 240px;
  text-align: left;
  font-weight: bold;
  word-break: break-word;
  margin: 17px 0 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #2f3e73;
}
.institute_avtr {
  width: 40px;
  overflow: hidden;
}
.institute_icon {
  width: 40px;
  border-radius: 50%;
  height: 40px;
}
.inst_avtr {
  width: 100%;
  border-radius: 50%;
}
.bx-amount {
  margin-top: 17px;
  margin-bottom: 0;
}
.bx-amount p:first-child {
  font-size: 24px !important;
  text-align: right;
  font-weight: 900;
  color: #5aa854 !important;
  margin: 0;
}
.rate {
  margin: 5px 0;
}
.bx-amount p:last-child {
  font-size: 18px;
  text-align: right;
  font-weight: 900;
  color: #7b7a7b;
  margin: 0;
}
.enroled {
  display: flex;
  padding: 2px 10px;
  align-items: center;
}
.grow {
  width: 20px;
  margin: 0 10px;
}
.enroled p {
  color: #7b7a7b;
}
.prog {
  display: flex;
  padding: 20px;
  margin-top: auto;
}
.prog img {
  width: 24px;
  margin-right: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .cards-box {
    width: auto;
  }
}

@media only screen and (max-device-width: 768px) {
  .cards-box {
    width: 46%;
    min-width: 45%;
    margin: 5px;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
  }
  .bx-amount p:first-child {
    font-size: 18px !important;
  }
  .bx-amount p:last-child {
    font-size: 14px;
  }
  .bx-title {
    font-size: 17px;
    margin: 16px 0 0;
  }
  .box-title {
    padding: 0 10px;
  }
  /* .institute_icon{
        width: 26px;
    } */
  .react-multiple-carousel__arrow--left {
    display: none;
  }
  .react-multiple-carousel__arrow--right {
    display: none;
  }
}
