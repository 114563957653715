
.submit-form {
    /* background-color: rgba(0, 0, 0, 0.1); */
    background-color: white;
    text-decoration: none;
    border: none;
    width: 100%;
    height: 60px;
    outline: unset;

    cursor: pointer;
    font-size: 26px;
    text-transform: uppercase;
}

.submit-form:hover {
    /* background-color: rgb(148, 98, 7); */
    /* background-color: black; */
    color: white;
}

.submit-form:focus 
{
    /* background-color: black; */
    color: white;
}

@media only screen and (max-height:600px)
{
    .submit-form {
        height: 50px;
        font-size: 1.2em;
    }
    
}