
.Explorebtnsection {
    padding: 50px 0;
    position: relative;
  }
  .ExploreBackground {
    background-color: #82a0d8;
    width: 100%;
    /* height: 200px; */
    
    & img {
      width: 100%;
    }
  }

  .NSDCImgContainer{
    width: 550px;

    & img{
      width: 100%;
    }
  }
  
  .exploreimgTextbtn {
    background-color: #82a0d8;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    margin: auto;
    display: flex;
    width: 70%;
    gap: 20px;
    justify-content: center;
    align-items: center;
  
    & h3 {
      font-size: 34px;
      font-weight: 600;
      color: #ffffff;
    }
  
    & .explorbtn {
      background-color: #11898a;
      color: #ffffff;
      box-shadow: 0 4px 14px 0 rgba(23, 127, 141, 0.25);
      padding: 10px 30px;
      font-size: 18px;
      font-weight: 600;
    }

    & .explorbtn:hover{
      background-color: #11898a;
    }
  }
  .exploremobile {
    display: none;
  }
  
  .iconboxone {
    top: 45%;
    right: 5%;
  }
  .iconboxtwo {
    left: 0;
    bottom: 15%;
    display: none;
  }
  .postions {
    position: absolute;
  }

  .sectionHeading {
    font-size: 32px;
    font-weight: 600;
    color: #032c6b;
    text-align: center;
  }

  @media only screen and (max-width: 1024px) and (min-width: 768px) {
    .exploreimgTextbtn {
      display: flex;
      /* position: absolute; */
      flex-direction: column;
      width: 70%;
      /* top: 50%;
      left: 15%; */
      gap: 10px;
      justify-content: space-around;
      align-items: center;
    
      & h3 {
        font-size:25px;
        font-weight: 600;
        color: #ffffff;
      }

  }
}
  @media only screen and (max-width: 767px) and (min-width: 320px) {
    .ExploreBackground {
      /* height: 150px; */
    }


    .sectionHeading {
      font-size: 24px;
      font-weight: 600;
    }
  .exploreimgTextbtn {
    width: 100%;
    /* top: 55%;
    left: 10px; */
    flex-direction: column;
    gap: 10px;
    justify-content: space-around;

    & h3 {
      font-size: 22px;
      font-weight: 600;
      text-align: center;
      /* width: 150px; */
      line-height: 1.4;
    }
    & div {
      /* width: 150px; */
      /* margin-right: 20px; */

      & img {
        width: 100%;
      }
    }

    & .explorepc {
      display: none;
    }
  }

  .NSDCImgContainer{
    width: 350px;
  }
  .exploremobile {
    display: block;
    text-align: center;
    position: absolute;
    bottom: 25%;
    left: 20%;
    /* margin: 40px 0 0; */

    & .explorbtn {
      background-color: #11898a ;
      color: #ffffff;
      box-shadow: 0 4px 14px 0 rgba(23, 127, 141, 0.25);
      padding: 10px 30px;
      font-size: 16px;
      font-weight: 700;
     
    }
  }

  .iconboxone {
    top: 60%;
    right: 20%;
    width: 45px;
    display: none;
  }
  .iconboxtwo {
    left: -80%;
    top: auto;
    bottom: 20%;
    display: none;
  }
  }