.btn {
  background: linear-gradient(93.31deg, #11898a 0%, #2c5b96 103.77%);
  font-size: 16px;
  font-weight: 700;
  color: #ffffff !important;

  border: none !important;
}
.btnCon {
  position: absolute;
  bottom: 15%;
  left: 10%;
}
.img {
  width: 100%;
  margin: auto;
  display: block;
  & img {
    width: 100%;
  }
}
.imgMobile {
  display: none;
  width: 100%;
  margin: auto;

  & img {
    width: 100%;
  }
}
.img3 {
  width: 50%;
  margin: auto;
  & img {
    width: 100%;
  }
}
.banner {
  position: relative;
}
.research {
  display: flex;
  justify-content: space-evenly;
  background: linear-gradient(180deg, #fbf5ff 95.33%, #e3fffd 109.83%);
  /* padding: 30px 0px; */
  align-items: center;
}
.heading {
  font-size: 50px;
  font-weight: 700;
  color: #032c6b;
  margin: 10px 0;
}
.para {
  font-size: 40px;
  font-weight: 700;
  color: #13868b;
}

.leftCon {
  width: 85%;
  display: flex;
  margin: auto;
  flex-direction: column;
  /* justify-content: space-between; */
  /* height: 80%; */
}
.leftConDid {
  width: 65%;
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: space-between;
  height: 80%;
}
.btnMore {
  background: linear-gradient(93.31deg, #11898a 0%, #2c5b96 103.77%);
  font-size: 16px;
  font-weight: 700;
  color: #ffffff !important;
  border: none !important;
}
.img1 {
  width: 60%;
  margin: auto;
  & img {
    width: 100%;
  }
}
.didCon {
  background: linear-gradient(
    180deg,
    rgba(216, 255, 253, 0.4) 0%,
    #d8fffd 100%
  );
  padding: 40px 0px;
  box-sizing: border-box;
}
.headingDid {
  font-size: 40px;
  font-weight: 700;
  color: #032c6b;
  width: 100%;
  text-align: center;
  margin: 0px;
  padding: 30px;
  box-sizing: border-box;
}
.about {
  display: flex;
  justify-content: space-evenly;
  padding-top: 30px;
  box-sizing: border-box;
}
.leftKnow {
  display: flex;
  flex-direction: column;
  justify-content: spa;
}
.right {
  text-align: center;
  margin: auto;
  width: 50%;
}
.left {
  width: 50%;
}
.left1 {
  width: 50%;
}
.rigth1 {
  width: 50%;
}
.color {
  font-size: 40px;
  font-weight: 700;
  color: #d46715;
}
.color1 {
  font-size: 40px;
  font-weight: 700;
  color: #e2b027;
}
.color2 {
  font-size: 40px;
  font-weight: 700;
  color: #0ec1ae;
}
.paraCon {
  font-size: 16px;
  font-weight: 500;
}
.card {
  border-radius: 15px;
  text-align: center;
  padding: 20px;
  box-shadow: 0px 4px 8px 0px #00000040;
  box-sizing: border-box;
}
.cardCon {
  display: flex;
  width: 90%;
  justify-content: center;
  gap: 50px;
  margin: auto;
  padding: 20px 0px;
  box-sizing: border-box;
}
.challenge {
  background: linear-gradient(
    180deg,
    rgba(247, 236, 255, 0) -6.01%,
    rgba(247, 236, 255, 0.6) 59.19%
  );
  padding: 30px 0px;
  box-sizing: border-box;
}
.chalengeHead {
  font-size: 25px;
  font-weight: 600;
  color: #032c6b;
  width: 100%;
  text-align: center;
  padding: 20px 0px;
  box-sizing: border-box;
  width: 90%;
  margin: auto;
}
.align {
  width: 1005;
  text-align: center;
  padding: 20px 0px;
  box-sizing: border-box;
  width: 90%;
  margin: auto;
}
.cardImg {
  width: 90%;
  margin: auto;
  & img {
    width: 100%;
  }
}
.cardDesc {
  font-size: 20px;
  font-weight: 400;
}
.technology {
  position: relative;
  background: linear-gradient(
    0.06deg,
    rgba(232, 247, 244, 0) 0.05%,
    rgba(232, 247, 244, 0.8) 42.86%
  );
  padding: 30px 0px;
  box-sizing: border-box;
}
.tHead {
  font-size: 40px;
  font-weight: 600;
  color: #032c6b;
  /* width: 90%; */
  text-align: center;
}
.detail {
  padding: 20px 0px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.info {
  width: 35%;
  font-size: 22px;
  font-weight: 400;
  color: #000000;
}

.system {
  position: absolute;
  top: 30px;
  left: 60px;
}
.plus {
  position: absolute;
  bottom: 180px;
  left: 111px;
}
.rocket {
  position: absolute;
  right: 110px;
  top: 120px;
}
.system1 {
  position: absolute;
  right: 50px;
  bottom: 130px;
}
.image {
  & img {
    width: 100%;
  }
}
.soluctionCon {
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
  box-sizing: border-box;
}

.soluctionCon:nth-child(even) {
  flex-direction: row-reverse;
}
.soluctiomImage {
  width: 50%;
  margin: auto;
}
.soluctionInfo {
  width: 50%;
  display: flex;
  align-items: center;
}
.cardImage {
  width: 60%;
  margin: auto;
  & img {
    width: 100%;
  }
}
.cardInfo {
  width: 70%;
  margin: auto;
  box-shadow: 6px 10px 41px 0px #14828b1a, inset -5px -5px 28px 0px #c6e1e140;

  padding: 30px;
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 16px;
}
.soluctionCon {
  width: 100%;
  display: flex;
  /* flex-direction: row-reverse; */

  justify-content: space-between;
}

.soluctionHead {
  font-size: 20px;
  font-weight: 600;
  color: #032c6b;
}
.soluctionPara {
  margin: 0px;
  font-size: 18px;
  font-weight: 400;
  color: #032c6bcc;
}
.soluction {
  padding: 20px 0px;
  box-sizing: border-box;
}
.howCon {
  position: relative;
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  box-shadow: 6px 10px 41px 0px #14828b1a;
  /* Outer shadow */
  /* inset -5px 28px 0px #c6e1e140;  */
  /* Inner shadow */
  border-radius: 16px;
  padding: 20px 0px;
  box-sizing: border-box;
}

.howRight {
  width: 50%;
}
.allCon {
  display: flex;
  flex-direction: column;
  gap: 40px;
  background: linear-gradient(
    180deg,
    rgba(216, 255, 253, 0.1) 0%,
    rgba(216, 255, 253, 0.6) 100%
  );
  padding: 60px 0px;
  box-sizing: border-box;
}

.leftImg {
  width: 150px;
  position: absolute;
  top: 20px;
  left: -50px;
  & img {
    width: 100%;
  }
}
.rightImg {
  width: 150px;
  position: absolute;
  right: -59px;
  bottom: 0px;
  & img {
    width: 100%;
  }
}
.joinus {
  position: relative;
}
.joinPara {
  position: absolute;
}
.refence {
  background-color: #e8f0f7;
  padding: 30px 0px;
  box-sizing: border-box;
  position: relative;
}
.refCon {
  width: 80%;
  margin: auto;
  font-size: 18px;
  font-weight: 400;

  & li,
  a {
    list-style-type: disc;
    color: #275ebe !important;
    padding: 20px 0px;
    box-sizing: border-box;
    word-break: break-all;
  }
}
.refImg {
  width: 150px;

  position: absolute;
  bottom: 10px;
  & img {
    width: 100%;
  }
}
.refImg1 {
  width: 150px;
  position: absolute;
  bottom: 10px;
  right: 0px;
  & img {
    width: 100%;
  }
}
@media screen and (max-width: 768px) and (min-width: 320px) {
  .research {
    flex-direction: column;
  }
  .heading {
    font-size: 22px;
  }
  .left {
    width: 100%;
  }
  .right {
    width: 100%;
  }
  .para {
    font-size: 16px;
  }
  .leftCon {
    width: 90%;
    text-align: center;
  }
  .btnCon {
    bottom: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .btn {
    font-size: 8px;
    /* width: 100%; */
  }
  .about {
    flex-direction: column;
  }
  .left1 {
    width: 100%;
  }
  .rigth1 {
    width: 100%;
  }
  .leftConDid {
    width: 80%;
  }
  .headingDid {
    font-size: 22px;
  }
  .didCon {
    padding: 0px;
  }
  .chalengeHead {
    padding: 0px;
    font-size: 20px;
  }
  .align {
    padding: 0px;
  }
  .cardCon {
    flex-direction: column;
    gap: 20px;
  }
  .tHead {
    font-size: 22px;
  }
  .detail {
    flex-direction: column;
    padding: 0px;
  }
  .image {
    width: 80%;
    margin: auto;
  }
  .info {
    width: 80%;
    font-size: 18px;
  }
  .system1 {
    display: none;
  }
  .plus {
    position: absolute;
    left: -55px;
    bottom: -1px;
  }
  .system {
    position: absolute;
    top: 0px;
    left: -100px;
  }
  .rocket {
    position: absolute;
    right: 0px;
    top: 30px;
  }
  .img {
    display: none;
  }
  .imgMobile {
    display: block;
  }
  .soluctionCon {
    flex-direction: column;
    gap: 20px;
  }
  .soluctiomImage {
    width: 100%;
  }
  .soluctionInfo {
    width: 100%;
  }
  .cardInfo {
    width: 80%;
  }
  .cardImage {
    width: 60%;
  }
  .soluctionCon:nth-child(even) {
    flex-direction: column;
  }
  .soluctionHead {
    text-align: center;
    font-size: 16px;
  }
  .soluctionPara {
    font-size: 14px;
  }
  .howRight {
    width: 80%;
    text-align: center;
  }
  .howCon {
    flex-direction: column;
  }
  .allCon {
    padding: 0px 0px 20px;
  }
  .leftImg {
    top: 0px;
    left: -80px;
    width: 100px;
  }
  .rightImg {
    width: 100px;
    bottom: -35px;
    right: -40px;
  }
  .refCon {
    width: 90%;
    font-size: 15px;
    & li,
    a {
      padding: 10px 0px;
    }
  }
  .refImg {
    width: 80px;
    bottom: -10px;
    left: -15px;
  }
  .refImg1 {
    width: 100px;
    bottom: 0px;
    right: 0px;
    top: -3px;
  }
  .refence {
    padding: 0px;
  }
}
@media only screen and (max-width: 1140px) and (min-width: 768px) {

  .about{
    flex-direction: column;
  }
  .rigth1{
    width: 100%;
    
  }
  .left1{
    width: 100%;
  }
}
@media only screen and (max-width: 1400px) and (min-width: 768px) {
.cardCon{
  flex-wrap: wrap;
 
}
.img1{
  width: 100%;
}
/* .cardDesc{
  width: 50%;
} */
}