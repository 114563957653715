/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&display=swap"); */

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
  /* font-family: Montserrat; */
  font-family: "Poppins", sans-serif;
}

/* #otpless-floating-button{
} */


.MuiDialog-paperWidthSm {
  max-width: max-content !important;
  min-width: 600px !important;
}


.font-blue{
  color: blue;
  font-size: 18px;
}

@media screen and (max-width: 768px) and (min-width: 320px) {
  .MuiDialog-paperWidthSm {
    min-width: 300px !important;
    /* overflow: auto; */
  }
}