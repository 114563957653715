.mainBox{
    margin-top: 100px;
}

.mainData{
    width: 472px;
    margin: 0px auto 30px;
    border-radius: 9px;
}

.session_title{
    font-size: 25px;
    text-align: center;
    margin-block: 30px;
}

.centerBx{
    box-shadow: 0px 0px 11px #cfc2c2;
    padding: 25px;
}