.concept-lbl-div
{
    display: flex;
    align-items: center;
    color:#3c3b37;
}

.exp-icn-con-con
{
    height: 25px;
    width: 25px;
    background-color: #fbfbfb;
    display: flex;
    align-items: center;
    justify-content: center;
    margin:6px;
    border-radius: 50%;
    cursor: pointer;
}