.mainForm{
    width: 600px;
    /* min-height: 400px; */
    /* overflow: auto; */
    padding: 20px 0;
}
.phoneNumber{
    display: flex;
    gap: 20px;
   
}

.heropopup{
    min-height: 400px;
    overflow: auto;
    padding: 20px 0;
    box-sizing: border-box;
}

.fromHeading{
    margin: 10px 0;
    text-align: center;
}
.inputFieldContainer{
    width: 100%;
    padding:10px  20px ;
    box-sizing: border-box;
}

.textWidth{
    width: 100%;
}

.btn{
    background-color:#032C6B !important;
    color: #fff !important;
}
.btn:hover{
    background-color:#032C6B !important;
    color: #fff !important;
}

.Submit{
    background: linear-gradient( 90deg, rgba(17, 137, 138, 1) 0%, rgba(44, 91, 150, 1) 100% );
    color: #ffffff !important;
    width: 100% !important;
    margin-bottom: 40px !important;
}
.inp_ten{
    width: 150px;
}
@media screen and (max-width: 768px) and (min-width: 320px) {
    .mainForm{
        width: 100%;
    }
    .inp_ten{
        width: 75px;
    }
  }