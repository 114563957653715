.cardContainer{
    width: 25%;

    & h4{
        color: #032C6B;
        font-size: 24px;
        font-weight: 600;
        margin: 10px 0;
    }

    & p {
        font-size: 18px;
        font-weight: 400;
        margin: 10px 0;
        line-height: 1.4;
    }
}

.imgContainer{
    width: 100%;

    & img{
        width: 100%;
    }
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
    .cardContainer{
        width: 100%;
    }
}
