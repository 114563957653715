.explore-container {
  background-color: white;
  padding: 94px 6% 6%;
}
.explore-header {
  color: #133268;
  font-size: 28px;
  font-weight: bold;
  padding: 0 12px;
  border-left: 5px solid #eb8413;
}
.filter-sidebar {
  flex: 2;
  margin-right: 30px;
  padding-bottom: 20px;
  height: max-content;
  /* margin: 20px; */
  /* height: 700px; */
  border-radius: 12px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.filter-container {
  flex: 5;
  /* box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 12px; */
}
.filter-banner {
  background-color: #eb8413;
  height: 12vh;
  border-radius: 16px;
  margin-bottom: 20px;
  /* box-shadow: 0 3px 10px rgb(0 0 0 / 0.2); */
}
.filter-tags {
  display: flex;
  overflow-x: auto;
  width: 100%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}
.filter-tags::-webkit-scrollbar {
  display: none;
}
.filter-chip {
  border-radius: 20px;
  margin-right: 15px;
  padding: 5px 22px;
  font-weight: bold;
  background-color: #dddddd;
  color: #777777;
  cursor: pointer;
}
.activ {
  color: #ffffff;
  background-color: #eb8413;
}
.clear {
  padding: 15px;
  text-align: right;
}
.autocomplete-input {
  background-color: #fff;
  margin: 0 20px;
}
.MuiOutlinedInput-root,
.css-yk16xz-control {
  border-radius: 10px !important;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;
}
.css-1okebmr-indicatorSeparator {
  background-color: #fff !important;
}
.price-label {
  font-size: 18px;
  margin-left: 20px;
}
.filter-list {
  margin: 30px 10px 0;
}
.filter-sublist {
  max-height: 30vh;
  overflow: auto;
}
.filter-type {
  font-size: 18px;
  margin: 20px 10px 5px;
  letter-spacing: 0.1rem;
  color: #313e6c;
}
.filters {
  /* margin-top: 10px; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.course-container {
  margin: 20px 0;
  border-radius: 18px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  /* border-bottom: 3px solid #dddddd; */
}
.course {
  display: flex;
  padding: 30px 30px 15px;
}
.img-container {
  text-align: center;
  margin-right: 20px;
}
.course-img {
  height: 80px;
  width: 100px;
  object-fit: contain;
  border-radius: 5px;
}
.iconContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.session-icon {
  height: 40px;
}
.course-icon {
  height: 32px;
  object-fit: contain;
}
.course-name {
  margin: 0;
  font-size: 22px;
  letter-spacing: 0.07rem;
  text-transform: capitalize;
  cursor: pointer;
  color: #222222;
}
.course-content {
  max-lines: 2;
  max-height: 74px;
  overflow: hidden;
}
.course-content img {
  object-fit: contain;
}
.course-feature {
  max-lines: 12;
  cursor: pointer;
  /* max-height: 118px;
  overflow: hidden; */
}
.compare {
  background-color: #133268;
  color: #ffffff;
  padding: 4px 10px;
  width: max-content;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}
.discount-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex: 3;
  flex-direction: column;
  cursor: pointer;
}
.discounted {
  background-color: #eb8413;
  color: #ffffff;
  padding: 4px 16px;
  display: inline-flex;
  /* width: max-content; */
  border-radius: 12px;
  font-size: 13px;
}
.price {
  font-size: 24px;
  /* letter-spacing: 0.05em; */
  margin: 10px 0;
  color: #333333;
}
.mrp {
  color: #445544;
  text-decoration: line-through;
}
.view-details {
  color: #133268;
  font-size: 15px;
  cursor: pointer;
  display: inline-flex;
}
.details-list {
  display: flex;
  align-items: center;
}
.filter-mobile {
  display: none;
}
.plain-link {
  text-decoration: none;
}

@media only screen and (max-device-width: 768px) {
  .explore-container {
    padding-left: 4%;
    padding-right: 4%;
  }
  .explore-header {
    margin-top: 10px;
  }
  .filter-sidebar {
    display: none;
  }
  .filter-container {
    width: 100%;
  }
  .course-name {
    font-size: 15px;
  }
  .filter-chip {
    padding: 4px 10px;
    font-size: 14px;
    min-width: max-content;
  }
  .course {
    padding: 15px;
    overflow: hidden;
  }
  .filter-mobile {
    display: inline;
  }
  .course-content {
    font-size: 13px !important;
    min-height: 40px;
  }
  .add-to {
    display: none;
  }
  .img-container {
    margin-right: 10px;
  }
  .rating-star {
    font-size: 1.1rem !important;
  }
  .course-img {
    height: 60px;
    width: 70px;
  }
  .iconContainer {
    margin-top: 6px;
    flex-direction: column;
  }
  .session-icon {
    height: 30px;
    margin: 3px;
  }
  .course-icon {
    height: 24px;
  }
  .price {
    font-size: 18px;
  }
  .discounted {
    font-size: 11px;
  }
  .view-details,
  .mrp {
    font-size: 13px;
    display: flex;
    align-items: center;
  }
}
