.cardContainer{
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0px 4px 20px 0px #00000026;
    border-radius: 10px;
    /* position: relative; */
}
.imgContainer{
    position: relative;
    width: 100%;

    & img{
        width: 100%;
    }
}
.cardName{
    background: linear-gradient(90deg, #12888B 0.81%, #12888B 100%);
    color: #fff;
    position: absolute;
    bottom: -15px;
    right: 10px;
    margin: 0;
    padding: 10px ;
    border-radius: 25px;
    font-size: 20px;
    font-weight: 500;
    width: 150px;
    text-align: center;
}

.desc{
    font-size: 18px;
    font-weight: 400;
    color: black;
    margin: 30px 0;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
    .cardName{
        font-size: 18px;
    }

    .desc{
        font-size: 16px;
    }
}