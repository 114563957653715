.introductionSection{
    padding: 50px;
}
.intoPara{
    font-size: 16px;
    font-weight: 400;
    color: rgba(3, 44, 107, 0.8);
}

.processSection{
    padding: 50px;
    /* text-align: center; */
}

.imgContainer{
    /* text-align: center; */
    width: 65%;
    margin: 0 auto;

    & img{
        width: 100%;
    }
}


.outcomeSection{
    padding: 50px;

}

.boder{
    border-bottom: 1px solid #000;
    width: 90%;
    margin: 50px auto;

}

.showListcontainer{
    display: flex;
    gap: 100px;
    justify-content: center;
}


@media only screen and (max-width: 767px) and (min-width: 320px) {

    .introductionSection , .processSection, .outcomeSection{
        padding: 10px;
    }

    .imgContainer{
        /* text-align: center; */
        width: 100%;
        margin: 0 auto;
    
        & img{
            width: 100%;
        }
    }

    .showListcontainer{
        overflow: scroll;
        gap: 10px;
    }
}