.course-cards-c-c {
  display: flex;
  margin-top: 40px;
  justify-content: space-around;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
}

.overflow-course-title-content {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  position: relative;
}
.course-p-compare-b {
  width: 100px;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin: auto;
  border-radius: 5px;
  cursor: pointer;
  color: white;
}

.wrap-course-compare-item {
  border-bottom: 1px solid #d3d3d3;
  min-height: 100px;
}

.course-cards-sc {
  width: 50%;
  padding-bottom: 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.course-cards-sc p {
  padding: 0px 10px;
}

.inner-c-c-d {
  padding: 10px 12px;
}

.inner-image-c-co {
  height: 112px;
  width: 112px;
  text-align: center;
  margin: auto;
  overflow: hidden;
}

.course-compare-table {
  border: 1px solid #d3d3d3;
}

.homepage-s-l-c {
  background-color: white;
  padding: 94px 6% 6%;
}

.page-header-01 {
  padding: 0 12px;
  border-left: 5px solid #eb8413;
}

.home-c-t-01 {
  color: #133268;
  font-size: 28px;
  font-weight: bold;
  margin: 0 0 -10px;
}
