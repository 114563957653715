.pover_header{
    background-color: #eb8413;
    display: flex;
    justify-content: space-around;
    padding: 10px;
    color: white;
    font-weight: 900;
}

.pp_head{
    background-color: #FCDCAC;
    color: #A26E1F;
    text-align: center;
    width: 170px;
    margin: 10px auto;
    font-size: 18px;
    border-radius: 5px;
    box-shadow: 2px 2px 5px #c9bbbb;
    padding: 4px;
}

.pop_list{
    display: flex;
    justify-content: space-around;
}

.pp_lst > ul{
    padding: 0 0 0 27px;
}
.pp_lst > ul >li{
    cursor: pointer;
    padding: 5px 0;
    font-size: 15px;
    max-width: 200px;
    text-align: left;
    color: #172B69;
}
.line-hor{
    display: none;
}

@media screen and (max-width: 767px) and (min-width: 320px) {
    .pop_list{
        flex-wrap: wrap;
    }
    .pp_lst{
        width: 45%;
        margin: 5px;
    }

    .pp_head{
        width: 80%;
        font-size: 17px;
    }

    .pp_lst > ul >li{
        cursor: pointer;
        padding: 5px 0;
        font-size: 16px;
    }
    .line-hor{
        display: block;
        width: 70px;
        margin: 10px auto;
        border: 2px solid #172B69;
    }
}

