.test_container{
    width: 680px;
    left: 0;
    position: absolute;
    right: 0;
    top: 23vh;
    margin: 0 auto;
    align-items: center;
}

.result{
    color: orange;
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 10px;
}
.test_question{
    max-width: 570px;
    font-size: 33px;
    margin: 0 0 11px;
    color: #f9c47c;
}

.MuiTypography-body1{
    font-size: 1rem !important;
    font-weight: 900 !important;
    /* color: #303a7d; */
}

.clas_answer{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.btn_success{
    background-color: rgb(73, 175, 73) !important;
}

.green_block_container{
    background-color: #e0e0e0;
    padding: 5px 0;
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.green_block{
    width: 100%;
    border: 2px solid #6fb36f;
    padding: 15px 0;
    background-color: #6fb36f;
    box-sizing: border-box;
    margin-top: 20px;
}