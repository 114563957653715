.wwyl-head
{
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    color:#8f8f8f

}   

.qEErI{
    background-color: #fbfbfb;
    font-size: 19px;
    font-weight: 600;
    height: 55px;
    cursor: pointer;
    display: grid;
    grid-template-columns:  calc(100% - 50px) 50px !important;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.jQRjmU{
    margin:20px 0px;
}

.daJvAX{
    padding:0px 20px 0px 10px!important;
}

.course-combo-card-title{
    display: grid;
    grid-template-columns: calc(70%) 30%;
    width: 90%;
    gap: 20px 20px;
    margin: auto;
}