.exp_main_box{
    display: flex;
    justify-content: space-between;
    width: 91%;
    margin: 0 auto;
    z-index: 1;
}

.btn_explore{
    font-size: 19px !important;
    padding: 25px 5px !important;
    font-weight: bolder !important;
    width: 165px;
}