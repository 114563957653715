.buttoncontainer{
    margin: 20px 0;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    gap: 20px;
}
.freeTest{
    background: linear-gradient(
        97.14deg,
        #11888a -3.11%,
        #2a5e95 100%
      );
      text-decoration: none;
      color: #FFF;
      padding: 5px 15px;
      border-radius: 5px;
      font-size: 20px;
}

.allTest{
    background: linear-gradient(
        97.14deg,
        #11888a -3.11%,
        #2a5e95 100%
      );
      text-decoration: none;
      color: #FFF;
      padding: 5px 15px;
      border-radius: 5px;
      font-size: 20px;
      border: none;
}