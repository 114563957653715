.cardListing{
    width: 80%;
    margin: 40px auto;
    display: flex;
    /* justify-content: space-around; */
    gap: 30px;
    overflow: auto;
}

.cardContainer{
    width: 500px;
    border: 1px solid #15838c;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 14px;
    box-shadow: 0px 4px 4px 0px #00000040;

}

.name{
    font-size: 16px;
    font-weight: 700;
    margin: 10px 0;
}

.class{
    font-size: 16px;
    font-weight: 700;
    color: #000000B2;
    margin: 10px 0;
}
.para{
    font-size: 16px;
    font-weight: 400;
    margin: 10px 0;
}
@media only screen and (max-width: 767px) and (min-width: 320px) {

    .cardListing{
        flex-direction: column;
        gap: 40px;
    }

    .cardContainer{
        width: 100%;    
    }
}