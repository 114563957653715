.signin_content {
  display: flex;
}
.signin_input_box {
  margin: 7px 0;
  text-align: center;
}

.stud_dialogs {
  background-image: url("../../Assets/1x/asset/asset10.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.sign_cont_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10px;
  margin: 98px 10px;
  width: 49%;
  border-radius: 7px;
  padding: 16px;
  box-sizing: border-box;
  box-shadow: 0px 3px 21px grey;
}
.sing_val {
  display: flex;
  align-items: center;
}

.stud_dialogue {
  width: 45%;
  padding: 10px;
  display: flex;
  align-items: center;
  margin: -1px 0px 0px;
}

.signin_input {
  border-radius: 8px;
  outline: none;
  background-color: white;
  border: 1px solid rgb(3, 44, 107);
  padding: 8px 13px;
  box-sizing: border-box;
  font-size: 19px;
  width: 82%;
  margin: 0 auto;
  display: flex;
}

.signin_input_box:nth-child(1) .signin_input {
  padding: 0;
  display: flex;
  overflow: hidden;
}

.inputs {
  border: none;
  padding: 0px 2px;
  font-size: 20px;
  width: 100%;
}

#city {
  padding: 0;
  padding-top: 2px;
  padding-left: 2px;
  margin: 0;
  border: 0;
  border-radius: 0px;
  width: 25%;
  font-size: 20px;
  color: #ee9e80;
  opacity: 1;
}

.inputs::placeholder,
.signin_input::placeholder {
  color: #bdbaba;
}
.forgot_pass {
  text-align: center;
}

.cross {
  position: absolute;
  right: 15px;
  top: 10px;
  cursor: pointer;
  color:rgb(3, 44, 107);
}

@media screen and (max-width: 768px) and (min-width: 320px) {
  .signin_input {
    margin-bottom: 5px;
    padding: 7px 8px;
    font-size: 17px;
  }
  .signin_content {
    flex-direction: column-reverse;
  }
  .sign_cont_box {
    margin: 5px auto;
    width: 100vw;
  }
  .stud_dialogs {
    background-image: url("../../Assets/1x/asset/asset11.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
  }
  .cross {
    display: none;
  }
}
