.boxForMobile{
    width: 500px !important;
}

.linkText{
    width: max-content;
    white-space: nowrap;
    margin: 5px;
}
.dropList{
    color: #fff !important;
}
.dropList:hover{
    background-color: rgba(0, 0, 0, 0.4) !important;
}
@media only screen and (max-width: 1024px) and (min-width: 768px) {
    .boxForMobile{
        width: 500px !important;
    }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
    .boxForMobile{
        width: 270px !important;
    }
}