.borderContainer {
  /* margin: 0 auto; */
  width: 100%;
  padding: 4px;
  background: rgb(20, 135, 139);
  background: linear-gradient(
    120deg,
    rgba(20, 135, 139, 1) 0%,
    rgba(170, 212, 212, 1) 50%,
    rgba(20, 135, 139, 1) 100%
  );
  box-sizing: border-box;
}
.fromContainer {
  background-color: #fff;
  padding: 40px;
  & .sectionHeading {
    margin: 0;
  }
}
.bgacolor {
  background-color: rgba(217, 217, 217, 0.15);
}
.textfieldContainer {
  padding: 2px 40px;

  & .fullwidth {
    margin: 10px 0;
    width: 100%;
  }
}

.gradeContainer {
  display: flex;
  flex-wrap: wrap;
  /* row-gap: 20px; */
  column-gap: 25px;
  width: 60%;
  margin: 0 auto;
}

.minBox {
  border: 1px solid #2b5f96;
  border-radius: 5px;
  width: 50px;
  box-sizing: border-box;
  padding: 8px 0;
  cursor: pointer;
}

.textbtn {
  border: 1px solid #2b5f96;
  width: 70%;
  margin: 20px auto;
  padding: 10px 0;
  border-radius: 5px;
}
.fromsubmitbtn {
  background-color: #11898a !important;
  color: #ffffff !important;
  box-shadow: 0 4px 14px 0 rgba(23, 127, 141, 0.25) !important;
  padding: 10px 30px !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  margin-top: 40px !important;
  width: 100% !important;
}

.formsubmited {
    /* font-size: 80px;
    background-color: #5cb85c;
    border-radius: 100px;
    color: #fff;
    padding: 30px; */
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }

  .sectionHeading {
    font-size: 40px;
    font-weight: 600;
    color: #032c6b;
    margin-top: 20px;
  }

  .serviceInfopara {
    font-size: 18px;
    font-weight: 400;
    color: rgba(3, 44, 107, 0.8);
  }

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  /* .borderContainer {
    width: 70%;
  } */

  .sectionHeading {
    font-size: 30px;
   
  }

  .serviceInfopara {
    font-size: 18px;
  }
}

@media only screen and (max-width: 768px) and (min-width: 320px) {
  .borderContainer {
    width: 100%;
  }
  .gradeContainer {
    width: 50%;
    column-gap: 25px;
  }
  .fromContainer {
    padding: 20px;
  }
  .textfieldContainer {
    padding: 10px;
  }

  .sectionHeading {
    font-size: 25px;
   
  }

  .serviceInfopara {
    font-size: 16px;
  }
}
