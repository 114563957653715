.imgas {
  width: 128px;
}

.pdfBoxListing {
  display: flex;
  /* padding: 0 40px; */
  /* gap: 20px; */
  /* width: calc(100% /2  - 50px); */
  justify-content: space-between;
}

.boxComponent {
  /* width: 45%; */
  width: calc(100% /2  - 30px);
 
  /* padding: 20px; */
  /* height: max-content; */
  border-radius: 40px;
  /* background-color: #407bff; */
  overflow: hidden;
  box-shadow: 9px 31px 71.0999984741211px 0px #77B5B526;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */

}

.gradianOne {
  background: linear-gradient(
    0deg,
    rgba(254, 180, 1, 1) 0%,
    rgba(255, 215, 29, 1) 100%
  );
}

.gradianTwo {
  background: linear-gradient(
    0deg,
    rgba(0, 127, 199, 1) 0%,
    rgba(0, 194, 235, 1) 100%
  );
}

.boxHeading {
  margin: 20px 0px;
  background-color: #fff;
  padding: 20px 0;
  text-align: center;
  border-radius: 20px;
  font-size: 30px;
  font-weight: 600;
}

.outerList {
  /* background-color: rgba(0, 0, 0, 0.11); */
  /* margin: 10px; */
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: 600;

  & li{
    font-size: 18px;
    font-weight: 600;
  }
}



.outerList li div {
  padding-top: 10px;
}

.innerHeading {
  font-size: 18px;
  font-weight: 400;
  margin: 5px 0 0;
}

.innerList {
  list-style: none;
  /* position: relative; */
  padding: 0px;
}

.innerList {
  /* position: absolute;
  content: "-";
  top: 0;
  left: -10px; */
  padding-bottom: 10px;

  & li{
    /* position: relative; */
    list-style: disc;
    margin-left: 20px;
    padding-bottom: 5px;
  }

  & li::marker{

  }

  /* & li::before {
  position: absolute;
  content: "-";
  top: 0;
  left: -10px;
  } */
}

.slListNote {
  color: #fff;
  list-style: none;
  position: relative;
}

.slListNote::after {
  position: absolute;
  content: "*";
  top: 0;
  left: 30px;
}

.buyNowBTN {
  width: 100%;
  background-color: #407bff;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  border: none;
  padding: 10px 0;
}




/* new */

.innerContainer{
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.heading{
  color: #fff;
  white-space: nowrap;
  font-size: 30px;
  font-weight: 600;
  padding: 30px;
  /* display: block; */
  text-align: center;
  margin: 0;
  & span{
    font-weight: 400;
  }
}

.textConatinerHeading{
  font-size: 25px;
  font-weight: 600;
  margin: 0;
}

.textConatiner{
  padding: 0 20px;
}

.headingone{
  background-color:#77B5B5 ;
}

.headingtwo{
  background-color: #7F96B3;
}
.notesinlist{
  font-size: 14px;
}
/* .innerContainer{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
} */

.buyButton{
  border: none;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  border-radius: 25px;
  padding: 10px 25px;
}
.pdfreporttod{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.reporttext{
  text-decoration: none;
  color: #14c69b;
  font-weight: 500;
  text-decoration: underline;
  font-size: 18px;
}

/* mobile Responsive */
@media screen and (max-width: 768px) and (min-width: 320px) {
  .pdfBoxListing{
    width: 100%;
    min-height: 90%;
    flex-direction: column;
    overflow: auto;
    gap: 30px;
  }

  .boxComponent{
    width: 100%;
  }
  .textConatiner{
    padding: 0px;
  }
  .boxStyles{
    width:100%;
    overflow:auto;
    height:700px;
  }
}
