.MainReportContainer {
  width: 90%;
  margin: auto;
}
.reportListingContainer {
  display: flex;
  justify-content: space-evenly;
  /* gap: 40px; */
}

.boxContainer {
  width: 30%;
  /* text-align: center; */
  border-radius: 16px;
  box-shadow: 6px 10px 41px 0px #14828b1a, -5px -5px 28px 0px #c6e1e140 inset;
  /* box-shadow: -5px -5px 28px 0px #c6e1e140 inset; */
  padding: 0 20px;
  box-sizing: border-box;
}

.boxHeading {
  font-size: 30px;
  font-weight: 600;
  position: relative;
  text-align: center;
}

.hedingUnderline {
  position: absolute;
  bottom: -20%;
  width: 25%;
  height: 3px;
  right: 25%;
  transform: translate(-50%, -50%);
}

.overflowContainer {
  height: 240px;
  min-height: 240px;
  overflow: auto;
  margin-top: 50px;
}

.innerBoxContainer {
  margin-bottom: 20px;
  padding: 10px 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.textTitle {
  font-size: 18px;
  font-weight: 600;
  margin: 10px 0;
  text-transform: capitalize;
}

.textDate {
  font-size: 12px;
  font-weight: 400;
  font-style: italic;
  margin: 10px 0;
}

.boxrightContainer {
  font-size: 12px;
  font-weight: 400;
  align-items: center;
  display: flex;
  gap: 5px;
  text-decoration: underline;
  cursor: pointer;
}

.DreamCareerContainer {
  margin: 40px 0;
}

.dreamTextContainer {
  text-align: center;
  width: 395px;
  height: 395px;
  /* vertical-align: middle; */
  margin: auto;
  /* padding: 120px 0; */
  background: linear-gradient(180deg, #286394 0%, #15838c 100%);
  color: #fff;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & h1 {
    font-size: 40px;
    font-weight: 800;
    margin: 10px 0;
  }

  & h4 {
    font-size: 20px;
    font-weight: 400;
    margin: 10px 0;
  }
}

.containerHeading {
  font-size: 26px;
  font-weight: 500;
  position: relative;
}

.containerHeading::after {
  content: " ";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 90px;
  height: 2px;
  /* border: 1.9px solid; */
  background: linear-gradient(97.14deg, #11888a -3.11%, #2a5e95 100%);
}

.careersListing {
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
}

.careersOption {
  font-size: 22px;
  font-weight: 400;
  padding: 10px 50px;
  border: 1px solid #286394;
  border-radius: 50px;
  margin: 10px 0;
}

.testInnerContainer {
  display: flex;
  gap: 20px;
  justify-content: space-evenly;
}

@media only screen and (max-width: 1440px) and (min-width: 1024px) {
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
}

@media only screen and (max-width: 768px) and (min-width: 320px) {
  .reportListingContainer {
    flex-direction: column;
    gap: 20px;
  }

  .boxContainer {
    width: 100%;
  }

  .dreamTextContainer {
    width: 230px;
    height: 230px;

    & h1 {
      font-size: 26px;
      /* font-weight: ; */
      margin: 5px 0;
    }

    & h4 {
      font-size: 13px;
      /* font-weight: ; */
      margin: 0;
    }
  }

  .containerHeading {
    font-size: 20px;
  }

  .careersOption {
    font-size: 14px;
    /* width: 40%; */
    padding: 10px 20px;
    text-align: center;
  }

  .testInnerContainer {
    flex-direction: column;
  }
}

/* buy pakeges  */

.buttoncontainer {
  margin: 0px 0 40px;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  gap: 20px;
}
.freeTest {
  background: linear-gradient(97.14deg, #11888a -3.11%, #2a5e95 100%);
  text-decoration: none;
  color: #fff;
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 400;
}

.allTest {
  background: linear-gradient(97.14deg, #11888a -3.11%, #2a5e95 100%);
  text-decoration: none;
  color: #fff;
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 20px;
  border: none;
  font-weight: 400;
}
