.bannerContainer {
  width: 100%;
  position: relative;
  & img {
    width: 100%;
  }
}

.wetext {
  position: absolute;
  top: 20%;
  right: 0%;
  width: 25%;

  & h1 {
    font-size: 70px;
    font-weight: 600;
    color: #fff;
    margin: 0;
  }

  & p {
    font-size: 30px;
    font-weight: 600;
    color: #d4fff2;
    line-height: 1.4;
    margin: 0;
  }
}

.bannertagLine {
  font-size: 26px;
  font-weight: 500;
  line-height: 1.4;
  position: absolute;
  top: 25%;
  left: 5%;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  width: 25%;
  color: #fff;
  background: #00000099;
  border-radius: 8px;

  & span {
    color: #0fdee7;
  }
}

.studentNeeds {
  margin: 40px 0 80px;
}
.StudentNeedCardListing {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
}

.successDesc {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;
}
.forSuccssContainer {
  margin: 80px;
}
.successHeading {
  text-align: center;
  color: #13868b;

  font-size: 40px;
  font-weight: 500;

  & span {
    font-size: 50px;
    font-weight: 700;
  }
}
.successCardListing {
  display: flex;
  justify-content: space-between;
  margin: 40px 0;
}

.mobilebanner {
  display: none;
}
/* patner school section */

.patnerSchoolSection {
  padding: 40px 0;
  position: relative;
  background-color: #f7ecde;
}
/* .maxWidth {
    max-width: 1330px !important;
    margin: 0 auto;
    padding: 0 10px;
  } */
.postions {
  position: absolute;
}

.psicons1 {
  bottom: 20%;
}
.psicons2 {
  bottom: 0;
  right: 0;
}

.textCenter {
  text-align: center !important;
}

.bluiesh {
  color: #2f327d;
}

.featuresdiv {
  margin-top: 0;
  font-weight: 600;
  font-size: 40px;
}

.howMentoringContainer {
  display: flex;
  justify-content: space-evenly;
  margin: 40px;
  position: relative;
}

.bulbimg{
  position: absolute;
  bottom: 5%;
  right: 10%;
}

.leftContainer {
  width: 40%;
  /* overflow: auto; */
  /* display: grid; */
  /* grid-template-columns: auto auto auto auto; */

  & img{
    width: 100%;
  }
  
}

.desktopView{

}

.mobileView{
  display: none;
}

.HMimgContainer1 {
  /* float: left; */
  grid-area: 1 / 1 / span 1 / span 2;
  width: 45%;

  & im{
    width: 100%;
  }
}
.HMimgContainer2 {
  /* float: left; */
  grid-area: 1 / 3 / span 2 / span 2;
  width: 45%;

  & im{
    width: 100%;
  }
}
.HMimgContainer3 {
  /* float: left; */
  grid-area: 2 / 1 / span 3 / span 2;
  width: 45%;

  & im{
    width: 100%;
  }
}
.HMimgContainer4 {
  /* float: left; */
  grid-area: 3 / 3 / span 2 / span 2;
  width: 45%;

  & img{
    width: 100%;
  }
}

.rightContainer {
  width: 30%;
}

.HMheading {
  font-size: 60px;
  font-weight: 600;
  color: #2f327d;
  margin-top: 0;
  line-height: 1;
}

.hmdesc {
  font-size: 24px;
  font-weight: 400;
  line-height: 1.4;

  & span {
    color: #13868b;
    font-weight: 700;
  }
}

@media screen and (max-width: 767px) {

  
  .mobile_container {
    margin: auto 9%;
    margin-bottom: 65px;
  }

  .featuresdiv {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 28px;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1024px) {

  .bannertagLine{
    font-size: 20px;
  }
  .howMentoringContainer{
    /* flex-direction: column; */
    /* align-items: center; */
  }
  .leftContainer{
    width: 45%;
    margin: 0 auto;
  }

  .rightContainer{
    width: 50%;
    margin: 0 auto;
    /* text-align: center; */
  }
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
  .wetext{

    & h1{
      font-size: 50px;

    }

    & p{
      font-size: 22px;
    }
  }
  .bannertagLine{
    font-size: 20px;
    width: 30%;
  }


  .leftContainer{
    width: 80%;
    margin: 0 auto;
  }

  .rightContainer{
    width: 80%;
    margin: 0 auto;
  }
  .howMentoringContainer{
    flex-direction: column;
  }
}

@media screen and (max-width: 992px) and (min-width: 768px) {
  .bluiesh {
    color: #2f327d;
    font-weight: 600;
    font-size: 28px;
  }
  .studentNeeds {
    margin: 20px;
  }

  .StudentNeedCardListing {
    flex-direction: column;
    gap: 20px;
  }
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
  .psicons1 {
    bottom: auto;
    top: 0;
  }
  .psicons2 {
    bottom: 52px;
    right: 0;
  }
  .studentNeeds {
    margin: 20px;
  }

  .StudentNeedCardListing {
    flex-direction: column;
    gap: 20px;
  }
  .desktopbanner {
    display: none;
  }
  .mobilebanner {
    display: block;
  }

  .wetext {
    width: 100%;
    top: auto;
    bottom: 5%;
    left: auto;
    & h1 {
      font-size: 40px;
      text-align: center;
    }

    & p {
      font-size: 18px;
      text-align: center;
    }
  }

  .bannertagLine {
    font-size: 16px;
    padding: 10px;
    box-sizing: border-box;
    /* bottom: 5%; */
    top: 5%;
    left: 0;
    right: 0;
    width: 90%;
    margin: 0 auto;
  }

  .forSuccssContainer {
    margin: 40px 20px;
  }

  .successHeading {
    font-size: 30px;

    & span {
      font-size: 40px;
    }
  }
  .successDesc {
    font-size: 16px;
  }
  .successCardListing {
    flex-direction: column;
    gap: 60px;
  }

  .howMentoringContainer {
    flex-direction: column;
    margin: 40px;
  }
  .leftContainer {
    width: 100%;
    /* grid-template-columns: none; */
  }

  .rightContainer {
    width: 100%;
    text-align: center;
  }
  .HMimgContainer1 {
    /* float: left; */
    grid-area: 1;

    & img {
      width: 100%;
    }
  }
  .HMimgContainer2 {
    /* float: left; */
    grid-area: 2;

    & img {
      width: 100%;
    }
  }
  .HMimgContainer3 {
    /* float: left; */
    grid-area: 3;

    & img {
      width: 100%;
    }
  }
  .HMimgContainer4 {
    /* float: left; */
    grid-area: 4;

    & img {
      width: 100%;
    }
  }

  .HMheading {
    font-size: 25px;
  }

  .hmdesc {
    font-size: 16px;
  }

  .desktopView{
    display: none;
  }
  
  .mobileView{
    display: block;
  }
}
