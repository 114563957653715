.review-rate-cont {
    /* display: grid;
    grid-template-columns: calc(100% - 350px) 350px;
    padding:10px 10px 50px 10px; */
    display: grid;
    grid-template-columns: calc(70%) 30%;
    width: 90%;
    gap: 20px 20px;
    margin: 10px auto 16px;
}

/* .user-ratings-crd {
    display: grid;
    grid-template-columns: 50px calc(100% - 50px);
} */

.user-ratings-crd {
    display: flex;
}

.user-profile-c{
    width:auto;
    margin-right: 20px;
}

.user-details-top-c{
    display: flex;
}

.user-name-c-r::first-letter{
    text-transform: uppercase
}

.user-name-c-r{
    margin-right:20px;
}

.user-review-c{
    margin-top: 20px;
    font-size: 20px;
}

.user-review-c::first-letter{
    text-transform: uppercase
}

.user-reveiew-reply{
    display: flex;
}

.like-dislike-c{
    display: flex;
}

.like-dislike-c p{
    margin-right: 10px;
    cursor: pointer;
}

.like-dislike-c p:nth-child(1){
    color:green;
}

.like-dislike-c p:nth-child(2){
    color:red;
}

.reply-button-review{
    cursor: pointer;
}

.view-reply-c{
    cursor: pointer;
}

.rate-ppr {
    padding: 30px;
    box-sizing: border-box;
    width: 330px;
    margin: auto;
    text-align: center;
}

.review-ppr {
    padding: 30px;
    padding-top:0px;
    box-sizing: border-box;
    margin: auto;
}

.progress {
    width: 90%;
    height: 18px;
    position: relative;
    background: #DCDCDC;
    /* margin-top:15px; */
    border-radius: 10px;
    overflow: hidden;
}

.progress-bar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}


@media only screen and (max-width:768px){
    .review-rate-cont{
        display: flex;
        flex-direction: column-reverse;
    }

    .rate-ppr{
        margin-left: 10px;
        width:auto;
    }
}

@media only screen and (min-width:769px){
    .rate-ppr{
        width:auto !important;
    }
}