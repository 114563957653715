.custom-arrow-banner{
    background-color: transparent;
    border: none;
    color: #fff;
    position: absolute;
    cursor: pointer;
    z-index: 5;
}

.custom-prev-arrow-banner{
    top: 50%;
    left: 20px;
}

.custom-next-arrow-banner{
    top: 50%;
    right: 20px;
}

