.accountTitle {
  color: #172b69;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  position: relative;
}

.accountTitle:after {
  content: "";
  background: #172b69;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 70px;
}
.firstSection {
  color: #ffffff;
  display: flex;
  font-size: 20px;
  text-align: center;
  font-weight: 500;
  line-height: 30px;
  border-radius: 9px !important;
  background-color: #172b69 !important;
  align-items: center;
  justify-content: space-around;
}
.firstSection p {
  white-space: nowrap;
}
.cardChildTitle {
  justify-content: space-between;
  align-items: center;
  width: 70%;
}
.cardTitle {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 70% !important;
  color: #fff;
  flex-wrap: nowrap;
}
.profileDailogTitle {
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #fff;
  flex-wrap: nowrap;
}
.userCardDetails {
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* color: #00000080; */
  color: #000;
  padding: 15px;
  min-height: 150px;
}
.cardHeight {
  height: 100%;
}
.btn_save {
  display: flex;
  background-color: #2f327d;
  align-items: center;
  padding: 9px 10px;
}
.btn_cancel {
  background-color: #032C6BA1;
  color: white;
  padding: 13px;
  border: none;
  border-radius: 10px;
  font-size: 15px;
  font-weight: bold;
  margin: 0 15px;
  box-sizing: border-box;
  cursor: pointer;
}