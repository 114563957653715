.bannerContainer {
  position: relative;
}

.bannerimg {
  width: 100%;

  & img {
    width: 100%;
  }
}
.mobileShow {
  display: none;
}

.bannerText {
  position: absolute;
  bottom: 0;
  left: 30%;
  transform: translate(-50%, -50%);

  & h1 {
    color: #fff;
    font-size: 38px;
    font-weight: 600;

    & span {
      font-size: 30px;
      font-weight: 400;
    }
  }
}

.backarrowClick {
  position: absolute;
  top: 10%;
  left: 5%;
}

/* common styling class */
.sectionHeading {
  font-size: 35px;
  color: #032c6b;
  font-weight: 600;
  margin-bottom: 40px;
}
.pl10 {
  padding-left: 20px;
}

.textcenter {
  text-align: center;
}

/* what we do  section styling*/

.whatwedoSection {
  padding: 50px;
}
.whatwedoCardListing {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
}

/* video section */
.videoSection {
  padding: 50px;
}

.videoContainer {
  width: 100%;
  margin: 50px auto;
  border-radius: 25px;
  overflow: hidden;
  min-height: 500px;
}

.innerVideoDisplay {
  width: 100% !important;
  min-height: 500px;
}

/* courses section */

.coursesSection {
  background-color: #d8fffd;
  padding: 25px 50px;
}

.cardlisting {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

/* tools section */

.toolsSection {
  padding: 50px 100px;
}

.border {
  box-sizing: border-box;
  border: 1px solid #000;
  padding: 30px 60px;
  border-radius: 20px;
}

/* apply now container */
.applynowSection {
  background-color: #7ab2bd;
  padding: 30px 60px;
  margin: 40px 0;
}

.applynowtext {
  display: flex;
  justify-content: space-around;
  align-items: center;

  & p {
    font-size: 26px;
    font-weight: 500;
    color: #fff;
    width: 47%;
  }
}
.applynowbtn {
  background-color: #11898a !important;
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  box-shadow: 5px 5px 0px 2px rgba(0, 0, 0, 0.5) !important;
}

/* why us */
.whyus {
  background-color: #f9f9f9;
  padding: 30px 40px;
}

.highlighttext {
  font-size: 26px;
  font-weight: 600;
  color: #11898a;
}

.normalpara {
  font-size: 20px;
  font-weight: 300;
  color: #000;
  width: 60%;
  margin: 0 auto;
  line-height: 1.4;
}

.heghlightpara {
  font-size: 20px;
  font-weight: 600;
  color: #11898a;
  width: 45%;
  margin: 50px auto;
}
/* outcom section */
.outcomeSection {
  padding: 30px;
}

.outcomeimgContainer {
  width: 50%;
  margin: 0 auto;
  & img {
    width: 100%;
  }
}

/* buy section */
.buyOurkitsection {
  padding: 30px 50px;
}

.buycardListing {
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
  overflow: auto;
}

.LastText {
  margin-top: 50px;
}
.buynowLastText {
  font-size: 18px;
  font-weight: 500;
}

.coonectbtn {
  background-color: #13878b !important;
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}

/* contact from  */
.postions {
  position: absolute;
}

.contactformSection {
  text-align: center;
  /* padding: 50px 0; */
  padding: 50px;
  position: relative;
}
.innercontactContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.contactformImgContainer {
  width: 35%;
  background: radial-gradient(
    circle,
    rgba(37, 103, 147, 0.21052170868347342) 0%,
    rgba(255, 255, 255, 1) 70%
  );
  overflow: visible;

  & img {
    width: 100%;
  }
}
.borderContainer {
  /* margin: 0 auto; */
  width: 35%;
  padding: 4px;
  background: rgb(20, 135, 139);
  background: linear-gradient(
    120deg,
    rgba(20, 135, 139, 1) 0%,
    rgba(170, 212, 212, 1) 50%,
    rgba(20, 135, 139, 1) 100%
  );
  box-sizing: border-box;
}
.fromContainer {
  background-color: #fff;
  padding: 40px;
  & .sectionHeading {
    margin: 0;
  }
}
.bgacolor {
  background-color: rgba(217, 217, 217, 0.15);
}
.textfieldContainer {
  padding: 2px 40px;

  & .fullwidth {
    margin: 10px 0;
    width: 100%;
  }
}

.gradeContainer {
  display: flex;
  flex-wrap: wrap;
  /* row-gap: 20px; */
  column-gap: 25px;
  width: 60%;
  margin: 0 auto;
}

.minBox {
  border: 1px solid #2b5f96;
  border-radius: 5px;
  width: 50px;
  box-sizing: border-box;
  padding: 8px 0;
  cursor: pointer;
}

.textbtn {
  border: 1px solid #2b5f96;
  width: 70%;
  margin: 20px auto;
  padding: 10px 0;
  border-radius: 5px;
}
.fromsubmitbtn {
  background-color: #11898a !important;
  color: #ffffff !important;
  box-shadow: 0 4px 14px 0 rgba(23, 127, 141, 0.25) !important;
  padding: 10px 30px !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  margin-top: 40px !important;
  width: 100% !important;
}
.showmobile {
  display: none;
}

.cfone {
  top: 10%;
  left: 3%;
}
.cftwo {
  bottom: 10%;
  left: 4%;
}
.cfthree {
  bottom: 0;
  right: 15%;
}
.formsubmited {
  font-size: 80px;
  background-color: #5cb85c;
  border-radius: 100px;
  color: #fff;
  padding: 30px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
.innerbox{
  width: 100%;
}
@media only screen and (max-width: 1440px) and (min-width: 1024px) {
  .bannerText {
    left: 40%;
    bottom: -10%;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .bannerText {
    left: 40%;
    bottom: -25%;
  }

  .whatwedoCardListing {
    overflow: auto;
    /* gap: 20px; */
  }

  .applynowtext {
    & p {
      font-size: 20px;
    }
  }
  .cardlisting {
    overflow: auto;
  }

  .normalpara {
    width: 90%;
  }

  .heghlightpara {
    width: 80%;
  }
  .outcomeimgContainer {
    width: 80%;
  }

  .buycardListing {
    overflow: auto;
    justify-content: space-between;
  }
  .contactformImgContainer {
    display: none;
  }
  .borderContainer {
    width: 70%;
  }

  .cfthree{
    right: 0;
  }
}
@media only screen and (max-width: 768px) and (min-width: 320px) {
  /* common styling class */
  .sectionHeading {
    font-size: 22px;
  }
  .pl10 {
    padding-left: 0px;
  }
  .pcShow {
    display: none;
  }

  .mobileShow {
    display: inline-block;
    & img {
      width: 100%;
    }
  }
  .bannerText {
    /* top: 50%; */
    bottom: -10%;
    left: 45%;
    width: 75%;

    & h1 {
      color: #fff;
      font-size: 25px;
      font-weight: 600;

      & span {
        font-size: 18px;
        font-weight: 400;
      }
    }
  }

  .backarrowClick {
    width: 17px;
    top: 10%;
    left: 5%;

    & img {
      width: 100%;
    }
  }
  .videoContainer {
    min-height: 300px;
  }
  .innerVideoDisplay {
    width: 100% !important;
    min-height: 300px !important;
  }

  /* what we do  section styling*/
  .whatwedoSection {
    padding: 20px;
  }

  .whatwedoCardListing {
    display: flex;
    /* gap: 10px; */
    /* margin-top: 50px; */
    overflow: scroll;
  }
  /* courses section */

  .coursesSection {
    background-color: #d8fffd;
    padding: 25px 30px;
  }

  .cardlisting {
    display: flex;
    overflow: scroll;
    gap: 20px;
  }
  /* tools section */

  .toolsSection {
    padding: 20px;
  }

  .border {
    box-sizing: border-box;
    border: 1px solid #000;
    padding: 30px 20px;
    border-radius: 20px;
  }

  /* apply now container */
  .applynowSection {
    background-color: #7ab2bd;
    padding: 30px;
    margin: 40px 0;
  }

  .applynowtext {
    display: block;

    & p {
      font-size: 18px;
      font-weight: 500;
      color: #fff;
      width: 100%;
      margin-bottom: 20px;
    }
  }
  .applynowbtn {
    background-color: #11898a !important;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    box-shadow: 2px 2px 0px 2px rgba(0, 0, 0, 0.5) !important;
  }

  /* why us */
  .whyus {
    background-color: #f9f9f9;
    padding: 30px 10px;
  }

  .highlighttext {
    font-size: 20px;
    font-weight: 600;
  }

  .normalpara {
    font-size: 14px;
    font-weight: 300;
    color: #000;
    width: 100%;
    margin: 0 auto;
    line-height: 1.4;
  }

  .heghlightpara {
    font-size: 16px;
    font-weight: 400;
    color: #11898a;
    width: 100%;
    margin: 50px auto;
  }
  .outcomeSection {
    padding: 20px;
  }
  .outcomeimgContainer {
    width: 100%;
    /* margin: 0 auto; */
    & img {
      width: 100%;
    }
  }

  /* buy section */
  .buyOurkitsection {
    padding: 20px;
  }

  .buycardListing {
    gap: 20px;
    overflow: scroll;
    justify-content: space-between;
  }
  /* video section */
  .videoSection {
    padding: 10px;
  }
  .videoContainer {
    width: 100%;
    margin: 30px auto;
    border-radius: 25px;
    overflow: hidden;
  }
  
  .cfone,
  .cftwo,
  .cfthree {
    display: none;
  }



  .contactformSection{
    padding: 20px;
  }
  .innercontactContainer{
    /* display: flex; */
    justify-content: space-around;
  }

  .contactformImgContainer{
   display: none;
  
    & img{
      width: 100%;
    }
  }

  .borderContainer {
    width: 100%;
  }
  .gradeContainer {
    width: 100%;
    column-gap: 25px;
  }
  .fromContainer {
    padding: 10px;
  }
  .textfieldContainer {
    width: 100%;
    padding: 10px;
  }

  .boxForMobile{
    padding: 20px !important;
    width: 80% !important;
  }
}


@media only screen and (max-width: 450px){
  .bannerText {
    /* top: 50%; */
    bottom: -25%;
    left: 45%;
    width: 75%;

    & h1 {
      color: #fff;
      font-size: 20px;
      font-weight: 600;

      & span {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}