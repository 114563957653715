.header {
  color: #333333;
  margin: 15px auto;
  font-size: 20px;
  font-weight: bold;
}
.footer {
  display: flex;
  align-items: center;
}
.footerText {
  font-size: 18px;
  cursor: pointer;
  flex: 1;
  padding: 15px 0;
  text-align: center;
}
.content {
  display: flex;
  border: 1px solid #ddd;
}
.filterBy {
  border-right: 1px solid #ddd;
  flex: 3;
  max-height: 60vh;
  overflow: auto;
}
.filterContainer {
  display: flex;
}
.filterType {
  margin: 10px 0;
  font-weight: bold;
  color: #666666;
  cursor: pointer;
}
.activ {
  width: 9px;
  margin-right: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.filterList {
  flex: 4;
  max-height: 60vh;
  overflow: auto;
}
.listContainer {
  display: flex;
  align-items: center;
}
.filterName {
  font-size: 14px;
}
.filterCheck {
  padding: 10px;
}
