.price-slider {
  /* width: calc(100% - 56px) !important; */
  margin: 20px;
  font-weight: bold;
}
.min-value, .max-value {
  color: #454545;
  font-size: 15px;
  background-color: #fff;
  padding: 10px 20px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  margin-top: 5px;
  border-radius: 8px;
}
