.heading{
    font-size: 24px;
    font-weight: 600;
    color: #032C6B;
}
.chapterheading{
    font-size: 18px;
    font-weight: 400;
    color: #000;
}
.pptsCards{
    display: flex;
    gap: 20px;
}
.pptCard{
    position: relative;
    display: inline-block;
    text-decoration: none;
    /* width: 30%; */
    overflow: hidden;
    border-radius: 10px;
}
.pptCard > img{
    width: 100%;
}

.pptCardText{
    position: absolute;
    bottom: 20px;
    left: 10px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.25);display: block;
    width: 100%;
}

.pptCardText > h5{
    margin: 10px 20px;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
}

.pptCardText > p{
    margin: 10px 20px;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
}