/*Main container*/
.mains {
  position: relative;
  overflow: hidden;
}

.title-content {
  margin: 0 0;
}

/*1. Show case content*/

/*heading of show case*/

.head-main {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.disp_head {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: 360px;
}
.head-text {
  color: #313e6c;
  font-size: 68px;
  width: 40%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.head-img {
  width: 70%;
  margin: 0 auto;
  margin-top: 230px;
}

.title-para {
  font-size: 22px;
  width: 60%;
  margin: 0 auto;
  text-align: center;
  color: #524e4edb;
}
.bg_image {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
}
.zik-zak {
  position: relative;
  padding: 0;
}

.btn-clss {
  position: absolute;
  top: 86px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 3;
}
.floats {
  position: relative;
}
.downs {
  position: absolute;
  top: 92px;
  left: 0;
  margin: 0 auto;
  right: 0;
  width: 49%;
}

/*End of show case content*/

/*2. Middle info*/

.flex-cls {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-bottom: 125px;
  margin: 0 auto;
}

.cont-head {
  font-size: 34px;
  margin: 0px 37px;
  text-align: left;
  color: #535e84;
  padding-bottom: 10px;
}
.cont-para {
  padding: 0px 38px;
  text-align: left;
  font-size: 19px;
  color: #535e84;
  padding-bottom: 10px;
}
.wraps {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.col-sm {
  max-width: 50%;
  margin-right: 70px;
  text-align: left;
}
.col-sm:last-child {
  margin-right: 0px;
  padding: 0 22px;
}
.img-col-first {
  width: 100%;
  padding-left: 40px;
}
.cards-abs:hover {
  background-image: url("../../Assets/1x/Asset green.png");
  background-size: 100%;
  background-attachment: fixed;
}
.cards-abs {
  display: flex;
  flex-direction: column;
  border: 2px solid wheat;
  background-color: #ffffff;
  width: 40%;
  margin-bottom: 30px;
  padding: 10px;
}

.cardhead {
  text-align: left;
  font-size: 23px;
  margin: 15px 0px;
  color: #4c577f;
}

.cardpara {
  color: #4c577f;
  text-align: left;
}

.small-image {
  border-radius: 50%;
  width: 50px;
  text-align: left;
}
.sm-image {
  background-color: white;
  padding: 7px;
  width: 61px;
}

.circles {
  width: 100%;
}

/*End of middle info*/

/*3 Our value*/

ul {
  margin: 0;
}
ul.dashed {
  list-style-type: none;
  padding-top: 10px;
}
ul.dashed > li {
  text-align: left;
  font-size: 18px;
  padding: 3px;
  color: #556087;
  text-indent: -5px;
}
ul.dashed > li:before {
  content: "-";
  text-indent: -5px;
  padding-right: 4px;
}

.lines {
  border: 2px solid #11888a;
  background-color: linear-gradient(97.14deg, #11888a -3.11%, #2a5e95 100%);
  padding: 60px 0;
}
.sub-footer {
  padding: 40px 29px 30px;
  display: flex;
  font-size: 20px;
  justify-content: space-around;
  background-color: #f7faff;
  border-radius: 20px;
  margin: 44px 5%;
}

.sub-foot-head > h1,
.sub-foot-head > p {
  margin: 0px;
  margin-bottom: 11px;
  text-align: center;
  color: #556087;
}
.sub-foot-head > p {
  max-width: 279px;
  font-size: 19px;
}

/*End of Our value*/

/* 4- FAQ */

.faq-container {
  margin-bottom: 50px;
  text-align: left;
}

.faq-class {
  width: 543px;
  margin: 0 auto;
}
.faq-class > h1 {
  border-left: 4px solid #f2af68;
  color: #47537c;
  font-weight: 500;
  margin: 0;
  text-align: left;
  padding-left: 7px;
  margin-bottom: 10px;
}
.faq-sub {
  padding: 38px 100px;
}
.faq-btn {
  padding: 20px 69px;
  border-radius: 17px;
  background-color: white;
  color: #47537c;
  font-size: 20px;
  border: 5px solid #f2af68;
  cursor: pointer;
}

/* End of FAQ*/

/* 5- last content*/

.last-image {
  width: 100%;
}
.last-content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 70px;
}
.playstore-box > h3 {
  color: aliceblue;
  font-size: 23px;
  font-weight: 600;
  padding: 0 12px;
  border-left: 2px solid #f2af68;
  float: left;
}
.playstore-box > img {
  float: left;
  width: 33%;
  padding: 10px;
}
.playstore-box {
  position: absolute;
  width: 50%;
  bottom: 23%;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media screen and (min-device-width: 993px) and (max-device-width: 1440px) {
  .head-text {
    font-size: 60px;
    width: 72%;
  }
  .cont-head {
    font-size: 26px;
  }
  ul.dashed > li {
    text-align: left;
    font-size: 17px;
  }
  .sub-foot-head > h1 {
    font-size: 30px;
    margin-right: 11px;
  }
}
@media only screen and (min-device-width: 767px) and (max-device-width: 992px) {
  .head-main {
    margin-top: 0px;
  }
  .head-img {
    margin-top: 190px;
  }
  .head-text {
    font-size: 39px;
    width: 50%;
    margin-top: 80px;
  }
  .title-content {
    margin: 0;
    padding-top: 135px;
  }
  .zik-zak {
    position: relative;
    padding: 0 40px;
  }
  .flex-cls {
    flex-direction: column;
    margin-bottom: 0;
    margin-top: 20px;
  }
  .line {
    display: none;
  }
  .lines {
    display: none;
  }
  .sub-foot-head > p {
    font-size: 14px;
    max-width: 100%;
  }
  .col-sm-one {
    display: flex;
    flex-direction: column-reverse;
    max-width: 100%;
    margin: 49px auto 20px;
  }
  .col-sm {
    max-width: 100%;
    margin: 49px auto 20px;
  }
  .wraps {
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .cont-head {
    text-align: center;
    padding: 20px;
  }
  .cont-para {
    text-align: center;
  }
  .cards-abs {
    width: 300px;
  }
  .img-col-first {
    width: 91%;
    padding-left: 42px;
  }
  .sub-foot-head {
    display: block;
    width: 100%;
    padding: 0 0 13px 14px;
  }
  .sub-footer {
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 0 47px;
    margin-bottom: 20px;
  }
  .sb-foot {
    width: 32%;
  }
  .dashed {
    padding: 15px;
  }
  .faq-sub {
    margin: 0 30px 18px;
    padding: 0;
  }
  .playstore-box {
    position: absolute;
    display: flex;
    flex-direction: column;
    text-align: initial;
  }
  .last-content {
    margin: 0 30px;
  }
  .playstore-box > h3 {
    margin-top: 30px;
    margin-bottom: 20px;
  }
}

@media only screen and (max-device-width: 768px) {
  .downs {
    display: none;
  }
  .head-main {
    margin-top: 60px;
    flex-direction: column-reverse;
  }
  .disp_head {
    position: relative;
    left: 0;
    right: 0;
    bottom: 0;
    margin-top: 0px;
  }
  .head-img {
    width: 80%;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -35%;
    z-index: 2;
  }
  .head-text {
    font-size: 22px;
    width: 70%;
    margin-top: 50px;
    max-width: 250px;
  }
  .title-para {
    font-size: 13px;
    width: 90%;
    text-align: center;
    margin-top: 8px;
    box-sizing: border-box;
  }
  .title-content {
    margin: 0;
  }
  .zik-zak {
    position: relative;
    padding: 0px;
  }
  .btn-clss {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 0;
    left: 0;
    top: 20px;
  }
  .btn-clss > .btn-prim,
  .btn-stud {
    padding: 3px 15px;
    margin: 0 auto;
    margin-bottom: 10px;
    border-radius: 10px;
  }
  .btn:last-child {
    margin-bottom: 0px;
    margin-right: auto;
  }

  .btn {
    width: 60%;
  }
  .flex-cls {
    flex-direction: column;
    margin-bottom: 0;
    margin-top: 20px;
    width: 100%;
  }
  .line {
    display: none;
  }
  .col-sm-one {
    display: flex;
    flex-direction: column-reverse;
    max-width: 100%;
    margin: 49px auto 20px;
  }
  .col-sm {
    max-width: 100%;
    margin: 7px auto 20px;
  }
  .col-sm:last-child {
    padding: 0px;
  }

  .img-col-first {
    width: 90%;
    padding: 18px;
    box-sizing: border-box;
    margin: auto;
    height: 33vh;
    margin-bottom: 10px;
  }
  .wraps {
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0px;
  }
  .cont-head {
    text-align: center;
    padding: 0px;
    font-size: 17px;
    margin: 0 18px;
    margin-bottom: 10px;
  }
  .cardpara {
    font-size: 14px;
  }
  .cont-para {
    font-size: 12px;
    text-align: center;
    max-width: 80%;
    margin: 0 auto;
    padding: 0 10px;
  }
  .cards-abs {
    width: 40%;
  }
  .cardhead {
    font-size: 18px;
    margin: 10px 0px;
  }
  .cards-abs:nth-child(2n + 1) {
    margin-right: 0px;
  }
  .faq-sub {
    padding: 18px 20px;
    box-sizing: border-box;
  }
  .sub-foot-head {
    display: block;
    width: 100%;
    padding: 0 0 13px 14px;
  }
  .sub-foot-head > h1 {
    font-size: 18px;
  }
  .lines {
    display: none;
  }
  .sub-foot-head > p {
    font-size: 14px;
    max-width: 100%;
  }
  .sub-footer {
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 0 14px;
    padding: 10px;
  }
  .sb-foot {
    width: 32%;
  }
  .faq-class {
    width: 70%;
    margin: 28px auto;
  }

  .faq-class > h1 {
    font-size: 19px;
    text-align: center;
  }
  .dashed {
    padding: 5px;
  }
  ul.dashed > li {
    font-size: 11px;
  }
  .playstore-box {
    position: absolute;
    display: flex;
    flex-direction: column;
    text-align: initial;
  }
  .last-content {
    margin: 0 30px;
  }
  .playstore-box > h3 {
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .MuiAccordionSummary-content {
    margin: 0 !important;
  }

  .faq-btn {
    padding: 10px;
    font-size: 12px;
    border: 2px solid #f2af68;
    border-radius: 10px;
  }

  .playstore-box > img {
    float: left;
    width: 33%;
    padding: 3px;
  }
}
