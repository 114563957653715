.circleLinerProgress{
    display: flex;
    justify-content: space-between;
}
.chapterwiseProgress{
    display: flex;
    width: 35%;
    justify-content: space-between;
    margin: 40px 0;
}

.top{
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #000;
    font-size: 33px;
    font-weight: 700;
    margin: 10px 0;
}

.bottom{
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    color: rgba(71, 70, 70, 0.93);
    margin: 10px 0;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
    .circleLinerProgress{
        display: block;
        
    }
    .chapterwiseProgress{
        display: block;
        width: 100%;
        /* width: 35%;
        justify-content: space-between;
        margin: 40px 0; */
    }
    .details{
        display: flex;
        justify-content: space-around;
    }
}