.modalBackground{
    position: relative;
}

.modalContainer{
    position: absolute;
    width: 50%;
    margin: 0 auto;
    left: 0;
    right: 0;
    overflow: hidden;
    border-radius: 18px;
    top: 100px;
}

.title{
    background-color: orange;
}

.title >h1{
    color: white;
    margin: 0;
    padding: 10px;
    text-align: center;
}
.body{
    background-color: white;
}

.icon_model{
    width: 55px;
}
.body_container{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 47px 10px 10px;
}
.bdy_box{
    width: 70%;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    padding: 5px 20px;
    box-shadow: 5px 4px 11px #dedbdb;
}
.bdy_box > p{
    text-align: left;
    margin-left: 50px;
    font-size: 18px;
    color: #032C6BA1;
    font-weight: bold;
}
