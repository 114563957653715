.mainContainer{
    width: 95%;
    margin: 0 auto;
}
.heading{
    color: #032C6B;
    font-size: 24px;
    font-weight: 600;
}
.performance{
    display: flex;
    justify-content: space-between;
   margin-bottom: 30px;
}

.Sidecontainer{
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.10);
    width: 45%;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.circleProgress{
    width: 40%;
}
.lineProgress{
    width: 45%;
    /* padding: 10px; */
}
.difficulty{
    display: flex;
    justify-content: space-between;
    font-size: 22px;
    font-weight: 400;
    /* color: rgba(0, 0, 0, 0.80); */
}
.difficulty span{
    /* color: rgba(0, 0, 0, 0.5); */
}

.testquestion{
    width: 50%;
    border-right:1px solid #000 ;
    padding: 20px;
}
.queInfo{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.queInfoLeft{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    text-align: left;
}
.queInfoLeft > h5{
    width: 60%;
    font-size: 22px;
    font-weight: 400;
    /* color: rgba(0, 0, 0, 0.5); */
}
.queInfoRight{
    font-size: 24px;
    font-weight: 700;
    color: rgba(3, 44, 107, 1);
}
.allPreTime{
    width: 40%;
}

.infoBox{
    background-color: #13868B;
    color: #fff;
    width: 70%;
    text-align: center;
    padding: 5px 10px;
    margin: 0 auto;
    margin-bottom: 10px;
    box-sizing: border-box;
    border-radius: 10px;
}

.infoBox > h5{
    font-size: 24px;
    font-weight: 700;
}
.infoBox > p{
    font-size: 20px;
    font-weight: 400;
}

.chapterTab{
    display: flex;
    gap: 20px;
    overflow: auto;
    
}

.tabs{
    width: 150px;
    background-color: #FCFCFC;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.10);
    border-radius: 10px;
    text-align: center;
    padding: 10px;
    white-space: nowrap;
    cursor: pointer;
    margin: 10px 0;
}
.activeTab{
    background-color: #13868B;
    color: #fff;
}
.backarrowtext{
    font-size: 20px;
    font-weight: 600;
    color: #032C6B;
    margin-left: 10px;
}
@media only screen and (max-width: 1024px) and (min-width: 768px) {
    .Sidecontainer{
        display: block;
    }
    .circleProgress{
        width: 100%;
        text-align: center;
        text-align: -webkit-center;
    }
    .lineProgress{
        width: 100%;
    }
    .testquestion{
        width: 100%;
        border: none;
        border-bottom: 1px solid #000;
        padding: 10px;
        box-sizing: border-box;
    }
    .allPreTime{
        width: 100%;
        margin-top: 20px;
    }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
    .performance{
        display: block;
    }
    .Sidecontainer{
        width: 100%;
        display: block;
        padding: 15px;
        box-sizing: border-box;
        
    }
    .circleProgress{
        width: 100%;
        text-align: center;
        text-align: -webkit-center;
    }
    .lineProgress{
        width: 100%;
    }
    .testquestion{
        width: 100%;
        border: none;
        border-bottom: 1px solid #000;
        padding: 10px;
        box-sizing: border-box;
    }
    .allPreTime{
        width: 100%;
        margin-top: 20px;
    }
}