.class_coaching_main{
    display: flex;
    padding-top: 250px;
}
.coach_first{
    width: 65%;
}

.coach_container{
    width: 600px;
    margin: 0 auto;
}

.reg_box{
    background-color: white;
    padding: 43px 31px;
    border-radius: 79px;
    max-height: 55vh;
    overflow: auto;
}

.txt_insti{
    text-align: center;
    margin-bottom: 0 !important;
}

.reg_box::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

textarea{
    resize: none;
    outline: none;
}
/* coaching image*/

.aside_img{
    width: 100%;
    height: 76vh;
    object-fit: cover;
}


@media screen and (max-width:992px) {
    .class_coaching_main{
        flex-direction: column-reverse;
    }
    .coach_first{
        width: 100%;
    }
    .reg_box {
        background-color: white;
        padding: 43px 31px;
        border-radius: 0px;
        margin: 0 20px;
        max-height: none;
        overflow: none;
    }
}

@media screen and (max-width:786px) {
    .coach_container{
        width: 100%;
    }
    .reg_box {
        padding: 43px 13px;
        margin: 0 10px;
        max-height: none;
    }
    .aside_img {
        width: 100%;
        height: 40vh;
        object-fit: cover;
    }
}