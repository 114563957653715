.search_main{
    position: relative;
}

.input{
    opacity: 0.5;
    border: 3px solid;
    padding: 8px 31px;
    border-radius: 22px;
    margin: 5px;
    width: 75%;
    font-size: 19px;
    box-sizing: border-box;
}

input:focus{
    outline: none;
}

/* For Google Chrome: */

input::-webkit-input-placeholder {
    color: black;
}

/* For Mozilla Firefox: */

input::-moz-placeholder {
    color: black;
}

/* For Internet Explorer: */

input:-ms-input-placeholder {
    color: black;
} 

/* For Opera: */

.input:-o-input-placeholder {
    color: black;
} 

.search{
    position: absolute;
    width: 17px;
    top: 17px;
    left: 14px;
}
@media screen and (min-width:993px) and (max-width:1400px) {
    .input{
        opacity: 0.5;
        padding: 8px 8px 8px 32px;
        border-radius: 22px;
        margin: 5px;
        width: 76%;
        font-size: 19px;
    }
}
@media screen and (min-width:768px) and (max-width:992px) {
    .search_main{
        width: 100%;
    }
    .input{
        width: 90%;
    }    
}
@media screen and (max-width:767px) {
    .search_main{
        width: 100%;
    }
    .input{
        width: 87%;
        padding: 6px;
        padding-left: 33px;
    }
}