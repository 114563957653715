.mainContainer{
    display: flex;
    /* gap: 20px; */
    padding: 40px;
    justify-content: space-between;
}
.sideBar{
    width: 25%;
    box-shadow: 0 4px 10px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    max-height: 210px;
}
.leftSideContainer{
    width: 70%;
    
}
.navigation{
    padding: 20px;
}
.list{
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.75);
    display: flex;
    gap: 10px;
    padding: 10px 15px;
    align-items: center;
}

.active{
    color: #fff;
    font-weight: 600;
    background-color: rgba(19, 134, 139, 1);
    border-radius: 10px;
}
.pageheading{
    color: #032C6B;
    font-size: 28px;
    font-weight: 700;
}


/* tab listing styles */
.tabListingContainer{
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    cursor: pointer;
}
.courseName{
    width: 30%;
    /* background: linear-gradient( 97.14deg, #11888a -3.11%, #2a5e95 100% ); */
    background-color: #13868B;
    color: #fff;
    text-align: center;
    text-transform: capitalize;
    border-radius: 20px;
    overflow: hidden;
    font-size: 19px;
    font-weight: 400;
}
.courseName > img{
    width: 100%;
    border-radius: 10px;
}
.courseName > h4{
    margin: 10px 0 10px;
    font-size: 25px;
    text-transform: uppercase;
}
.nodataAvl{
    margin: 0 auto;
}
.backBtnwithHeading{
    display: flex;
    gap: 20px;
    align-items: center;
}
.couresHeading{
    color: #032C6B;
    font-size: 28px;
    font-weight: 700;
    text-transform: capitalize;
    margin: 0;
}
.courseListingHeading{
    color: #032C6B;
    font-size: 35px;
    font-weight: 700;
    text-transform: capitalize;
    /* margin: 0; */
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
    .mainContainer{
        display: block;
        padding: 0;
    }
    .sideBar{
        width: 100%;
        box-shadow: none;
        margin: 10px 0;
    }
    .navigation{
        display: flex;
       overflow: auto;
       padding: 0;
    }
    .list {
        width: 130px;
        text-align: center;
    }
    .leftSideContainer{
        width: 100%;
    }
    .courseName{
        width: 100%;
        background: linear-gradient( 97.14deg, #11888a -3.11%, #2a5e95 100% );
        color: #fff;
        text-align: center;
        text-transform: capitalize;
        border-radius: 10px;
    }
    .backBtnwithHeading{
        display: none;
       
    }

}